import React, {useMemo, useState, useCallback, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons"
import {Divider, Loader, Popover, Whisper, Tooltip, Badge, Icon as RsIcon, FlexboxGrid} from "rsuite";
import {TableClientSortHOC} from "components/Table/TableClientSort";
import {fixedNumber, getManagedServices} from "utils";
import { useLocation, withRouter } from "react-router";
import {commentTypes} from "const";
import {api} from "api/loginRoutes";

import Icon from "@mdi/react";
import { mdiMicrosoftTeams } from "@mdi/js";

import TableClientSort from "components/Table/TableClientSort";

import styled, {css} from "styled-components";



export default TableClientSortHOC(withRouter(({
    data = [],
    authInfo,
    loading,
    history,
    service,
    onSetRedirectTab,
    isMobile = false,
    ...props
}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchServiceParam = searchParams.get("service");

    const [sortColumn, setSortColumn] = useState("name");
    const [sortType, setSortType] = useState("asc");
    const [sortLoading, setSortLoading] = useState(false);
    const [pickColumnsLoading, setPickColumnsLoading] = useState(false);
    const [partialRedirect, setPartialRedirect] = useState(null);

    const getData = () => {
        // const limitedData = [data[0]];

        const formatedData = data.map(value => {
            const billing = value?.billing;
            const traffic = billing?.VOLUME_1_OUT ? billing.VOLUME_1_OUT : billing?.VOLUME_7_OUT ? billing.VOLUME_7_OUT : billing?.VOLUME_30_OUT;
           
            const allocatedNumbers = value.allocated_numbers || 0;
            const numbersWithTraffic = value.numbers_with_traffic || 0;
            const procentAllocated = allocatedNumbers !== 0 ? numbersWithTraffic / allocatedNumbers : 0;

            return {
                ...value,
                traffic: traffic,
                pending_amount: value?.billing?.pending_amount,
                ready_for_payment: value?.billing?.ready_for_payment,
                traffic_for_day: value?.billing?.VOLUME_1_OUT,
                traffic_for_week: value?.billing?.VOLUME_7_OUT,
                traffic_for_month: value?.billing?.VOLUME_30_OUT,
                created_at_date: new Date(value?.created_at),
                procent_with_traffic: procentAllocated
            }
        });


        if (sortColumn && sortType) {
            return formatedData.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                
                if (typeof x === "string") {
                    x = x.charCodeAt();
                }
                
                if (typeof y === "string") {
                    y = y.charCodeAt();
                }
                
                if (sortType === "asc") {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }

        return formatedData;
    };

    const redirectToAccount = (id, idByService, searchServiceParam, partial=false, customTab="general") => {
        if (partial) {
            localStorage.setItem("ACCOUNT_ACTIVE_TAB", customTab);
        } else {
            localStorage.setItem("ACCOUNT_ACTIVE_TAB", "trunks");
        }

        if (idByService) {
            window.open(`/accounts/view/${idByService}?service=${searchServiceParam}`);
        } else {
            window.open(`/accounts/view/${id}?service=${searchServiceParam}`);
        }
    }
   

    const columns = useMemo( () => {
        const hasBlockedAccount = data.find(item => {
            const {allocation_ban_expired_at = null} = item;
            return allocation_ban_expired_at !== null
        });

        const columns = [
            {
                label: "Account Name",
                flexGrow: 1,
                minWidth: 250,
                sortable: true,
                name: "Account Name",
                dataKey: "name",
                align: "left",
                value: ({id, id_by_service, name, account_note_last}) => {
                    return <AccountNameContainer>
                        <AccountNotesIcon
                            type={account_note_last}
                            accountId={id}
                            redirectFunction={() => {
                                redirectToAccount(id, id_by_service, searchServiceParam, true, "notes");
                            }}
                        />
                        <Whisper
                            trigger="hover"
                            speaker={<Tooltip><span>{name}</span></Tooltip>}
                        >
                            <div className="account_name">{name}</div>
                        </Whisper>
                    </AccountNameContainer>
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Account Manager Name",
                flexGrow: 1,
                width: 170,
                minWidth: 170,
                sortable: true,
                name: "Account Manager Name",
                dataKey: "account_manager_name",
                align: "left",
                value: ({account_manager_name}) => <Whisper
                    trigger="hover"
                    speaker={<Tooltip><span>{account_manager_name}</span></Tooltip>}
                >
                    <SmallText>{account_manager_name}</SmallText>
                </Whisper>,
                pickable: true,
                isMobile: false,
            },
            {
                label: "Service",
                // flexGrow: 1,
                width: 80,
                // width: 80,
                maxWidth: 120,
                sortable: true,
                name: "Service",
                dataKey: "service",
                align: "left",
                value: ({service}) => {
                    return <ServiceCell service={service}>
                        {service}
                    </ServiceCell>
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Pending amount",
                flexGrow: 1,
                width: 125,
                minWidth: 125,
                sortable: true,
                align: "right",
                name: "Pending amount",
                dataKey: "pending_amount",
                value: ({billing = {}, currency_name = ""}) => {
                    const balance = <>
                        {billing?.pending_amount} {currency_name}
                    </>;
                    return balance;
                    
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Ready for payment",
                // flexGrow: 1,
                width: 140,
                minWidth: 140,
                sortable: true,
                align: "right",
                name: "Ready for payment",
                dataKey: "ready_for_payment",
                value: ({billing = {}, currency_name = ""}) => {
                    const balance = <>
                        {billing?.ready_for_payment} {currency_name}
                    </>;
                    return balance;
                    
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Traffic for day",
                // flexGrow: 1,
                width: 115,
                minWidth: 130,
                sortable: true,
                align: "right",
                name: "Traffic for day",
                dataKey: "traffic_for_day",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_1_OUT}</>

                    return traffic;
                
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Traffic for week",
                // flexGrow: 1,
                width: 125  ,
                minWidth: 130,
                sortable: true,
                align: "right",
                name: "Traffic for week",
                dataKey: "traffic_for_week",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_7_OUT}</>

                    return traffic;
                
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Traffic for month",
                // flexGrow: 1,
                width: 130,
                minWidth: 130,
                sortable: true,
                align: "right",
                name: "Traffic for month",
                dataKey: "traffic_for_month",
                value: ({billing = {}}) => {
                    const traffic = <>{billing?.VOLUME_30_OUT}</>

                    return traffic;
                
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Allocated Numbers",
                // flexGrow: 1,
                width: 140,
                sortable: true,
                align: "right",
                dataKey: "allocated_numbers",
                name: "Allocated Numbers",
                value: ({allocated_numbers = 0, numbers_with_traffic = 0}) => {
                    const allocatedNumbers = <>{allocated_numbers||0}/{numbers_with_traffic||0}</>
 
                    return allocatedNumbers;
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "% with traffic",
                // flexGrow: 1,
                width: 106,
                minWidth: 105,
                sortable: true,
                align: "right",
                dataKey: "procent_with_traffic",
                name: "% with traffic",
                value: ({allocated_numbers = 0, numbers_with_traffic = 0, procent_with_traffic = 0}) => {
                    const allocatedNumbers = allocated_numbers || 0;
                    const numbersWithTraffic = numbers_with_traffic || 0;

                    if (allocatedNumbers === 0) {
                        return "0%";
                    } else if (allocatedNumbers < numbersWithTraffic) {
                        return "100%";
                    }

                    if  (procent_with_traffic === 0) {
                        return "0%";
                    }

                    return `${fixedNumber(procent_with_traffic * 100, 2, true, false)}%`;
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Creation date",
                // flexGrow: 1,
                // minWidth: 130,
                width: 130,
                sortable: true,
                align: "right",
                name: "Creation date",
                dataKey: "created_at_date",
                // formatData: "date",
                
                value: ({created_at}) => {
                    const date = new Date(created_at);
                    const options = {year: "numeric", month: "long", day: "numeric"};
                    return <SmallText>{date.toLocaleDateString("en-US", options)}</SmallText>
                },
                pickable: true,
                isMobile: false,
            },
            {
                label: "Country",
                // flexGrow: 1,
                width: 80,
                minWidth: 60,
                sortable: true,
                name: "Country",
                dataKey: "cntr_code",
                pickable: true,
                isMobile: false,
            },
            {
                label: "",
                width: 75,
                sortable: false,
                dataKey: "contact",
                align: "left",
                name: "Contact info",
                value: ({contact}) => {
                    return <ContactInfo
                        contact={contact}
                    />
                },
                pickable: false,
                isMobile: false,
            },
            {
                label: "",
                width: 40,
                sortable: false,
                align: "right",
                dataKey: "not_verified_users",
                name: "Not verified users",
                value: ({id, id_by_service, not_verified_users}) => {
                    return <AccountNotVerifiedUsersIcon
                        redirectFunction={() => {
                            redirectToAccount(id, id_by_service, searchServiceParam, true);
                        }}
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                // fixed: "right",
                pickable: false,
                isMobile: false,
            },
            ...(hasBlockedAccount ? [{
                label: "",
                width: 50,
                sortable: false,
                align: "right",
                name: "",
                value: ({allocation_ban_expired_at = null}) => {
                    return <AccountBlockedNumberMinerIcon
                        show={allocation_ban_expired_at !== null}
                    />
                },
                pickable: false,
                isMobile: false,
            }] : []),
            {
                label: "Account",
                flexGrow: 2,
                minWidth: 170,
                sortable: true,
                name: "Account",
                dataKey: "name",
                value: ({id, id_by_service, name, account_note_last, account_manager_name}) => {
                    return <FlexboxGrid justify="space-between">
                        <FlexGridItemPrefix>
                            <AccountNotesIcon
                                type={account_note_last}
                                accountId={id}
                                redirectFunction={() => {
                                    redirectToAccount(id, id_by_service, searchServiceParam, true, "notes");
                                }}
                            />
                        </FlexGridItemPrefix>
                        <FlexboxGrid.Item>
                            {name}
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item>
                            {account_manager_name}
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                },
                pickable: true,
                isMobile: true,
            },
            {
                label: "Balance",
                flexGrow: 1,
                minWidth: 100,
                sortable: true,
                name: "Balance",
                dataKey: "pending_amount",
                value: ({billing = {}, currency_name = ""}) => (
                    <div className="table-two-staged__cell">
                        <div className="table-two-staged__cell-first">{billing?.pending_amount}</div>
                        <div className="table-two-staged__cell-second">{`${billing?.ready_for_payment}, ${currency_name}`}</div>
                    </div>
                ),
                pickable: true,
                isMobile: true,
            },

            {
                label: "",
                width: 40,
                sortable: false,
                align: "right",
                dataKey: "not_verified_users",
                name: "Not verified users",
                value: ({id, id_by_service, not_verified_users}) => {
                    return <AccountNotVerifiedUsersIcon
                        redirectFunction={() => {
                            redirectToAccount(id, id_by_service, searchServiceParam, true);
                        }}
                        show={not_verified_users && not_verified_users !== 0 }
                        count={not_verified_users}
                    />
                },
                pickable: false,
                isMobile: true,
            },
            ...(hasBlockedAccount ? [{
                label: "",
                width: 50,
                sortable: false,
                align: "right",
                name: "",
                value: ({allocation_ban_expired_at = null}) => {
                    return <AccountBlockedNumberMinerIcon
                        show={allocation_ban_expired_at !== null}
                    />
                },
                fixed: true,
                pickable: false,
                isMobile: true,
            }]: []),
        ];

        return columns.filter(column => column.isMobile === isMobile);

    }, [data, service, isMobile]);

    const handleSortColumn = (sortColumn, sortType) => {
        setSortLoading(true);
        setTimeout(() => {
            setSortLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 300);
      };

    // const testData = getData();

    const columnSelectorName = useMemo(() => service ? "AccountsTable" : "DialersTable", [service]);
    const columnSelector = useMemo(() => {
        return localStorage.getItem(columnSelectorName) && JSON.parse(localStorage.getItem(columnSelectorName))
    }, [columnSelectorName]);

    const filteredColumns = useMemo(() => {
        return columns.filter(column => columnSelector && columnSelector.length ? columnSelector.includes(column.dataKey) : true);
    }, [columnSelector]);

    const pickableColumns = useMemo(() => {
        return columns;
    }, [columns]);

    return (
        <TableClientSort
            virtualized
            customHeight={700}
            data={getData()}
            loading={loading || sortLoading || pickColumnsLoading}
            rowHeight={!isMobile ? 30 : 50}
            rowClassName={(rowData) => {
                if (rowData) {
                    const {is_managed} = rowData;
                    let rowClassName = !rowData.active
                        ? "table-row-account__closed"
                        : "table-row-account table-row-account---link";
                    // let rowClassName = "table-row-account table-row-account---link";

                    if (!is_managed) {
                        rowClassName += " table-row-account--disabled";
                    }
          
                    return rowClassName;
                }
            }}
            onRowClick={ ({id, id_by_service, is_managed, allocation_ban_expired_at = null, ...acc}) => {
                const managedServiceList = getManagedServices(authInfo);
                if (is_managed) {
                    if (managedServiceList.length > 1) {
                        redirectToAccount(id, id_by_service, searchServiceParam);
                    } else {
                        window.open(`/accounts/view/${id}?service=${searchServiceParam}`);
                        localStorage.setItem("ACCOUNT_ACTIVE_TAB", "trunks");
                    }
                }
            }}
            customRowClick
            {...props}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            columns={filteredColumns}
            {...(!isMobile ? {
                columnSelectorLSKey: columnSelectorName,
                originalColumns: pickableColumns,
                onPickColumns: () => {
                    setPickColumnsLoading(true);
                    setTimeout(() => {
                        setPickColumnsLoading(false);
                    }, 300);
                }
            } : {})}
        />
    );
            {/* {columns.map(({dataKey, name, value = null, ...columnProps}) => (
                <Column {...columnProps}>
                    <HeaderCell>{name}</HeaderCell>
                    <Cell dataKey={dataKey}>{value}</Cell>
                </Column>
            ))}

            <Column key="table_scrollfix" width={0} fixed>
                <HeaderCell></HeaderCell>
                <Cell></Cell>
            </Column>

        </BaseTable> */}
}));

const AccountNotVerifiedUsersIcon = ({show, count, redirectFunction}) => {

    return (
        <StyledAccountNotVerifiedUsersIcon
            style={{
                width: 20,
                marginRight: 10,
                display: "inline-block"
            }}
        >
            {show
                ? <Whisper
                    onClick={(e) => {
                        e.stopPropagation();
                        redirectFunction();
                    }}
                    placement="auto"
                    speaker={<Tooltip>Count of unconfirmed users: {count}</Tooltip>}
                >
                    <Badge
                        content={<span className="badge-count">{count}</span>}
                    >
                        <RsIcon 
                            icon="envelope-o" 
                            className="badge-icon"
                        />
                    </Badge>
                </Whisper>
                : null
            }
        </StyledAccountNotVerifiedUsersIcon>
    )
}

const AccountBlockedNumberMinerIcon = ({show, count}) => {
    return (
        <StyledAccountBlockedNumberMinerIcon
            style={{
                width: 20,
                marginRight: 10,
                display: "inline-block"
            }}
        >
            {show ? <Whisper
                    placement="auto"
                    speaker={<Tooltip>Blocked</Tooltip>}
                >
                    <>
                        <FontAwesomeIcon icon={faBan} className="badge-icon"/>
                        <span className="icon-letter">A</span>
                    </>
                </Whisper>
                : null
            }
        </StyledAccountBlockedNumberMinerIcon>
    )
}

const AccountNotesIcon = ({
    type,
    accountId,
    redirectFunction
}) => {

    const [comments, setComments] = useState([]);
    const [timerId, setTimerId] = useState(null);


    useEffect(() => {
        setComments([]);
    }, [timerId]);


    const getNotes = useCallback( () => {
        const timerId = setTimeout(() => {
            api("account_note:get_short_list", {
                target: {account_id: accountId}
            })
                .then(res => {
                    if (res && res.account_note_list)
                        setComments(res.account_note_list);
                })
        }, 500);
        setTimerId(timerId);
    }, [accountId] );


    const clearNotes = useCallback(() => {
        clearTimeout(timerId);
    }, [timerId]);


    return (
        <span style={{width: 20, display: "inline"}}>
            {type || type === 0
                ? <Whisper
                    onClick={(e) => {
                        e.stopPropagation();
                        redirectFunction();
                    }}
                    enterable={comments.length}
                    placement="auto"
                    speaker={
                        <Popover
                            title={"Recent notes"}
                            // MOUSE
                        >
                            <div>
                                {comments && comments.length
                                    ? comments.map(item => (
                                        <div style={{textAlign: "left"}}>
                                            <span
                                                className={"rs-icon rs-icon-" + commentTypes[item.type].icon}
                                                style={{
                                                    width: 20,
                                                    color: commentTypes[item.type].color,
                                                }}
                                            />
                                            &nbsp;{item.text}
                                        </div>
                                    ))
                                    : <Loader content="Loading..." style={{paddingTop: 6}} size="xs"/>
                                }
                            </div>
                        </Popover>
                    }
                >
                    <span
                        onMouseEnter={getNotes}
                        onMouseOut={clearNotes}
                        className={"rs-icon rs-icon-" + commentTypes[type].icon}
                        style={{color: commentTypes[type].color}}
                    />
                </Whisper>
                : null
            }
        </span>
    )
};


const ContactInfo = ({contact}) => {
    if (!contact) {
        return <></>
    }

    const tgUsername = contact.telegram_username;
    const clearTgUsername = tgUsername && tgUsername.startsWith("@") ? tgUsername.slice(1) : tgUsername; 
    const telegramUrl = `tg://resolve?domain=${clearTgUsername}`;
    const skypeUrl = `skype:${contact.messenger}?chat`;
    const teamsUrl = contact.teams_link;
    const openTelegram = (e) => {
        e.stopPropagation();
        window.open(telegramUrl, "_self");
    };

    const openSkype = (e) => {
        e.stopPropagation();
        window.open(skypeUrl, "_self");
    };

    const openTeams = (e) => {
        e.stopPropagation();
        window.open(teamsUrl, "_self");
    };


    return <ContactIcons>
        {contact.telegram_username ? <span onClick={openTelegram}>
            <Whisper 
                speaker={<Tooltip>Click to open a chat in Telegram</Tooltip>}
                placement="bottomEnd"
            >
                <RsIcon className={`telegram ${contact.telegram_id_ready ? "active" : ""}`} icon="telegram"/>
            </Whisper>
        </span>  : <></>}
        {contact.messenger ? <span onClick={openSkype}>
            <Whisper 
                speaker={<Tooltip>Click to open a chat in Skype</Tooltip>}
                placement="bottomEnd"
            >
                <RsIcon className="skype" icon="skype"/>
            </Whisper>
        </span> : <></>}
        {contact.teams_link ? <span onClick={openTeams}>
            <Whisper 
                speaker={<Tooltip>Click to open a chat in Teams</Tooltip>}
                placement="bottomEnd"
            >
                <MdiIcon path={mdiMicrosoftTeams} size={1} />
            </Whisper>
        </span> : <></>}
        {contact.phone ? <Whisper 
            speaker={<Tooltip>{contact.phone}</Tooltip>}
            placement="bottomEnd"
        >
            <RsIcon className="phone" icon="phone"/>
        </Whisper> : <></>}
    </ContactIcons>
};

const StyledAccountBlockedNumberMinerIcon = styled.span`
    && {
        color: red;
    }
    && .badge-icon {
        margin-top: -2px;
        font-size: 24px;
        font-weight: 300;
    }
    && .icon-letter {
        position: absolute;
        top: 6px;
        left: 17px;
        font-size: 17px;
        line-height: 1em;
        font-weight: 500;
    }
`;

const FlexGridItemPrefix = styled(FlexboxGrid.Item)`

`;

const ServiceCell = styled.span`
    ${props => props.service === "voice" ? css`
        color: var(--color-violet);
    ` : css`
        color: var(--color-info);
    `
    }
`;


const AccountNameContainer = styled.div`
    display: flex;

    .account_name {
        margin-left: 5px;
    }
`;

const StyledAccountNotVerifiedUsersIcon = styled.span`
    .rs-badge {
    
        padding: 2px;
        font-size: 9px;
        
        .rs-badge-content {
            box-shadow: 0 0 0 1px #ffffff;
            background-color: var(--color-info);
            border-radius: 11px;
            font-size: 10px !important;
            line-height: 11px;
            padding: 0 2px;
            color: #ffffff;
            margin-right: 1px;
            margin-top: 3px;
            height: 11px;
            min-width: 11px;
            text-align: center;
        }
        
        .badge-count {
            font-weight: 700;
            font-size: 9px;
        }
    }
`;

const ContactIcons = styled.span`
    && {
        display: flex;
        align-items: center;
    }

    && .rs-icon {
        font-size: 16px;
        margin-right: 2px;
    }

    && .rs-icon.skype {
        margin-top: 2px;
        color: #00AFF0;
    }

    && .rs-icon.telegram {
        font-size: 14px;
        color: gray;
    }

    && .rs-icon.telegram.active {
        color: #24A1DE;
    }

    && .rs-icon.phone {
        color: #13d161;
        margin-bottom: 1px;
    }
`;

const SmallText = styled.span`
    && {
        font-size: 12px;
    }
`;

const MdiIcon = styled(Icon)`
    && {
        width: 1.1rem !important;
        height: 1.1rem !important;
        color: #5159C8 !important;
        margin-top: -3px;
    }
`;