import {connect} from "react-redux";
import {
    getAllocationResultList
} from "actions/client/allocations";

import {servicePlanNoTrunkFilter} from "utils/filters";

import AllocationsPage from "./AllocationsPage";


const mapState = ({auth, auth_dialer, client_allocations, references}) => {
    return {
        data: client_allocations.items,
        loading: client_allocations.loading,
        // count: client_allocations.count,
        // page: client_allocations.page,
        // perPage: client_allocations.per_page,
        service: auth_dialer.serviceDialer,

        account: auth.authInfo,
        permissions: auth.permissions,

        servicePlanList: references.service_plan_list.filter(servicePlanNoTrunkFilter),
        currencyList: references.currency_list,
        worldzoneList: references.worldzone_list,
        destinationList: references.destination_list,
        subdestinationList: references.subdestination_list,
    }
};


export default connect(mapState, {
    getAllocationResultList
})(AllocationsPage);