import React, {useMemo, useState, useEffect} from "react";
import {Input, Icon, Form, CheckPicker, Schema, Whisper, Tooltip} from "rsuite";
import styled, {css} from "styled-components";
import CustomField from "./ContactCustomField";
import {checkIfArrayIsUnique, formValid, fullPhoneRegex} from "utils";
import {ButtonListRemove} from "components/base/BaseButton";
import {FieldTextArea} from "pagesClient/PaymentDetails/ModalAddPaymentDetail/Field";

const { StringType, ArrayType } = Schema.Types;

export const contactModel = Schema.Model({
    person: StringType().isRequired("Required").maxLength(40, "The maximum is only 40 characters."),
    email: StringType().isRequired("Required").addRule((value) => {
        const emailList = value.replace(/\s\s+/g, " ").replace(/(,\s)|,/g, "|-&^#").split("|-&^#");
        const correctEmails = emailList.map(email => {
            const emailModel = new RegExp(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);

            return emailModel.test(email);
        });

        return emailList.length === correctEmails.filter(email => !!email).length;
    }, "Incorrect email addresses"),
    phone: StringType().addRule( value => fullPhoneRegex.test(value) , "Please enter valid phone number"),
    messenger: StringType().maxLength(40, "The maximum is only 40 characters."),
    teams_email: StringType()
        .maxLength(256, "The maximum is only 256 characters.")
        .pattern(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, "Incorrect email address"),
    teams_link: StringType()
        .maxLength(256, "The maximum is only 256 characters.")
        .pattern(/^(https?:\/\/)([a-z0-9-]+\.)+[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/i, "Incorrect teams link"),
    role_list: ArrayType().isRequired("This field required").minLength(1, "Required")
});

export const telegramModel = Schema.Model({
    telegram_username: StringType()
        .pattern(/.*\B@(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*.*/, "Telegram username is incorrect. Telegram username must be 5-32 long and contain the '@' symbol at the beginning.")    
});

const emailListModel = Schema.Model({
   email: StringType().isRequired().addRule((value) => {
       const emailList = value.replace(/\s\s+/g, " ").replace(/(,\s)|,/g, "|-&^#").split("|-&^#");
       const correctEmails = emailList.map(email => {
           const emailModel = new RegExp(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/);

           return emailModel.test(email);
       });

       return emailList.length === correctEmails.filter(email => !!email).length;
   }, "Incorrect email addresses").addRule((value) => {
       const emailList = value.replace(/\s\s+/g, " ").replace(/(,\s)|,/g, "|-&^#").split("|-&^#");
       return checkIfArrayIsUnique(emailList);
   }, "Some emails are equal")
});


const roles = ["billing", "rates", "technical", "general"].map(x => ({value: x, label: x}));

export default ( {
    disabled, contacts, updateContacts, formsRef, isMobile, addableIsChanged, initialList, isSupplier, updateFormRef, conflictContacts,
    showExpandedEmails, setShowExpandedEmails, errorContactMessages
} ) => {

    const managedContactModel = useMemo(() => {
        return isSupplier ? contactModel : Schema.Model.combine(contactModel, telegramModel);
    }, [isSupplier]);
    

    const updateFormValues = (formValues, key) => {
        const value = [
            ...contactMailList.map( contact =>
                contact.key === key
                    ?
                    {...contact, ...formValues} : contact
            )
        ];
        updateContacts(value);
    };

    const onRemove = (key) => {
        updateContacts( contactMailList.filter( contact => contact.key !== key ) )
    };

    const [expandedMail, setExpandedMail] = useState(null);
    const [expandedMailError, setExpandedMailError] = useState(contacts.map(contact => {
        return {key: contact.key, email: {hasError: false}}
    }));
    const [contactMailList, setContactMailList] = useState(contacts);
    const [contactErrors, setContactErrors] = useState(errorContactMessages);

    useEffect(() => {
        const defaultMails = contacts.map(contact => {
            return {key: contact.key, email: {hasError: false}}
        });

        if (expandedMail) {
            updateFormValues(expandedMail, expandedMail.key);
        }

        if (expandedMail && someMailHasError(expandedMailError)) {
            setExpandedMailError(defaultMails);
        }
    }, [showExpandedEmails]);

    useEffect(() => {
        const defaultMails = contacts.map(contact => {
            return {key: contact.key, email: {hasError: false}}
        });
        setContactMailList(contacts);
        setExpandedMailError(defaultMails)
    }, [contacts]);

    useEffect(() => {
        if (expandedMail && expandedMail?.key) {
            let expandedTextarea = document.getElementsByClassName(`textarea-${expandedMail?.key}`);
            expandedTextarea = expandedTextarea.length ? expandedTextarea[0] : null;

            if (expandedTextarea) {
                expandedTextarea.focus();
            }
        }
    }, [expandedMail]);

    const someMailHasError = (errors) => {
        return errors.filter(err => !!err?.email?.hasError).length
    };

    const getErrorMessage = (key) => {
        return contactErrors.find(contact => contact.key === key);
    };

    return contactMailList.map(
        (contact, idx) => {
            const clearedInitialList = initialList.map(initialValues => {
                const {key, ...remainingInitialValues} = initialValues;
                return remainingInitialValues;
            });
            const {key, ...clearedContactValues} = contact;

            const contactChanged = addableIsChanged(contact.id, clearedInitialList, clearedContactValues);
  
            const currentConflict = conflictContacts.find(cnt => {
                return cnt === contact.id || (cnt === null && !contact.id)
            });

            return <>
                {isMobile && idx !== 0 ? <hr style={{width: "100%", height: "2px"}}/> : <></>}
                <ContactForm
                    layout="inline"
                    key={contact.key}
                    ref={ref => updateFormRef(ref, contact.key)}
                    onChange={(formValues) => {
                        const contactState = contactMailList.find(con => con.key === contact.key);
                        const localValue = {
                            ...contactState,
                            ...formValues,
                            ...(expandedMail && expandedMail.key === contact.key ? expandedMail : {})
                        };
                        updateFormValues(localValue, contact.key);

                        const localContacts = [...contactMailList].map(contact => {
                            if (contact.key === localValue.key) {
                                return localValue
                            }
                            return contact
                        });

                        const unValidContacts = localContacts.filter(contact => !formValid(managedContactModel.check(contact)));

                        if (unValidContacts.length) {
                            const localErrors = [...unValidContacts].reduce((acc, contact) => {
                                const errorMessage = contactErrors.find(err => err.key === contact.key);

                                acc.push({...errorMessage, key: contact.key, error: managedContactModel.check(contact)})
                                return acc;
                            }, []);

                            setContactErrors(localErrors)
                        } else {
                            setContactErrors([])
                        }
                    }}
                    formDefaultValue={contact}
                    className={`tab-general-addable ${contactChanged ? "has-changed" : ""}`}
                    hasConflict={(currentConflict || currentConflict === null) ? "has-conflict" : ""}
                    model={managedContactModel}
                    mobile={isMobile}
                >
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="person"
                        errorMessage={getErrorMessage(contact.key)?.error?.person?.errorMessage}
                        placeholder="Name"
                    />
                    <StyledStickedContainer show={showExpandedEmails && expandedMail?.key === contact?.key}>
                    {showExpandedEmails && expandedMail?.key === contact?.key
                        ? <>
                            {someMailHasError(expandedMailError) ?
                                <TextareaError>{expandedMailError.find(err => err.key === contact.key)?.email?.errorMessage}</TextareaError> : <></>
                            }
                            <FieldTextArea
                                className={`rs-input textarea-${contact?.key}`}
                                disabled={disabled}
                                placeholder="Email"
                                defaultValue={expandedMail?.email}
                                onChange={(value) => {
                                    const mail = {...expandedMail};

                                    if (mail.key === contact.key) {
                                        mail.email = value.target.value;
                                    }

                                    const errors = expandedMailError.map(error => {
                                        if (error.key === contact.key) {
                                            error.email = emailListModel.check(mail)?.email;
                                        }
                                        return error;
                                    });

                                    setExpandedMailError(errors);

                                    if (!someMailHasError(expandedMailError)) {
                                        setExpandedMail(mail);
                                        const localContacts = contactMailList.map(con => {
                                            let localContact;

                                            if (con.key === mail.key) {
                                                localContact = {...con, ...mail};
                                            } else {
                                                localContact = con;
                                            }

                                            return localContact;
                                        });
                                        setContactMailList(localContacts);

                                        const unValidContacts = localContacts.filter(contact => !formValid(managedContactModel.check(contact)));

                                        if (unValidContacts.length) {
                                            const localErrors = [...unValidContacts].reduce((acc, contact) => {
                                                const errorMessage = contactErrors.find(err => err.key === contact.key);

                                                acc.push({...errorMessage, key: contact.key, error: managedContactModel.check(contact)})
                                                return acc;
                                            }, []);

                                            setContactErrors(localErrors)
                                        } else {
                                            setContactErrors([])
                                        }
                                    }


                                }}
                            />
                        </>
                        : <CustomField
                            disabled={disabled}
                            accepter={Input}
                            name="email"
                            placeholder="Email"
                            errorMessage={getErrorMessage(contact.key)?.error?.email?.errorMessage}
                            onClick={() => {
                                setExpandedMail({key: contact?.key, email: contact?.email});
                                setShowExpandedEmails(true)
                            }}
                        />}
                    </StyledStickedContainer>
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="phone"
                        errorMessage={getErrorMessage(contact.key)?.error?.phone?.errorMessage}
                        placeholder="Phone"
                    />
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="messenger"
                        errorMessage={getErrorMessage(contact.key)?.error?.messenger?.errorMessage}
                        placeholder="Skype"
                    />
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="teams_email"
                        errorMessage={getErrorMessage(contact.key)?.error?.messenger?.errorMessage}
                        placeholder="Teams email"
                    />
                    <CustomField
                        disabled={disabled}
                        accepter={Input}
                        name="teams_link"
                        errorMessage={getErrorMessage(contact.key)?.error?.messenger?.errorMessage}
                        placeholder="Teams link"
                    />
                    <StyledInputWithIcon>
                        <CustomField
                            disabled={disabled}
                            accepter={Input}
                            name="telegram_username"
                            errorMessage={getErrorMessage(contact.key)?.error?.telegram_username?.errorMessage}
                            placeholder="Telegram"
                        />
                        {contact?.telegram_id_ready ? <Whisper
                            trigger="hover"
                            speaker={<Tooltip>Subscribed</Tooltip>}
                        >
                            <Icon icon="check-circle-o"></Icon>
                        </Whisper> : null}
                    </StyledInputWithIcon>
                    <CustomField
                        disabled={disabled}
                        accepter={CheckPicker}
                        name="role_list"
                        data={roles}
                        placeholder="Roles"
                        searchable={false}
                        classPrefix="minWidth"
                        errorMessage={getErrorMessage(contact.key)?.error?.role_list?.errorMessage}
                    />
                    <div style={{marginTop: 10, marginLeft: 20, display: "inline-block"}}>
                        {contacts.length > 1 &&
                        <ButtonListRemove onClick={() => onRemove(contact.key)}/>}
                    </div>
                </ContactForm>
            </>
        }
    );
}

const ContactForm = styled(Form)`   
    &&& {
        ${props => props.mobile ? css`
            display: block;

            .rs-input {
                margin-bottom: 15px;
            }
            .rs-form-group .rs-form-control-wrapper {
                float: inherit;
            }
        ` : css`
            display: flex;
        `};
    }
    ${props => props.hasConflict ? css`
        .rs-input {
            border: 1px solid var(--color-error) !important;
        }
        .rs-picker-toggle-wrapper .rs-btn.rs-btn-default.rs-picker-toggle {
            border: 1px solid var(--color-error) !important;
        }
    ` : css`
      .rs-input, .rs-picker-toggle-wrapper .rs-btn.rs-btn-default.rs-picker-toggle {
        border: 1px solid #e5e5ea;
      }
    `
    }
`;

const StyledStickedContainer = styled.div`
    &&& {
        z-index: 7 !important;
    }
    &&& .rs-form-control-wrapper {
        z-index: 7 !important;

        width: 240px;
    }
    
    &&& > .rs-form-group {
        width: 250px !important;
    }
`;

const TextareaError = styled.div`
    position: absolute;
    z-index: 8;
    color: red;
    font-size: 12px;
    padding: 0 4px;
    background: #fff;
    border-radius: 5px;
    margin-top: -8px;
    margin-left: 8px;
`;

const StyledInputWithIcon = styled.div`
    && {
        position: relative;   
    }

    && .rs-icon {
        color: var(--color-success);
        position: absolute;
        left: 146px;
        top: 9px;
        font-size: 18px;
    }
`;
