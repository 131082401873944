import {defineMessages} from "react-intl";

export default defineMessages({
    editProfile: { id: "editProfile", defaultMessage: "Edit Profile", description: "Edit Profile modal title" },
    email: { id: "editProfile.email", defaultMessage: "Email", description: "Edit Profile email" },
    name: { id: "editProfile.name", defaultMessage: "Name", description: "Edit Profile name" },
    surname: { id: "editProfile.surname", defaultMessage: "Surname", description: "Edit Profile surname" },
    phone: { id: "editProfile.phone", defaultMessage: "Phone", description: "Edit Profile phone" },
    send: { id: "editProfile.send", defaultMessage: "Send", description: "Edit Profile send" },
    nothingToUpdate: { id: "editProfile.nothingToUpdate", defaultMessage: "Nothing to update", description: "Edit Profile Nothing to update" },
    password: { id: "editProfile.password", defaultMessage: "Password", description: "Edit Profile Password" },
    changePassword: { id: "editProfile.changePassword", defaultMessage: "Change password", description: "Edit Profile Change password" },
    confirm: { id: "editProfile.confirm", defaultMessage: "Confirm", description: "Edit Profile Confirm" },
    confirmPassword: { id: "editProfile.confirmPassword", defaultMessage: "Confirm password", description: "Edit Profile Confirm password" },
    dontChangePassword: { id: "editProfile.dontChangePassword", defaultMessage: "Don't change password", description: "Edit Profile Don't change password" },
    equalPasswordsAlert: { id: "editProfile.equalPasswordsAlert", defaultMessage: "Password and confirm password should be equal!", description: "Edit Profile Password and confirm password should be equal!" },
    confirmProfileChanges: { id: "editProfile.confirmProfileChanges", defaultMessage: "Confirm profile changes", description: "Edit Profile Confirm profile changes" },
    typeCurrentPassword: { id: "editProfile.typeCurrentPassword", defaultMessage: "Type current password if you want to confirm changes", description: "Edit Profile Type current password if you want to confirm changes" },
    profileDataHasSuccessfullyChanged: { id: "editProfile.profileDataHasSuccessfullyChanged", defaultMessage: "Profile data has successfully changed!", description: "Edit Profile Profile data has successfully changed!" },
    accountContacts: { id: "editProfile.accountContacts", defaultMessage: "Account contacts", description: "Account contacts" },
    changeNameOrEmail: { id: "editProfile.changeNameOrEmail", defaultMessage: "Change name or email", description: "Change name or email" },
    changeNameAndEmail: { id: "editProfile.changeNameAndEmail", defaultMessage: "Change name and email", description: "Change name and email" },
    contactName: {  id: "editProfile.contactName", defaultMessage: "Contact name", description: "Contact name" },
    contactEmail: {  id: "editProfile.contactEmail", defaultMessage: "Contact email", description: "Contact email" },
    sendRequestAboutChangingContactNameOrEmail: { id: "editProfile.sendRequestAboutChangingContactNameOrEmail", defaultMessage: "Send request about changing contact name or email", description: "Send request about changing contact name or email"},
    telegram: { id: "editProfile.telegram", defaultMessage: "Telegram", description: "Telegram" },
    telegramUsernameMustBe5to32long: { id: "editProfile.telegramUsernameMustBe5to32long", defaultMessage: "Telegram username must be 5-32 long and contain the '@' symbol at the beginning.", description: "Telegram username must be 5-32 long and contain the '@' symbol at the beginning." },
    messenger: { id: "editProfile.messenger", defaultMessage: "Messenger", description: "Messenger" },
    sendRequest: { id: "editProfile.sendRequest", defaultMessage: "Send request", description: "Send request" },
    contactPersonHasBeenChanged: { id: "editProfile.contactPersonHasBeenChanged", defaultMessage: "Contact {person} has been changed.",  description: "Contact person has been changed." },
    requestSent: { id: "editProfile.requestSent", defaultMessage: "Request sent", description: "Request sent" },
    pleaseEnterValidPhoneNumber: { id: "editProfile.pleaseEnterValidPhoneNumber", defaultMessage: "Please enter valid phone number", description: "Please enter valid phone number" },
    incorrectEmailAddresses:  { id: "editProfile.incorrectEmailAddresses", defaultMessage: "Incorrect email addresses", description: "Incorrect email addresses" },
    incorrectEmailAddress: { id: "editProfile.incorrectEmailAddress", defaultMessage: "Incorrect email address", description: "Incorrect email address" },
    teamsLink: { id: "editProfile.teamsLink", defaultMessage: "Teams link", description: "Teams link" },
    teamsEmail: { id: "editProfile.teamsEmail", defaultMessage: "Teams email", description: "Teams email" },
    incorrectTeamsLink: { id: "editProfile.incorrectTeamsLink", defaultMessage: "Incorrect teams link", description: "Incorrect teams link" },
})