import React, {useState, useRef, useEffect} from "react";
import styled from "styled-components";
import {
    ControlLabel, Form, FormControl, Uploader, Schema, Button, Alert, Icon
} from "rsuite";

import useWindowWidth from "../../../../../hooks/useWindowWidth";
import {api, apiFormData} from "../../../../../api/loginRoutes";
import {
    SMS_SUPPLIER_PLAN_UPLOAD_CSV_METHOD
} from "../../../../../const/apiMethods";

import {Spacer} from "../../../../../components/base/Spacer";
import {CustomModal} from "../../../../../components/base";

const FILES_DEFAULT = [];

const numberOptions = [
    {value: 'add', label: 'Add new numbers'},
    {value: 'replace', label: 'Replace group numbers'},
];

const testNumberOptions = [
    {value: 'none', label: 'Do not set test number'},
    {value: 'first', label: 'Set first number from list as test'},
    {value: 'random', label: 'Set random number from list as test'},
];


export default (
    {
        show,
        onClose,
        onSuccess,
        currentData,
        showReasonHandler,
        disabled
    }
) => {

    const maxWidth = 600;

    const formRef = useRef(null);
    const resizedWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);

    const [files, setFiles] = useState(FILES_DEFAULT);
    const [formValue, setFormValue] = useState({});


    useEffect(() => {
        if (files && files.length) {
            formRef.current.cleanErrorForFiled("text");
            return;
        }

    }, [files]);

    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        // if (files && files.length) {
        //     const dt = new DataTransfer();
        //     dt.items.add(files[0].blobFile);
        //     params.files = dt.files;
        //     params.upload_file_list = params.files;
        //     delete params.files;
        // }

        const dt = new DataTransfer();
        dt.items.add(files[0].blobFile);


        const params = {
            target: {
                'sms.supplier_plan_id': currentData?.id
            },
            upload_file_list: dt.files,
            files: dt.files
        };

        // params.upload_file_list = formValue?.file_list;

        const method = SMS_SUPPLIER_PLAN_UPLOAD_CSV_METHOD;

        const methodFunc = apiFormData;
        // const methodFunc = api;


        setLoading(true);

        methodFunc(method, params)
            .then(r => {

                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {params.files && `${params.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                if (r.reason_code) {
                    showReasonHandler({
                        ...r,
                        status: 1,
                        reasonCode: r.reason_code,
                        reasonHash: r.hash,
                    });
                }

                setFiles([]);
                onSuccess(r, method === apiFormData);
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <>
            <StyledModal
                show={show}
                showFooter={true}
                title={'Upload numbers'}
                width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
                onConfirm={handleSubmit}
                loading={loading}
                onClose={() => {
                    onClose();
                    setFiles([]);
                }}
                onExited={() => {
                    onClose();
                }}
            >
                <Form
                    ref={formRef}
                    // model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >
                    <>
                        <FormControl
                            disabled={files.length > 0}
                            accepter={StyledUploader}
                            fileList={files}
                            autoUpload={false}
                            multiple={false}
                            removable={true}
                            accept="text/csv"
                            onChange={(_files) => {
                                setFiles(_files);
                            }}
                            name="files"
                        >
                            <Button>Upload via CSV</Button>
                        </FormControl>

                        <Spacer size={15}/>

                        <StyledControlLabel>
                            * The CSV file must consist of 2 columns: Sender ID, and B-Number
                        </StyledControlLabel>

                    </>
                </Form>
            </StyledModal>
        </>
    )
};


const StyledUploader = styled(Uploader)`
    
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }

    .rs-uploader-file-item {
        background-color: transparent !important;
    }
    
    .rs-uploader-file-items {
        margin-top: 5px !important;
        flex-grow: 1;
    }
    
    .rs-uploader-file-item-title {
        white-space: normal !important;
    }

    .rs-uploader-file-item-size {
        margin-left: auto;
    }
`;

const StyledContainer = styled.div`
  display: flex;
`;

const StyledModal = styled(CustomModal)`
    .rs-modal-body {
        margin-left: -10px;
        margin-right: -10px;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const StyledCloseIcon = styled(Icon)`
    order: 1;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;
`;