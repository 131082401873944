import React, {useMemo, useEffect, useState} from "react";
import {
    CheckboxGroup,
    DateRangePicker,
    Form,
    FormGroup,
    SelectPicker,
    CheckPicker,
    FormControl,
    ControlLabel,
    Button,
    Input,
    Checkbox
} from "rsuite";
import Text from "components/Form/Text";
import CustomField from "components/Form/CustomField";
import {connect} from "react-redux";
import {getReferencesForReports} from "actions/references";
import {getAccountManagersBillingList, getAccountManagersDropdownList} from "actions/account_managers";
import FormHOC from "hoc/FilltersForm";
import {difference, servicePick, removeNilValues, toUTCDateTime} from "utils";
import CheckboxBadge from "components/Form/CheckboxBadge/CheckboxBadge";
import {ButtonPrimary} from "components/base/BaseButton";
import { usePrevious } from "hooks";
import DateTimeRangePicker from "components/DateRangePicker/DateTimerRangePicker";
import styled from "styled-components";
import {useIntl} from "react-intl";
import m from "definedMessages";
import {SCREEN_MEDIA} from "const";

const {mx} = SCREEN_MEDIA;

const {allowedRange} = DateRangePicker;


const TrafficReportsFilters = ({
    onApplyFilters,
    defaultFilters,
    currentFilters, 
    loading,
    preFilters,
    setPreFilters,
    globalSettings = {},
    service,
    isFailedMdr,
    ...props
}) => {
    const {formatMessage} = useIntl();

    const isFailedMdrDefaultValues = {
        ...defaultFilters,
        operator: null,
        cntr_key: null,
        [servicePick(service, "supplier_key", "supplier_id")]: null,
        [servicePick(service, "sender_id", "senderid")]: null,
        mcc_mnc: null,
        sender_sde_key: null,
        phone_sde_key: null,
        reason_list: [],
        group_checkbox: [],
        only_my_account: true
    };

    const trafficMdrDefaultValues = {
        ...defaultFilters,
        ...(!service ? {operator: null, cntr_key: null} : {}),
        [servicePick(service, "supplier_key", "supplier_id")]: null,
        originator_key: null,
        account_manager_name: null,
        dialer_id: null,
        sp_key: null,
        [servicePick(service, "a_sde_key", "sender_sde_key")]: null,
        [servicePick(service, "b_sde_key", "phone_sde_key")]: null,
        [servicePick(service, "a_number", "senderid")]: null,
        [servicePick(service, undefined, "mcc_mnc")]: null,
        dialer_manager_id: null,
        group_checkbox: [],
        only_my_account: true
    };

    const [filters, setFilters] = useState(currentFilters);
    const [grouping, setGrouping] = useState(currentFilters.group_checkbox);
    const [onlyMyAccountCheck, setOnlyMyAccountCheck] = useState(true);

    const prevIsFailedMdr = usePrevious(isFailedMdr);

    const [secondUpdateFixer, setSecondUpdateFixer] = useState(null);
    const [currentFiltersComponent, setCurrentFiltersComponent] = useState(<></>)

    const supplierPlanList = useMemo(() => {
        if (!filters?.supplier_id) 
            return [];

        const supplier = props.supplierList.find(supplier => supplier.id === filters.supplier_id);
        return supplier && supplier.supplier_plan_list || [];
    }, [filters?.supplier_id]);

    useEffect(() => {
        if (prevIsFailedMdr !== isFailedMdr) {
            const deafultFilterValues = isFailedMdr ? isFailedMdrDefaultValues : trafficMdrDefaultValues;
            setFilters(deafultFilterValues);

            const preDeafultFilterValues = {...deafultFilterValues};

            if (preDeafultFilterValues.range && preDeafultFilterValues.range.length) {
                // filterValues["start_date"] = toStartDayUTCTime(filterValues.range[0]);
                // filterValues["end_date"] = toEndDayUTCTime(filterValues.range[1]);
                preDeafultFilterValues["start_date"] = toUTCDateTime(preDeafultFilterValues.range[0]);
                preDeafultFilterValues["end_date"] = toUTCDateTime(preDeafultFilterValues.range[1]);

                delete preDeafultFilterValues.range;
            }

            setPreFilters(removeNilValues(preDeafultFilterValues))

            setOnlyMyAccountCheck(true);

            setGrouping(deafultFilterValues?.group_checkbox);
            setSecondUpdateFixer(null);
        }
    }, [isFailedMdr])

    const allowedRanges = useMemo(() => {
        const date = new Date();
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const endDate = new Date(date.getFullYear(), date.getMonth(), 1);
        const intervalMonth = globalSettings.cdr_full__interval_month || 0;
        const dateRange =  [
            new Date( startDate.setMonth(startDate.getMonth() - intervalMonth) ),
            new Date( endDate.setMonth(endDate.getMonth() + 12) )
        ];

        return [
            `${dateRange[0].getFullYear()}-${dateRange[0].getMonth() + 1}-${dateRange[0].getDate()}`,
            `${dateRange[1].getFullYear()}-${dateRange[1].getMonth() + 1}-${dateRange[1].getDate()}`,
        ];
    }, [globalSettings]);


    const handleChange = (formValues) => {
        const filterValues = {...formValues};

        let groupCheckboxes = filterValues.group_checkbox;
        const radioCheckboxes = ["dt_hour", "dt_day", "dt_month", "dt_year"];
        const mccMncCheckboxes = ["mcc", "mcc_mnc"];
        const changedCheckbox = difference(groupCheckboxes, grouping)[0];

        const valuesWithoutRadio1 = groupCheckboxes.filter((el) => !radioCheckboxes.includes(el));
  
        if (changedCheckbox && radioCheckboxes.includes(changedCheckbox)) {
            groupCheckboxes = [...valuesWithoutRadio1, changedCheckbox];
        }

        const valuesWithoutRadio2 = groupCheckboxes.filter((el) => !mccMncCheckboxes.includes(el));

        if (changedCheckbox && mccMncCheckboxes.includes(changedCheckbox)) {
            groupCheckboxes = [...valuesWithoutRadio2, changedCheckbox];
        }  

        if (!service && !groupCheckboxes.includes("supplier_name")) {
            if (groupCheckboxes.includes("supplier_plan")) {
                const index = groupCheckboxes.indexOf("supplier_plan");
                groupCheckboxes.splice(index, 1);
            }
        }

        setGrouping(groupCheckboxes);

        if (!service && (filterValues["supplier_id"] === null || filterValues["supplier_id"] !== filters["supplier_id"])) {
            if ("supplier_plan_id" in filterValues) {
                delete filterValues.supplier_plan_id;
            }
        }

        setFilters({...filterValues, group_checkbox: groupCheckboxes});

        if (filterValues.range && filterValues.range.length) {
            // filterValues["start_date"] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues["end_date"] = toEndDayUTCTime(filterValues.range[1]);
            filterValues["start_date"] = toUTCDateTime(filterValues.range[0]);
            filterValues["end_date"] = toUTCDateTime(filterValues.range[1]);

            delete filterValues.range;
        }

        setPreFilters(removeNilValues({...filterValues, group_checkbox: groupCheckboxes}))
    };

    const handleApply = () => {
        onApplyFilters( removeNilValues(preFilters) );
    };

    const checkOnlyAccount = (checked) => {
        setFilters((value) => {
            return {
                ...value,
                only_my_account: checked
            }
        });
        setPreFilters((preValue) => {
            return {
                ...preValue,
                only_my_account: checked
            }
        });
        setOnlyMyAccountCheck(checked);
    };

    useEffect(() => {
        props.getReferencesForReports();
        if (service) {
            props.getAccountManagersBillingList();
        }
        props.getAccountManagersDropdownList({}, !service);
    }, [service]);

    const reasonList = props.reasonList ? props.reasonList.map((reason, idx) => { return { name: reason, key: reason } }) : [];
    
    const handleChangeDateTime = (value) => {
        const filter = {...value};
        const currentDatetimeFilter = {range: [filter.range[0], filter.range[1]]};

        const filtersToUpdate = {
            ...filters,
            ...filter,
            ...currentDatetimeFilter
        };

        setFilters(filtersToUpdate);

        const filterValues = {...filtersToUpdate};

        if (filterValues.range && filterValues.range.length) {
            // filterValues["start_date"] = toStartDayUTCTime(filterValues.range[0]);
            // filterValues["end_date"] = toEndDayUTCTime(filterValues.range[1]);
            filterValues["start_date"] = toUTCDateTime(filterValues.range[0]);
            filterValues["end_date"] = toUTCDateTime(filterValues.range[1]);

            delete filterValues.range;
        }

        setPreFilters(removeNilValues(filterValues))
    };

    const accountManagersFiltered = props.accountManagerList.filter(value => value.active || value.has_accounts);

    useEffect(() => {
        const mdrFailedFilters = <>
            {/* <CustomField
                accepter={DateRangePicker}
                cleanable={false}
                placeholder="Select Date Range"
                disabledDate={allowedRange(...allowedRanges)}
                name="range"
                width={250}
            /> */}
             <DateTimeRangePicker
                onChange={(value) => {
                    handleChangeDateTime(value);
                }}
                defaultStartDate={defaultFilters.range[0]}
                defaultEndDate={defaultFilters.range[1]}
                startDate={filters?.range[0]}
                endDate={filters?.range[1]}
                showRanges
                // input={false}
            />
            {!service ?<Text 
                name="operator"
                placeholder="Operator"
                width={150}
                value={filters.operator}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="cntr_key"
                data={props.countryList}
                placeholder="Country"
                name="cntr_key"
                width={200}
            /> : <></>}
            <CustomField
                accepter={SelectPicker}
                data={props.supplierList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier"
                name={servicePick(service, "supplier_key", "supplier_id")}
                width={200}
            />
            {!service ?<CustomField
                accepter={SelectPicker}
                data={supplierPlanList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier trunk"
                name="supplier_plan_id"
                width={200}
                disabled={!filters?.supplier_id}
            /> : <></>}
            <CustomField
                accepter={Input}
                width={150}
                placeholder="Sender ID"
                name={servicePick(service, "sender_id", "senderid")}
            />
            <CustomField
                accepter={Input}
                width={120}
                placeholder="MCC/MNC"
                name="mcc_mnc"
            />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Origin"
                name="sender_sde_key"
            />
            <CustomField
                accepter={SelectPicker}
                width={300}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Destination"
                name="phone_sde_key"
            />
            <CustomField
                accepter={CheckPicker}
                width={300}
                data={reasonList}
                labelKey="name"
                valueKey="key"
                placeholder="Reason"
                name="reason_list"
            />
            {!service ?
                <Checkbox
                    name="only_my_account"
                    onChange={(value, checked) => {
                        checkOnlyAccount(checked);
                    }}
                    checked={onlyMyAccountCheck}
                    style={{width: 240, display: "inline-block"}}
                >
                    Show only my account traffic
                </Checkbox>
            : <></>}
        </>;

        const servicePlanField = <CustomField
            accepter={SelectPicker}
            data={props.servicePlanList}
            value={filters.sp_key}
            labelKey="name"
            valueKey="sp_key"
            placeholder="Trunk type"
            name="sp_key"
            width={200}
        />;
        const trafficMdrFilters = <>
            {/* <CustomField
                accepter={DateRangePicker}
                cleanable={false}
                placeholder="Select Date Range"
                disabledDate={allowedRange(...allowedRanges)}
                name="range"
                width={250}
            /> */}
             <DateTimeRangePicker
                onChange={(value) => {
                    handleChangeDateTime(value);
                }}
                defaultStartDate={defaultFilters.range[0]}
                defaultEndDate={defaultFilters.range[1]}
                startDate={filters?.range[0]}
                endDate={filters?.range[1]}
                showRanges
                // input={false}
            />
            {!service ?<Text 
                name="operator"
                placeholder="Operator"
                width={150}
                value={filters.operator}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="cntr_key"
                data={props.countryList}
                placeholder="Country"
                name="cntr_key"
                width={200}
            /> : <></>}
            {!service ? <CustomField
                accepter={SelectPicker}
                data={props.supplierList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier"
                name={servicePick(service, "supplier_key", "supplier_id")}
                width={200}
            /> : <></>}
            {!service ?<CustomField
                accepter={SelectPicker}
                data={supplierPlanList}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier trunk"
                name="supplier_plan_id"
                width={200}
                disabled={!filters?.supplier_id}
            /> : <></>}
            {!service ?<CustomField
                accepter={SelectPicker}
                data={accountManagersFiltered}
                labelKey="name"
                valueKey="id"
                placeholder="Supplier AM"
                name="supplier_manager_id"
                width={200}
            /> : <></>}
            {service ? <CustomField
                accepter={SelectPicker}
                labelKey="name"
                valueKey="acc_key"
                data={props.originatorList}
                placeholder="Originator"
                name="originator_key"
                width={200}
            /> 
            : <Text name="originator_name" placeholder="Originator" width={200}/>}
            {service ? <CustomField
                accepter={SelectPicker}
                labelKey="ACM_NAME"
                valueKey="ACM_KEY"
                data={props.accountManagerBilList}
                placeholder="Originator AM"
                name="originator_manager_key"
                width={200}
            /> : <></>}
            <CustomField
                accepter={SelectPicker}
                data={props.dialerList}
                value={filters.dialer_id}
                labelKey="name"
                valueKey="id"
                placeholder="Dialer"
                name="dialer_id"
                width={200}
            />
            {!service ? servicePlanField : <></>}
            {<CustomField
                accepter={SelectPicker}
                data={accountManagersFiltered}
                labelKey="name"
                valueKey="id"
                placeholder="Dialer AM"
                name="dialer_manager_id"
                width={200}
            />}
            <CustomField
                accepter={SelectPicker}
                width={200}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Subdest Origin"
                name={servicePick(service, "a_sde_key", "sender_sde_key")}
            />
            <CustomField
                accepter={SelectPicker}
                width={200}
                data={props.subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                placeholder="Subdest Dest"
                name={servicePick(service, "b_sde_key", "phone_sde_key")}
            />
            {/* {!service && <br/>} */}
            {service ? <CustomField
                accepter={Input}
                width={150}
                placeholder="A-Number"
                name="a_number"
            /> : <CustomField
                accepter={Input}
                width={150}
                placeholder="Sender ID"
                name="senderid"
            />}
            {service ? servicePlanField : <></>}
            {!service ? <>
                <CustomField
                    accepter={Input}
                    width={120}
                    placeholder="MCC/MNC"
                    name="mcc_mnc"
                />
            </> : <></>}
            {!service ?
                <Checkbox
                    name="only_my_account"
                    onChange={(value, checked) => {
                        checkOnlyAccount(checked);
                    }}
                    checked={onlyMyAccountCheck}
                    style={{width: 240, display: "inline-block"}}
                >
                    Show only my account traffic
                </Checkbox>
            : <></>}
        </>;
        
        const currentFilterComponent = isFailedMdr ? mdrFailedFilters : trafficMdrFilters;
        setCurrentFiltersComponent(currentFilterComponent);
    }, [filters, secondUpdateFixer, props.supplierList, props.dialerList, props.subdestinationList, props.originatorList, props.reasonList, isFailedMdr]);

    const mdrFailedGroups = <FormControl
        name="group_checkbox"
        accepter={CheckboxGroup}
        inline
        value={grouping}
    >
        <CheckboxBadge value="operator">Operator</CheckboxBadge>
        <CheckboxBadge value="country">Country</CheckboxBadge>
        <CheckboxBadge value="supplier_rate">Supplier Rate</CheckboxBadge>
        <CheckboxBadge value="supplier_name">Supplier</CheckboxBadge>
        <CheckboxBadge value="supplier_plan" disabled={!grouping.includes("supplier_name")}>Supplier trunk</CheckboxBadge>
        <CheckboxBadge value="phone">B-Number</CheckboxBadge>
        <CheckboxBadge value="mcc_mnc">MCC/MNC</CheckboxBadge>
        <CheckboxBadge value="mcc" wrapperClassName={"mr-4"}>MCC</CheckboxBadge>
        <CheckboxBadge value="dt_hour">Hour</CheckboxBadge>
        <CheckboxBadge value="dt_day">Day</CheckboxBadge>
        <CheckboxBadge value="dt_month">Month</CheckboxBadge>
        <CheckboxBadge value="dt_year">Year</CheckboxBadge>

        <br/>
        <CheckboxBadge value="sender_wz_name">Zone (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_wz_name">Zone (dest)</CheckboxBadge>
        <CheckboxBadge value="sender_de_name">Dest (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_de_name">Dest (dest)</CheckboxBadge>
        <CheckboxBadge value="sender_sde_name">Subdest (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_sde_name">Subdest (dest)</CheckboxBadge>
        <CheckboxBadge value="senderid">Sender ID</CheckboxBadge>
        <CheckboxBadge value="reason">Reason</CheckboxBadge>
    </FormControl>

    const trafficMdrGroups = service ? <FormControl
        name="group_checkbox"
        accepter={CheckboxGroup}
        inline
        value={grouping}
    >
        <CheckboxBadge value="a_number">A-Number</CheckboxBadge>
        <CheckboxBadge value="b_number">B-Number</CheckboxBadge>
        <CheckboxBadge value="range_name">Range</CheckboxBadge>
        <CheckboxBadge value="originator_key">Originator</CheckboxBadge>
        <CheckboxBadge value="originator_manager_key">Originator AM</CheckboxBadge>
        <CheckboxBadge value="originator_rate">Originator rate</CheckboxBadge>
        <CheckboxBadge value="dialer_key">Dialer</CheckboxBadge>
        <CheckboxBadge value="dialer_manager_key">Dialer AM</CheckboxBadge>
        <CheckboxBadge value="service_plan_name">Trunk type</CheckboxBadge>
        <CheckboxBadge value="dialer_rate">Dialer rate</CheckboxBadge>
        {!service ? <CheckboxBadge value="supplier_key" wrapperClassName={"mr-4"}>Supplier</CheckboxBadge> : <></>}
        <CheckboxBadge value="dt_hour">Hour</CheckboxBadge>
        <CheckboxBadge value="dt_day">Day</CheckboxBadge>
        <CheckboxBadge value="dt_month">Month</CheckboxBadge>
        <CheckboxBadge value="dt_year">Year</CheckboxBadge>
        <br/>
        <CheckboxBadge value="a_wz_key">Zone (origin)</CheckboxBadge>
        <CheckboxBadge value="b_wz_key">Zone (dest)</CheckboxBadge>
        <CheckboxBadge value="a_de_key">Dest (origin)</CheckboxBadge>
        <CheckboxBadge value="b_de_key">Dest (dest)</CheckboxBadge>
        <CheckboxBadge value="a_sde_key">Subdest (origin)</CheckboxBadge>
        <CheckboxBadge value="b_sde_key">Subdest (dest)</CheckboxBadge>
        <CheckboxBadge value="margin" >Margin</CheckboxBadge>
    </FormControl>
    : <FormControl
        name="group_checkbox"
        accepter={CheckboxGroup}
        inline
        value={grouping}
    >   
        <CheckboxBadge value="range_name">Range</CheckboxBadge>
        <CheckboxBadge value="originator_name">Originator</CheckboxBadge>
        <CheckboxBadge value="operator">Operator</CheckboxBadge>
        <CheckboxBadge value="country">Country</CheckboxBadge>
        <CheckboxBadge value="supplier_rate">Supplier Rate</CheckboxBadge>
        <CheckboxBadge value="supplier_name">Supplier</CheckboxBadge>
        <CheckboxBadge value="supplier_plan" disabled={!grouping.includes("supplier_name")}>Supplier trunk</CheckboxBadge>
        <CheckboxBadge value="supplier_manager">Supplier AM</CheckboxBadge>
        <CheckboxBadge value="dialer_rate">Dialer Rate</CheckboxBadge>
        <CheckboxBadge value="dialer_name">Dialer</CheckboxBadge>
        <CheckboxBadge value="service_plan_name">Trunk type</CheckboxBadge>
        <CheckboxBadge value="dialer_manager">Dialer AM</CheckboxBadge>
        <CheckboxBadge value="phone">B-Number</CheckboxBadge>
        <CheckboxBadge value="mcc_mnc">MCC/MNC</CheckboxBadge>
        <CheckboxBadge value="mcc">MCC</CheckboxBadge>
        <br/>
        <CheckboxBadge value="dt_hour">Hour</CheckboxBadge>
        <CheckboxBadge value="dt_day">Day</CheckboxBadge>
        <CheckboxBadge value="dt_month">Month</CheckboxBadge>
        <CheckboxBadge value="dt_year" wrapperClassName={"mr-5"}>Year</CheckboxBadge>


        <CheckboxBadge value="delivery_status">Delivery Status</CheckboxBadge>
        <CheckboxBadge value="sender_wz_name">Zone (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_wz_name">Zone (dest)</CheckboxBadge>
        <CheckboxBadge value="sender_de_name">Dest (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_de_name">Dest (dest)</CheckboxBadge>
        <CheckboxBadge value="sender_sde_name">Subdest (origin)</CheckboxBadge>
        <CheckboxBadge value="phone_sde_name">Subdest (dest)</CheckboxBadge>
        <CheckboxBadge value="senderid">Sender ID</CheckboxBadge>
        <CheckboxBadge value="reason">Reason</CheckboxBadge>
    </FormControl>;

    const currentGroups = isFailedMdr ? mdrFailedGroups : trafficMdrGroups;

    return (
        <>
            <Form
                layout="inline"
                onChange={handleChange}
                formDefaultValue={defaultFilters}
                formValue={filters}
            >
                <ControlLabel className={"d-block mb-2"}>Filters</ControlLabel>
                {currentFiltersComponent}
                <br />
                <FormGroup>
                    <ControlLabel className={"d-block"}>Group by</ControlLabel>
                    {currentGroups}
                </FormGroup>
                <FormGroupRight>
                    <ButtonPrimary
                        style={{
                            padding: "8px 34px",
                            marginLeft: 12
                        }}
                        onClick={handleApply}
                        disabled={loading}
                    >
                        {formatMessage(m.applyFilter)}
                    </ButtonPrimary>
                </FormGroupRight>
            </Form>
        </>
    );
};

const mapStateToProps = ({references, traffic_reports, account_managers, auth}) => ({
    service: auth.service,
    loading: traffic_reports.loading,
    originatorList: references.client_list,
    reasonList: references["sms.reason_list"],
    dialerList: auth.service ? references.dialer_list : references["sms.dialer_list"],
    supplierList: auth.service ? references.supplier_list : references["sms.supplier_list"],
    supplierPlanList: references["sms.supplier_plan_list"],
    servicePlanList: references.service_plan_list,
    countryList: references.country_list,
    accountManagerBilList: account_managers.billingList,
    accountManagerList: account_managers.dropdown_list,
    subdestinationList: references.subdestination_list,
});

const mapDispatchToProps = {
    getReferencesForReports,
    getAccountManagersBillingList,
    getAccountManagersDropdownList
};

export default connect(mapStateToProps, mapDispatchToProps)(FormHOC(TrafficReportsFilters));

const FormGroupRight = styled(FormGroup)`
    && {
        float: right;
        position: relative;
        height: 120px;
        margin-right: 0;
    }

    && button {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 160px;
    }

    @media (max-width: ${mx.min}px) {

        && {
            height: fit-content;
        }

        && button {
            position: relative;
        }
    }
`;