import common from "./common";
import accesses from "./accesses";
import hot_accesses from "./hot_accesses";
import validation from "./validation";
import balanceInfo from "./balanceInfo";
import numbers from "./numbers";
import liveCalls from "./liveCalls";
import layout from './layout';
import pagination from "./pagination";
import testCalls from "./testCalls";
import traffic from "./traffic";
import cdr from "./cdr";
import price from "./price";
import cli from "./cli";
import ivr from "./ivr";
import paymentDetails from "./paymentDetails";
import payments from "./payments";
import modalResponseHandler from "./modalResponseHandler";
import editProfile from "./editProfile";
import accessListFilters from "./accessListFilters";
import news from "./news";
import subaccounts from "./subaccounts";
import menu from "./menu";
import allocations from "./allocations";

export default {
    ...common,
    ...hot_accesses,
    ...accesses,
    ...validation,
    ...balanceInfo,
    ...numbers,
    ...liveCalls,
    ...layout,
    ...pagination,
    ...testCalls,
    ...traffic,
    ...cdr,
    ...price,
    ...cli,
    ...ivr,
    ...paymentDetails,
    ...payments,
    ...modalResponseHandler,
    ...editProfile,
    ...accessListFilters,
    ...news,
    ...subaccounts,
    ...menu,
    ...allocations
};