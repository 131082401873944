import React, {useState, useEffect, useRef} from 'react';
import {api, getFileResponse} from 'api/loginRoutes';
import AccountsGetForm from 'components/Filters/AccountsGetForm';
import {ButtonPrimary} from "../../../../components/base/BaseButton";
import {CustomModal} from '../../../../components/base/BaseModal';
import styled from "styled-components";
import DialerTrunk from 'components/Filters/DialerTrunk';
import AllocateForm from './AllocateForm';
import {servicePick, getServiceSmsPrefix, getBlobContent} from 'utils';
import ModalAllocationResult from '../../../../components/Modal/ModalAllocationResult';
import { 
    TRUNK_LIST_FOR_ACCOUNTS_METHOD,
    SMS_TRUNK_LIST_FOR_ACCOUNTS_METHOD,
    ALLOCATION_INCORPORATED_MASS_API,
    SMS_ALLOCATION_GAN_MASS_BY_MANAGER_API,

    TRUNK_NUMBER_GET_LIST_API
} from '../../../../const/apiMethods';


const defultFormValue = {
    numbers: 10,
    random_number: false,
};


export default ({
    rowKey,
    groupList,
    disabled,
    extraParams,
    currentGroupData,
    showReasonHandler,
    buttonText = 'Allocate',
    style = {},
    service,
    onSuccess
}) => {

    const defaultTrunkId = null;

    const formRef = useRef(null);
    const [show, setShow] = useState(false);

    const [trunkId, setTrunkId] = useState(defaultTrunkId);
    const [dialers, setDialers] = useState([]);

    const [accountFilter, setAccountFilter] = useState(null);
    const [accountFilterLoading, setAccountFilterLoading] = useState(false);

    const [formValue, setFormValue] = useState(defultFormValue);
    const [modalLoading, setModalLoading] = useState(false);
    const [dialerLoading, setDialerLoading] = useState(true);

    const [showResult, setShowResult] = useState(false);
    const [summaryState, setSummaryState] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [csvContent, setCsvContent] = useState(null);
    const [totalNumbersForced, setTotalNumbersForced] = useState(null);

    const [clearTrunk, setClearTrunk] = useState(false);

    const [hashCode, setHashCode] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);

    const trunkListMethod = getServiceSmsPrefix(service, TRUNK_LIST_FOR_ACCOUNTS_METHOD);

    useEffect(() => {
        setClearTrunk(false);
    }, []);

    useEffect(() => {
        if (dialers.length) {
            const firstTrunk = dialers[0];
            const currentTrunkIdList = dialers.map((trunk) => trunk.trunk_id);

            if (!currentTrunkIdList.includes(trunkId)) {
                setTrunkId(firstTrunk.trunk_id);
            }
        }
    }, [dialers]);

    useEffect(() => {
        if (show) {
            api(trunkListMethod, {
                filter: {
                    gan: true,
                    str: accountFilter || ""
                }
            }).then(({trunk_list}) => {
                setDialers((trunk_list || [])
                    // .filter(x => x.incorporated)
                    .map(x => ({
                        ...x,
                        trunk_id: x.trunk_id,
                        _name: `${x.acc_name}\\${x.tr_name}\\${x.sp_name}`
                    }))
                );
            });
            setDialerLoading(false);
        }
    }, [show, accountFilter]);

    const onSubmit = async () => {

        if ( !formRef.current.check() )
            return;
        const groupPickedList = groupList.filter(group => {
            if ('gan_group_key_list' in extraParams)
                return extraParams.gan_group_key_list.includes(group[rowKey]);
        });

        if (extraParams.gan_group_key_list) {
            delete extraParams.gan_group_key_list;
        }

        const params = {
            ...extraParams,
            ...formValue,
            numbers: +formValue.numbers,
            target: {
                [getServiceSmsPrefix(service, 'trunk_id')]: trunkId,
                ...(!service ? {'sms.supplier_gan_group_id_list': groupPickedList.map(group => group?.id)} : {}) 
            },
        };

        setModalLoading(true);

        const method = servicePick(service, ALLOCATION_INCORPORATED_MASS_API, SMS_ALLOCATION_GAN_MASS_BY_MANAGER_API);

        const result = await api(method, params);

        if (result.hash) {
            setHashCode(result.hash);
        }

        if (result.message) {
            setResponseMessage(result.message);
        }

        if (!result) {
            setModalLoading(false);
            return;
        }

        const activeTrunk = dialers.find(item => item.trunk_id === trunkId);

        if (result.reason_code && (!result.trunk_number_transaction || !result.trunk_number_transaction.numbers) ) {
            showReasonHandler({
                ...result,
                ...params,
                reasonCode: result.reason_code,
                reasonHash: result.hash,
                trunk: activeTrunk || {},
            });
            setModalLoading(false);
            return;
        }

        const {trunk_number_transaction} = result;
        const summaryInfo = {...trunk_number_transaction};
        const transactionId = summaryInfo.id;


        const trunkMethod = getServiceSmsPrefix(service, TRUNK_NUMBER_GET_LIST_API);

        getFileResponse(trunkMethod, {target: {[getServiceSmsPrefix(service, 'trunk_number_transaction_id')]: transactionId}})
            .then((response) => {
                if (response) {
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    });
                }
            });

        setModalLoading(false);
        setSummaryState(summaryInfo);
        setTransactionId(transactionId);
        setClearTrunk(true);

        setTotalNumbersForced(summaryInfo.numbers);
        setShowResult(true);

        onSuccess();
        setAccountFilter(null);
        setFormValue(defultFormValue);
        setShow(false);
    };

    return (
        <>
            <ButtonPrimary
                disabled={disabled} 
                onClick={() => setShow(true)}
                style={style}
            >
                {buttonText}
            </ButtonPrimary>

            <CustomModal
                show={show}
                title="Allocate numbers"
                onClose={() => {
                    setShow(false);
                    setClearTrunk(true);
                    setAccountFilter(null);
                    setFormValue(defultFormValue);
                }}
                onConfirm={onSubmit}
                showFooter={true}
                loading={modalLoading}
                disabled={!trunkId}
                extraDisabled={!dialers.length}
                confirmButtonText="Confirm"
            >
                Choose dialer and trunk.

                <AccountsGetFormWrapper>
                    <AccountsGetForm
                        setLoading={setAccountFilterLoading}
                        setAccountFilter={setAccountFilter}
                    />
                </AccountsGetFormWrapper>

                <DialerTrunk
                    trunkId={trunkId}
                    clearTrunk={clearTrunk}
                    disabledTrunk={disabled}

                    dialerTrunkList={dialers}
                    dialerLoading={dialerLoading}
                    setTrunkId={setTrunkId}

                    onClear={() => setTrunkId(null)}
                    onChange={(trunk) => setTrunkId(trunk.trunk_id)}
                />

                <AllocateForm
                    currentGroupData={currentGroupData}
                    trunkId={trunkId}
                    trunksList={dialers}
                    updateFormRef={ref => formRef.current = ref}

                    disabled={disabled || accountFilterLoading}
                    formValue={formValue}
                    setFormValue={setFormValue}
                />
            </CustomModal>

            <ModalAllocationResult
                service={service}

                showFullList={true}
                hideBufferButtons={false}
                
                show={showResult}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                trunkId={trunkId}
                defaultTrunkId={defaultTrunkId}
                csvContent={csvContent}
                transactionId={transactionId}
                hashCode={hashCode}
                responseMessage={responseMessage}

                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);
                    
                    if (hashCode)
                        setHashCode(null);
                    
                    if (responseMessage)
                        setResponseMessage(null);
                }}
            />
        </>

    );
};


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
    margin-top: 10px;
`;