import React, {useState, useEffect} from "react";
import {Alert, Loader, Placeholder} from "rsuite";
import CreateForm from "./CreateForm";
import PageHeader from "components/base/PageHeader";
import PanelLayout from "components/base/PanelLayout";
import {api} from "api/loginRoutes";
import {withRouter} from "react-router-dom";
import {SMS_SUPPLIER_CREATE_METHOD} from "const/apiMethods";
import {checkPermissionsFor} from "store/storeHelpers";

import { transformed_service_reverse_data, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY } from "const";
import {servicePick} from "utils";


const {Paragraph} = Placeholder;
const USER_NAME_ALREADY_USED_CODE = 4;

const NewSupplier = ({
    service,
    history,
    authInfo, 
    howFindList,
    countryList,
    currency_list,
    admin_role_list,
    client_role_list,
    loadingReferences,
    registeredAccount,
    setNewCreatedAccountId,
    payment_terms_list,
    account_manager_list,

    getAccountManagersDropdownList,
    ...props
}) => {
    const formDefaultValue = {
        account_manager_id: null,
        cur_key: null,
        pt_key: null
    };

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        const accountManagerList = account_manager_list.filter(value => value.active || value.has_accounts);
        
        setUpFormDefaultValues(service, currency_list, payment_terms_list, accountManagerList);
    }, [service, currency_list, payment_terms_list, account_manager_list]);

    const setUpFormDefaultValues = (service, currencyList, paymentTermsList, acountManagerList) => {
        if (paymentTermsList.length)
            formDefaultValue.pt_key = paymentTermsList[0].pt_key;

        // if (acountManagerList.length)
        //     formDefaultValue.account_manager_id = acountManagerList[0].id;
        if (currencyList.length)
            formDefaultValue.cur_key = servicePick(service, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY);
    };

    const onCancel = () => {
        history.goBack();
    };

    const onSubmit = async (data, account_manager_id, users, contacts = [], reg_key) => {
        setDisabled(true);

        const method = SMS_SUPPLIER_CREATE_METHOD;

        try {
            const result = await api(method, {
                ...data,
                service: transformed_service_reverse_data[service],
                target: {
                    account_manager_id
                }
            }, true);
            
            setDisabled(false);

            if (result && result.account_id) {
                localStorage.removeItem(`CURRENT_SUPPLIER_${result.account_id}`);
                setNewCreatedAccountId(result.account_id);

                if (!checkPermissionsFor("account_user:create") || !checkPermissionsFor("account_contact:create")) {
                    history.replace("/reload");
                    history.push({
                        pathname: `/sms-suppliers/view/${result.account_id}`,
                        search: "?tab=general"
                    });
                } else {
                    await Promise.all([
                        ...users
                            .map(
                                (user) => api("account_user:create", {
                                    ...user,
                                    ...(reg_key ? {reg_key} : {}),
                                    target: {
                                        account_id: result.account_id
                                    }
                                })
                            ),
                        ...contacts
                            .map(
                                (contact) =>{
                                    api("account_contact:create", {
                                        ...contact,
                                        target: {account_id: result.account_id}
                                    }, true)
                                }
                            )
                        ])
                        .finally(() => {
                            history.replace("/reload");
                            history.push({
                                pathname: `/sms-suppliers/view/${result.account_id}`,
                                search: "?tab=general"
                            });
                        });
                        
                    }
            } else {
                if (result.error.code === USER_NAME_ALREADY_USED_CODE) {
                    // Alert.error("Account name already exists, please change to another");
                    Alert.error(result.error.data?.error_type);
                }
                setDisabled(false);
            }
        } catch (e) {
            setDisabled(false);
        }
    };

    return (
        <PanelLayout>
            <PageHeader title="Create Supplier"/>
            {loadingReferences && <Loader backdrop content="loading..." vertical/>}

            {loadingReferences
                ?
                <Paragraph rows={5}/>
                :
                <CreateForm
                    onSubmit={onSubmit}
                    disabled={loadingReferences || disabled}
                    formDefaultValue={formDefaultValue}
                    onCancel={onCancel}
                    isSeparatedPage={true}
                    newAccount
                    newSupplier
                    isSupplier
                    {...{
                        service,
                        authInfo,
                        currency_list,
                        payment_terms_list,
                        account_manager_list,
                        client_role_list,
                        admin_role_list,
                        countryList,
                        howFindList,
                        // getAccounts,
                        getAccountManagersDropdownList
                    }}
                />
            }
        </PanelLayout>
    )
};

export default withRouter(NewSupplier);