import React, {useLayoutEffect, useState, useMemo, useEffect} from "react";
import NumberForm from "./NumberForm";
import MassForm from "./MassForm";
import ListForm from "./ListForm";
import SequenceForm from "./SequenceForm";
import TemplateListForm from "./TemplateListForm";
import ModalSendApi from "../../../../../components/Modal/ModalSendApi";
import {connect} from "react-redux";
import Tabs from "./Tabs";
import DialerTrunk from "../../../../../components/Filters/DialerTrunk";
import {getDialerTrunk} from "../../../../../actions/references";
import {getSuppliers} from "../../../../../actions/suppliers";
import {closeAllocationModal} from "../../../../../actions/settings";
import ModalAllocationResult from "../../../../../components/Modal/ModalAllocationResult";
import AccountsGetForm from "../../../../../components/Filters/AccountsGetForm";
import styled from "styled-components";
import {useWindowWidth} from "../../../../../hooks";
import {
    SMS_ACCOUNT_ID,  
    APP_TYPE_ADMIN, 
    SP_OTP_TYPE,
    SP_KEY__CONFERENCE_CALLS
} from "const";
import DialerTrunkDropdown from "../../../../../components/Filters/DialerTrunkDropdown";
import {pageIdLogObject} from "../../../../../utils/pageIdLogger";
import {TRUNK_NUMBER_GET_LIST_API} from "../../../../../const/apiMethods";
import {getFileResponse} from "../../../../../api/loginRoutes";
import {getBlobContent, getServiceSmsPrefix} from "../../../../../utils";


const AllocateModal = ({
    show,
    appType,
    getDialerTrunk,
    disabledTrunk = false,
    activeTrunk: _activeTrunk,
    accountJoinList,
    account,
    service,
    service_plan_list,
    trunk_id: _trunk_id,
    sp_key: _sp_key,
    sp_auth: _sp_auth,
    spOtpType: _spOtpType,
    update,
    onClose,
    isDialerTrunkFiltered = false,
    account_id: _account_id,
    dialerTrunkList,
    supplierList,
    getSuppliers,
    getTrunkNumberCsvById,
    pageId = null,
    match = {},
    closeAllocationModal: closeModal
}) => {

    const windowWidth = useWindowWidth();

    const [activeTab, setActiveTab] = useState("numberForm");

    const [activeTrunk, setActiveTrunk] = useState(_activeTrunk);
    useEffect(() => setActiveTrunk(_activeTrunk), [_activeTrunk]);

    const [spAuth, setSpAuth] = useState(_sp_auth);
    useEffect(() => setSpAuth(_sp_auth), [_sp_auth]);

    const [allocateByPrefixesStatus, setAllocateByPrefixesStatus] = useState(spAuth);
    useEffect(() => setAllocateByPrefixesStatus(spAuth), [spAuth]);

    const [spOtpType, setSpOtpType] = useState(_spOtpType);
    useEffect(() => setSpOtpType(_spOtpType), [_spOtpType]);

    const [sp_key, setSpKey] = useState(_sp_key);
    useEffect(() => setSpKey(_sp_key), [_sp_key]);

    const [trunk_id, setTrunkId] = useState(_trunk_id);
    useEffect(() => setTrunkId(_trunk_id), [_trunk_id]);

    const [account_id, setAccountId] = useState(_account_id);
    useEffect(() => setAccountId(_account_id), [_account_id]);

    const [accountFilter, setAccountFilter] = useState(null);

    const [clearSelectedData, setClearSelectedData] = useState(false);

    const [totalNumbersForced, setTotalNumbersForced] = useState(null)
    const [showResult, setShowResult] = useState(false);
    const [hideBufferButtons, setHideBufferButtons] = useState(false);
    const [filteredAccounts, setFilteredAccounts] = useState(null);
    const [filteredAccountsLoading, setFilteredAccountsLoading] = useState(false);
    const [csvContent, setCsvContent] = useState(null);
    const [summaryState, setSummaryState] = useState(null);
    const [transactionId, setTransactionId] = useState(null);
    const [notAllocatedNumbers, setNotAllocatedNumbers] = useState([]);
    const [extraLoader, setExtraLoader] = useState(false);
    const [clearTrunk, setClearTrunk] = useState(false);
    const [hashCode, setHashCode] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);

    useLayoutEffect(() => {
        if (!pageId)
            return;
        if (show || showResult) {
            pageIdLogObject.pageMatch = {...match, path: "/number-allocation"};
            pageIdLogObject.withTab = false;
        } else {
            pageIdLogObject.pageMatch = {...match};
            pageIdLogObject.withTab = true;
        }
    }, [show, showResult]);

    useEffect(() => {
        setClearTrunk(false);
    }, []);

    useEffect(() => {
        if (appType === APP_TYPE_ADMIN && !show && !showResult) {
            setTrunkId(null);
        }
    }, [show, showResult]);


    useEffect(() => {
        if (appType === APP_TYPE_ADMIN) {
            // if (!_account_id) { // ATX-2853
                setAccountId(null);
            // }
            if (show) {
                const dialerExtraFilter = {str: accountFilter || ""};
                getDialerTrunk(service, dialerExtraFilter);

                if (!service) {
                    getSuppliers();
                }
            }
        }
    }, [show, accountFilter, service]); 

    useEffect(() => {
        if (dialerTrunkList.length) {
            const firstTrunk = dialerTrunkList[0];
            const currentTrunkIdList = dialerTrunkList.map((trunk) => trunk.trunk_id);

            if (!currentTrunkIdList.includes(trunk_id)) {
                handleChangeDialerTrunk(firstTrunk);
                setTrunkId(firstTrunk.trunk_id);
            }
        }
    }, [dialerTrunkList]);

    useEffect(() => {
        if (_account_id) {
            setAccountId(_account_id);
        }
    }, [_account_id, service]);

    useEffect(() => {
        if (show) {
            setActiveTab("numberForm");
        }
    }, [show])

    useEffect(() => {
        if (show && sp_key === SP_KEY__CONFERENCE_CALLS && ["listForm", "templateListForm", "sequenceForm"].includes(activeTab)) {
            setActiveTab("numberForm");
        }
    }, [activeTab]);

    useEffect(() => {
        if (!clearSelectedData) {
            setClearSelectedData(true);
        }
    }, [activeTrunk]);

    const onSelect = (activeKey) => setActiveTab(activeKey);


    const handleChangeDialerTrunk = (trunk = {}) => {
        trunk.trunk_id && setTrunkId(trunk.trunk_id);
        trunk.sp_key && setSpKey(trunk.sp_key);

        if (trunk.account_id !== account_id) {
            trunk.account_id && setAccountId(trunk.account_id);
        }
        setSpOtpType(trunk.sp_otp_type);
        setSpAuth(trunk.sp_auth);
        setActiveTrunk({...trunk, name: trunk.tr_name});

        // if (trunk.sp_otp_type !== SP_OTP_TYPE) {
        //     setActiveTab("numberForm");
        // }
        if ((trunk.sp_key === SP_KEY__CONFERENCE_CALLS) && ["listForm", "templateListForm", "sequenceForm"].includes(activeTab)) {
            setActiveTab("numberForm");
        }
    };


    const clearChosenTrunkInfo = () => {
        setTrunkId(null);
        setSpKey(1);
        setAccountId(null);
        setSpAuth(null);
        setActiveTrunk(null);
        setClearTrunk(true);
    };

    const getNewNumbers = (service, transactionId) => {
        const method = getServiceSmsPrefix(service, TRUNK_NUMBER_GET_LIST_API);

        getFileResponse(method, {
            target: {
                ...(service ? {trunk_number_transaction_id: transactionId} : {"sms.trunk_number_transaction_id": transactionId})
            }
        })
            .then((response) => {
                if (response) {
                    const csvText = getBlobContent(response);
                    csvText.then((text) => {
                        setCsvContent(text);
                    });
                }
            });
    }


    // const dialerTrunkListFiltered = useMemo(() => (
    //     dialerTrunkList.filter(item => item.sp_key !== SP_KEY__INCORPORATED)
    // ), [dialerTrunkList]);

    return (
        <>
            {show &&

            <ModalSendApi
                title="Allocate numbers"
                successText="Allocate"
                update={update}
                extraLoader={extraLoader}
                extraDisabled={!trunk_id || !dialerTrunkList.length}
                onClose={() => {
                    onClose();
                    setClearTrunk(true);
                    setAccountFilter(null);
                }}
                width={windowWidth < 800 ? windowWidth : 800}
                checkFirstAll
            >

                <>
                    {windowWidth < 800 &&
                        <Tabs
                            activeTab={activeTab}
                            onSelect={onSelect}
                            spKey={sp_key}
                            spAuth={spAuth}
                            isMobile={true}
                            spOtpType={spOtpType}
                        />
                    }
                </>

                <>
                    {isDialerTrunkFiltered &&
                    <AccountsGetFormWrapper>
                        <AccountsGetForm
                            setAccountFilter={setAccountFilter}
                            setLoading={setFilteredAccountsLoading}
                            setFilteredAccounts={setFilteredAccounts}
                        />
                    </AccountsGetFormWrapper>
                    }
                </>

                {windowWidth >= 568
                    ? <DialerTrunk
                        show={show}
                        sp_key={sp_key}
                        service={service}
                        trunkId={trunk_id}
                        clearTrunk={clearTrunk}
                        defaultTrunkId={_trunk_id}
                        trunkFromTable={!!_trunk_id}
                        setTrunkId={setTrunkId}
                        accounts={filteredAccounts}
                        onClear={clearChosenTrunkInfo}
                        onChange={handleChangeDialerTrunk}
                        service_plan_list={service_plan_list}
                        dialerTrunkList={dialerTrunkList}
                        disabledTrunk={disabledTrunk || filteredAccountsLoading}
                    />
                    : <DialerTrunkDropdown
                        show={show}
                        sp_key={sp_key}
                        service={service}
                        trunkId={trunk_id}
                        defaultTrunkId={_trunk_id}
                        trunkFromTable={!!_trunk_id}
                        accounts={filteredAccounts}
                        onClear={clearChosenTrunkInfo}
                        onChange={handleChangeDialerTrunk}
                        service_plan_list={service_plan_list}
                        dialerTrunkList={dialerTrunkList}
                        disabledTrunk={disabledTrunk || filteredAccountsLoading}
                    />
                }

                <>
                    {windowWidth >= 800 &&
                        <Tabs
                            activeTab={activeTab}
                            onSelect={onSelect}
                            spKey={sp_key}
                            spAuth={spAuth}
                            isMobile={false}
                            spOtpType={spOtpType}
                        />
                    }
                </>

                {getForm({
                    activeTab,
                    onSelect,
                    activeTrunk,
                    target: {trunk_id},
                    show,
                    sp_key,
                    service,
                    service_plan_list,
                    spAuth: spAuth,
                    allocateByPrefixesStatus,
                    setAllocateByPrefixesStatus,
                    showResult: () => {
                        setShowResult(true)
                    },
                    closeModal,
                    setSummaryState,
                    setTotalNumbersForced,
                    getTrunkNumberCsvById,
                    setCsvContent,
                    setTransactionId,
                    account_id,
                    defaultAccountId: _account_id, 
                    getNewNumbers,
                    supplierList,
                    setHideBufferButtons,
                    setNotAllocatedNumbers,
                    isMobile: windowWidth < 800,
                    setExtraLoader,

                    clearSelectedData, 
                    setClearSelectedData,
                    setHashCode,
                    setResponseMessage,

                    onClose
                })}

            </ModalSendApi>
            }
            <ModalAllocationResult
                show={showResult}
                service={service}
                accountId={account_id}
                summaryState={summaryState}
                totalNumbersForced={totalNumbersForced}
                trunkId={trunk_id}
                defaultTrunkId={_trunk_id}
                showFullList={activeTab !== "massForm"}
                hideBufferButtons={hideBufferButtons}
                csvContent={csvContent}
                transactionId={transactionId}
                notAllocatedNumbers={notAllocatedNumbers}
                pageId={pageId}
                hashCode={hashCode}
                responseMessage={responseMessage}

                isSms={activeTrunk?.account_id === SMS_ACCOUNT_ID}
                
                allocatedByOTP={spOtpType || spOtpType === 0}
                onClose={() => {
                    setShowResult(false);

                    if (totalNumbersForced)
                        setTotalNumbersForced(null);

                    if (csvContent)
                        setCsvContent(null);

                    if (hashCode)
                        setHashCode(null);

                    if (responseMessage)
                        setResponseMessage(null);
                }}
            />
        </>
    );
};


const getForm = (props) => {
    switch (props.activeTab) {
        case "numberForm":
            return <NumberForm {...props} />;
        case "massForm":
            return <MassForm {...props} />;
        case "listForm":
            return <ListForm {...props} />;
        case "templateListForm":
            return <TemplateListForm {...props} />;
        case "sequenceForm":
            return <SequenceForm {...props} />;
        default:
            return null;
    }
};


const mapState = ({auth, references, suppliers, settings}) => ({
    appType: auth.appType,
    service: auth.service,
    service_plan_list: references.service_plan_list,
    dialerTrunkList: references.dialerTrunkList,
    supplierList: suppliers.items,
    showAllocation: settings.showAllocation
});


export default connect(mapState, {
    getDialerTrunk,
    getSuppliers,
    closeAllocationModal
})(AllocateModal);


const AccountsGetFormWrapper = styled.div`
    margin-bottom: 10px;
`;