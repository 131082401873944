import React, {useState, useMemo, useEffect, useRef} from "react";
import {useIntl} from "react-intl";
import {Alert, Checkbox, ControlLabel, Form, FormControl, FormGroup, Schema} from "rsuite";
import styled, {css} from "styled-components";
import m from "definedMessages";
import {useWindowWidth} from "hooks";
import {SCREEN_MEDIA} from "const";

import TrunksList from "components/TrunksList/TrunksList";
import CustomField from "components/client/Form/CustomField/CustomField";
import {CustomModal} from "components/base";
import {BaseInputNumberField} from "components/base/BaseForm";
import { connect } from "react-redux";


const {md} = SCREEN_MEDIA;
const {StringType, NumberType} = Schema.Types;
const formValueDefault = {trunk_id: null, numbers: 1, random_number: true};

const COUNTER_VALUE = 30;
const TRY_DELAY_VALUE = 20000;


const ModalAllocationCommon = (
    {
        show,
        title,
        trunks,
        trunksNotFilterable=false,
        loading,
        service = true,
        hideRandom = false,
        minNumber = 1,
        maxNumber = null,
        selectedData,
        maxWidth = 600,
        globalSettings,
        rangeName,
        showReasonHandler,
        onClose,
        onSuccess,
        onSend,

        ...props
    }
) => {
    const countDownTimers = useRef({});
    const tryTimers = useRef({});
    const formRef = useRef(null);

    const activeTrunk = useMemo(() => {
        const firstTrunk = trunks.find((trunk) => trunk.sp_key === selectedData.sp_key);
        return firstTrunk;
    }, [trunks, selectedData]);

    const resizedWidth = useWindowWidth();
    const {formatMessage} = useIntl();
    const [formValue, setFormValue] = useState({...formValueDefault});
    const [localLoading, setLocalLoading] = useState(false);
    const [inactiveDirections, setInactiveDirections] = useState({});


    const maxNumberLimit = useMemo(() => {
        const maxTrunkLimitOnce = trunks || !formValue.trunk_id
        ? trunks.find(item => item.id === formValue.trunk_id)?.allocation_limit?.once
        : null;

        if (!maxTrunkLimitOnce) {
            return maxNumber;
        }

        return maxNumber && maxNumber < maxTrunkLimitOnce 
            ? maxNumber 
            : maxTrunkLimitOnce;
    }, [maxNumber, trunks, formValue.trunk_id]);


    const formSchema = useMemo(() => Schema.Model({
        trunk_id: StringType()
            .isRequired(formatMessage(m.thisFieldIsRequired)),
        numbers: NumberType(formatMessage(m.pleaseEnterValidNumber))
            .min(minNumber, formatMessage(m.minNumber, {number: minNumber}))
            .max(maxNumberLimit, formatMessage(m.maxNumber, {number: maxNumberLimit}))
            .isRequired(formatMessage(m.thisFieldIsRequired))
    }), [minNumber, maxNumberLimit]);


    // set allowed numbers count
    useEffect(() => {
        setFormValue(prev => {
            if (!prev.numbers || !maxNumberLimit) {
                return {...prev, numbers: 1}
            }
            if (prev.numbers > maxNumberLimit) {
                return {...prev, numbers: maxNumberLimit}
            }
            return prev;
        });
    }, [maxNumberLimit]);

    useEffect(() => {
        if (!formValue?.trunk_id && activeTrunk) {
            setFormValue((currentValue) => {
                return {
                    ...currentValue,
                    trunk_id: activeTrunk.id
                }
            });
        }
    }, [activeTrunk])

    useEffect(() => {
        return () => {
            for ( const id of Object.keys(countDownTimers.current) ) {
                clearTimeout(countDownTimers.current[id]);
            }
            for ( const id of Object.keys(tryTimers.current) ) {
                clearTimeout(tryTimers.current[id]);
            }
        }
    }, []);

    
    const trunkList = useMemo(() => {
        if (!trunks) {
            return [];
        }

        if (trunksNotFilterable) {
            return trunks;
        }

        const result = trunks.filter(value => (!value || !value?.allocation_limit?.allocate)
            ? false
            : value.sp_key === selectedData?.sp_key && !value.closed && value.active
        );
        return result;
    }, [trunks, selectedData]);

    useEffect(() => {
        if (show && trunkList && trunkList.length === 1) {
            setFormValue(prev => ({
                ...prev, 
                trunk_id: trunkList[0].id
            }))
        }
    }, [trunkList])


    const handleSubmit = async (nextTry = false, fixedFormData = {}) => {
        let formData = {};
        if (nextTry) {
            formData = fixedFormData;
        } else {
            formData = formRef.current.getFormValue();
        }

        setLocalLoading(true);

        const r = await onSend({...formData, ...selectedData}, true);

        if (selectedData.sde_key) {
            if (["too_much_operations", "too_much_operations_sde"].includes(r?.reason_code)) {
                
                const delayVal = globalSettings?.allocation_lock_delay_sec 
                    ? globalSettings.allocation_lock_delay_sec * 1000
                    : TRY_DELAY_VALUE;

                if (!nextTry) {
                    tryTimers.current[selectedData.sde_key] = setTimeout(() => {
                        handleSubmit(true, formData);
                    }, delayVal);
                    return;
                }
                disableDirection(selectedData.sde_key);
            }
        }

        setLocalLoading(false);

        if (!r || (r.code && !r.reason_code)) {
            return;
        }

        if (0 === r?.trunk_number_transaction?.numbers && "allocation:incorporated" === r?.trunk_number_transaction?.method) {
            const errorMessage = formatMessage(m.incorporatedGroupHasNoFreeNumbers);
            Alert.error(errorMessage);
            return;
        }

        if (r.reason_code && (!r.trunk_number_transaction || !r.trunk_number_transaction.numbers)) {
            if (r.reason_code === "not_enough_free_numbers_sequence") {
                setFormValue(prev => ({...prev, random_number: true}));
            }
            const trunk = trunks.filter(trunk => trunk.id === formData.trunk_id);

            showReasonHandler({
                ...r,
                reasonCode: r.reason_code,
                reasonHash: r.hash,
                trunk: trunk[0],
                subdestinationKey: selectedData.sde_key,
                numbersAmount: formValue.numbers,
                rangeName: rangeName
            });
            return;
        }

        onSuccess && onSuccess(r, formData);
        clearForm();
    };


    const disableDirection = (id, counter = globalSettings?.allocation_lock_second_delay_sec || COUNTER_VALUE) => {
        setInactiveDirections(prev => ({
            ...prev,
            [id]: {counter: counter}
        }));

        if (counter < 1) {
            return;
        }

        countDownTimers.current[id] = setTimeout(() => {
            disableDirection(id, counter - 1);
        }, 1000);
    };


    const clearForm = () => {
        setFormValue({...formValueDefault});
    };


    const counterValue = inactiveDirections[selectedData?.sde_key || selectedData?.template]?.counter;

    return (
        <ModalForm
            show={show}
            confirmButtonText={formatMessage(m.getNumbers)}
            title={title || formatMessage(m.getNumbers)}
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={true}
            disabled={!formValue.trunk_id}
            loading={!!loading || !!localLoading || !!counterValue}
            loadingText={!!counterValue ? `${formatMessage(m.getNumbers)} (${counterValue})` : null}
            onConfirm={handleSubmit}
            onClose={() => {
                onClose(false);
                clearForm();
            }}
            {...props}
        >
            <Form
                layout="horizontal"
                ref={formRef}
                model={formSchema}
                formValue={formValue}
                onChange={(currentFormValue) => {
                    setFormValue(currentFormValue);
                }}
            >
                <FormParts>
                    <FormPart>
                        <FormGroup>
                            <StyledTrunkField>

                                <CustomField
                                    accepter={TrunksList}
                                    name="trunk_id"
                                    data={trunkList}
                                    onClean={() => {
                                        setFormValue((currentFormValue) => {
                                            return {
                                                ...currentFormValue,
                                                trunk_id: null
                                            }
                                        });
                                    }}
                                />

                            </StyledTrunkField>
                        </FormGroup>

                    </FormPart>
                    <FormPart>
                        <FormGroup>
                            <FlexBox>

                                <FormLabel>{formatMessage(m.numbers)}</FormLabel>

                                <FormFieldWrapper width="80px">

                                    <FormControl
                                        accepter={BaseInputNumberField}
                                        max={maxNumberLimit}
                                        min={minNumber}
                                        name="numbers"
                                        disabled={!formValue.trunk_id}
                                    />

                                </FormFieldWrapper>

                            </FlexBox>
                        </FormGroup>
                    </FormPart>
                    {!hideRandom && <FormPart>

                        <FormControl
                            accepter={CustomCheckbox}
                            name="random_number"
                        >
                            {formatMessage(m.randomOrder)}
                        </FormControl>

                    </FormPart>}
                </FormParts>
            </Form>
        </ModalForm>
    );
};


const CustomCheckbox = (
    {
        children, 
        defaultValue = false, 
        onChange,
        value,
        ...props
    }
) => {

    const [_value, _setValue] = useState(defaultValue);

    
    useEffect(() => {
        _setValue(value);
    }, [value]);


    return <Checkbox
        value={_value}
        checked={_value}
        onChange={() => {
            _setValue(!_value);
            onChange(!_value);
        }}
        {...props}
    >
        {children}
    </Checkbox>
};


const mapStateToProps = ({references}) => ({
    globalSettings: references.global_setting,
});

export default connect(mapStateToProps, {})(ModalAllocationCommon);


const FlexBox = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;

const FormParts = styled.div`
    display: block;
    margin-bottom: -20px;
    @media (min-width: ${md.min}px) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

const FormPart = styled.div`
    padding-bottom: 20px;

    @media (min-width: ${md.min}px) {
        padding-right: 10px;

        &:last-child {
            padding-right: 0;
        }
    }
`;

const FormLabel = styled(ControlLabel)`
    &&&& {
        float: none;
        margin: 0 0 10px 0;
        padding: 0;
        text-align: left;
        word-wrap: none;

        @media (min-width: ${md.min}px) {
            margin: 0 10px 0 0;
            max-width: 130px;
            width: auto;
        }
    }
`;

const FormFieldWrapper = styled.div`
    width: 100%;

    ${props => props.width && css`
        @media (min-width: ${md.min}px) {
            width: ${props.width}
        }
    `
}
`;

const StyledTrunkField = styled.div`
    width: 100%;

    @media (min-width: ${md.min}px) {
        width: 200px;
    }
`;

export const ModalForm = styled(CustomModal)`
    .rs-modal-content {
        display: flex;
        flex-direction: column;
    }

    .rs-modal-footer {
        margin-top: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
`;