import {
    ALLOCATIONS as PREFIX,
    DEFAULT_PER_PAGE_MEDIUM
} from "const";

const initialState = {
    items: [],
    // count: 0,
    // page: 1,
    // per_page: DEFAULT_PER_PAGE_MEDIUM,
    loading: false

};

const handlers = {
    ["SET_ITEMS_" + PREFIX]: (state, {payload: {list, count, page, per_page}}) => ({
        ...state,
        items: list,
        // count,
        // page,
        // per_page
    }),
    ["SET_LOADING_" + PREFIX]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
}