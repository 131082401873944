import React, {useState, useEffect, useRef} from "react";
import {parse} from "object-flaser";
import {connect} from "react-redux";
// import Recaptcha from "react-google-invisible-recaptcha";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {Form, Schema, Divider, Alert, Checkbox, CheckboxGroup} from "rsuite";

import {useWindowWidth} from "hooks";
import {registerCreate} from "actions/registration";
import {getAllReferences} from "actions/references";

import {CustomModal} from "components/base";

import * as S from "./styled";
import fields, {selectableFields, registrationFormFields} from "./RegistrationFormFields";
import {FieldInput, FieldNumber, FieldSelect, FieldSelectComment} from "./RegistrationField";


const {StringType, NumberType, ArrayType} = Schema.Types;

const formModel = Schema.Model({
    name: StringType()
        .isRequired( "This field is required")
        .maxLength(256, "Max length exceeded"),
    teams_email: StringType()
        .maxLength(256, "The maximum is only 256 characters.")
        .pattern(/^([a-zA-Z0-9_+.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/, "Incorrect email address"),
    teams_link: StringType()
        .maxLength(256, "The maximum is only 256 characters.")
        .pattern(/^(https?:\/\/)([a-z0-9-]+\.)+[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/i, "Incorrect teams link"),
    skype: StringType()
        .maxLength(256, "Max length exceeded"),
    email: StringType()
        .isRequired( "This field is required")
        .maxLength(1024, "Max length exceeded")
        .isEmail(),
    cntr_code: StringType()
        .isRequired( "This field is required"),
    month_traffic_minute: NumberType()
        .min(1, "Min amount exceeded")
        .max(99999, "Max amount exceeded"),
    cur_key: NumberType(),
    traffic_type: NumberType(),
    service: ArrayType()
        .minLength(1, "Please select at least 1 service")
        .isRequired("This field is required"),
});


const RegistrationModal = ( (
    {
        show = false,
        onClose,
        maxWidth = 1000,
        defaultFormValue = null,
        countryList,
        currencyList,
        registerCreate,
        getAllReferences,
        howFindList,
        globalSetting
    }
) => {

    const formRef = useRef(null);
    const formData = useRef(null);
    // const recaptchaRef = useRef(null);
    const resizedWidth = useWindowWidth();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState(defaultFormValue ? defaultFormValue : {});
    const [verifyToken, setVerifyToken] = useState(null);
    const [trafficState, setTrafficState] = useState(0);
    const [registeredBody, setRegisteredBody] = useState(null);

    const [checkboxState, setCheckboxState] = useState([]);

    const [recapchaIsVisible, setRecapchaIsVisible] = useState(() => {
        return false;
    });

    useEffect(() => {
        getAllReferences();
    }, []);


    useEffect(() => {
        if (defaultFormValue) {
            setFormValue({...defaultFormValue});
        }
    }, [defaultFormValue]);


    useEffect(() => {
        setLoading(false);
    }, [registeredBody])

    useEffect(() => {
        if (globalSetting) {
            setRecapchaIsVisible(globalSetting.captcha_check_at_login);
        }
    }, [globalSetting])

    const renderFields = (fieldNames = [], formValue) => (

        fieldNames.map((fieldName) => {
            const {type, name, label, labelId, hideOn, ...props} = fields[fieldName];

            // hide on option
            if (hideOn && Array.isArray(hideOn)) {
                for (const conditionItem of hideOn) {
                    const keys = Object.keys(conditionItem || {});
                    const status = keys.map(key => {
                        return formValue[key] && formValue[key] === conditionItem[key];
                    });

                    if (!status.includes(false))
                        return null;
                }
            }

            // get label
            switch (type) {
                case "select_cntr_code":
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        data={countryList}
                        labelKey="name"
                        valueKey="cntr_code"
                    />;
                case "select_cur_key":
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={currencyList}
                        labelKey="name"
                        valueKey="cur_key"
                        {...props}
                    />;
                case "hf_key_options":
                    return <FieldSelectComment
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={howFindList}
                        labelKey="how_find_name"
                        valueKey="hf_key"
                        {...props}
                    />;
                case "select_traffic_type":
                    return <FieldSelect
                        key={name}
                        name={name}
                        label={label}
                        searchable={false}
                        data={selectableFields[type]}
                        labelKey="name"
                        valueKey="id"
                        {...props}
                    />;
                case "integer":
                    return (<S.GroupNumber>
                        <FieldNumber
                            key={name}
                            name={name}
                            label={label}
                            onChange={(value) => {setTrafficState(parseInt(value))}}
                            {...props}
                        />
                    </S.GroupNumber>);
                default:
                    return <FieldInput key={name} name={name} label={label} {...props} />
            }
        })
    );


    const handleFormSubmit = async () => {
        const formValues = formRef.current.getFormValue();

        if (formValues && Object.keys(formValues).length) {

            const fieldsValidity = [];
            for (const fieldName of registrationFormFields) {
                fieldsValidity.push(formRef.current.checkForField(fieldName));
            }
            if (fieldsValidity.includes(false)) {
                return;
            }

            setLoading(true);

            const formValue = {...formValues, month_traffic_minute: trafficState || 0};
            formData.current = formValue;
            setRegisteredBody(formValue);

            if (!recapchaIsVisible || !executeRecaptcha) {
                handleResolveCaptcha();
                return;
            }

            try {
                const token = await executeRecaptcha("register");
                handleResolveCaptcha(token);
            } catch (e) {
                Alert.error("Bot verification failed");
            }
        }
    };

    const handleResolveCaptcha = (token) => {
        if (!token && recapchaIsVisible) {
            Alert.error("Bot verification failed")
            return;
        }

        const serviceList = formData.current.service || [];

        const {teams_email, teams_link, ...restFormData} = formData.current;
        const params = {
            ...restFormData,
            ...(teams_email ? {teams_email: formData.current.teams_email} : {}),
            ...(teams_link ? {teams_link: formData.current.teams_link} : {}),
            // service:
            voice: serviceList.includes("voice"),
            sms: serviceList.includes("sms")
        } || {};

        if (params.service) {
            delete params.service;
        }

        registerCreate(parse({...params, captcha_token: token})).then((response) => {
            if (response) {

                if (response.code && response.code === 1) {
                    Alert.error("Bot verification failed")
                } else if (response.code && response.code > 1) {
                    Alert.error("No permissions");
                } else if ( response.reg_key) {
                    Alert.success("Registration request has been sent")
                }

                cleanErrors();
                clearForm();
                onClose(false);
            }
        })
    };


    const clearForm = () => {
        setFormValue({});
        setCheckboxState([]);
    };


    const cleanErrors = () => {
        if (formRef?.current) {
            formRef.current.cleanErrors();
        }
    };


    return ( <CustomModal
            {...{show, onClose}}
            title="Registration form"
            width={resizedWidth > maxWidth ? maxWidth : resizedWidth}
            showFooter={false}
            confirmButtonText="Success"
            loading={loading}
            keyboard={false}
            onClose={() => {
                cleanErrors()
                clearForm();
                onClose(false);
            }}
        >
            <S.FormModalWrapper>
                <Form
                    ref={formRef}
                    model={formModel}
                    formValue={formValue}
                    onChange={setFormValue}
                >

                    <S.FormColumns>
                        <>
                            <S.FormColumn colWidth="50%">
                                {renderFields(registrationFormFields, formValue)}
                            </S.FormColumn>
                            {/* <Divider style={{width: 0}} vertical /> */}
                        </>
                    </S.FormColumns>

                       <S.FormColumns>
                        <>
                            <S.FormColumn colWidth="50%">
                                <FieldInput
                                    name="service"
                                    accepter={CheckboxGroup}
                                    label="Service"
                                    // defaultValue={defaultFilterValue?.checkbox}
                                    value={checkboxState}
                                    onChange={(value) => {
                                        setCheckboxState(value)
                                    }}
                                >
                                    <Checkbox
                                        key="voice"
                                        value="voice"
                                        disabled={loading}
                                    >
                                        Voice
                                    </Checkbox>
                                    <Checkbox
                                        key="sms"
                                        value="sms"
                                        disabled={loading}
                                    >
                                        SMS
                                    </Checkbox>
                                </FieldInput>
                            </S.FormColumn>
                            <Divider style={{width: 0}} vertical />
                        </>
                    </S.FormColumns>

                    <S.RegistrationButtonWrapper>
                        <S.RegistrationSubmitButton 
                            onClick={handleFormSubmit} 
                            appearance="primary" 
                            type="submit"
                        >
                            Submit
                        </S.RegistrationSubmitButton>
                        
                        {resizedWidth < 1200 
                            ? <S.RegistrationCancelButton 
                                onClick={() => {onClose()}}
                            >
                                Close
                            </S.RegistrationCancelButton> 
                            : null
                        }
                    </S.RegistrationButtonWrapper>
                </Form>
            </S.FormModalWrapper>
        </CustomModal>
    )
} );

const mapState = ({references}) => ({
    countryList: references.country_list,
    currencyList: references.currency_list,
    howFindList: references.how_find_list,
    globalSetting: references.global_setting
});

export default connect(mapState, {
    registerCreate,
    getAllReferences
})(RegistrationModal);