import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";

import {Alert, Dropdown, List as RsuiteList, Icon, Modal} from "rsuite";
import {useWindowWidth} from "hooks";
import {EOL} from "const";
import {CustomModal} from "../../base";
import {
    copyToClipboard, makeNumbersFromPrefixesToCsv,
    randomizeArray, getRangeByNumberDigit, getServiceSmsPrefix,
} from "utils/helpers";

import "./ModalAllocationResult.css";
import * as S from "./styled";
import ResultNotAllocatedList from "./ListNotAllocatedNumbers";
import {
    downloadCompletedListAsPrefixes,
    downloadCompletedList,
    downloadNewNumbers,
    downloadNewNumbersAsPrefixes,
    downloadNewNumbersSimpleList,
    downloadTrunkNumbersCsv,
} from "utils";


const ModalAllocationResult = (
    {
        show,
        trunkId,
        accountId,
        service,
        csvContent,
        hashCode = null,
        responseMessage = null,
        isSms = false,
        showFullList,
        transactionId,
        defaultTrunkId,
        hideBufferButtons,
        notAllocatedNumbers = [],
        totalNumbersForced,
        allocatedByOTP,

        onClose,
    }
) => {

    const windowWidth = useWindowWidth();
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [accountIdState, setAccountIdState] = useState(accountId); 

    const currentTrunkId = useMemo(() => {
        return trunkId || defaultTrunkId;
    }, [trunkId, defaultTrunkId]);

    const onCopy = () => {
        const csvData = makeNumbersFromPrefixesToCsv(csvContent);
        const csvDataList = csvData && csvData.length ? csvData.split("\n") : [];
        
        if (csvDataList.length) {
            const test = csvDataList && csvDataList.length && csvDataList.slice(1)
                .map( line => {
                    const item = line.split(";");
                    return line ? `${item[0]}, ${item.slice(2).join(", ")}` : ""
                })
                .join(EOL)

            copyToClipboard(test);

            Alert.success(`Copied!`);
        } else {
            Alert.warning(`Something wrong with numbers`);
        }
    };


    const onCopySimple = () => {

        copyToClipboard(csvContent.split("\n").slice(1).join(EOL));

        Alert.success(`Copied!`);
    };


    const onCopyOnlyNumbers = () => {
        const csvData = makeNumbersFromPrefixesToCsv(csvContent);
        const csvDataList = csvData && csvData.length ? csvData.split("\n") : [];

        if (csvDataList.length) {
            const onlyNumbersData = csvDataList && csvDataList.length && csvDataList.slice(1)
                .map( line => {
                    const item = line.split(";");
                    return item.length && item[0];
                });

            copyToClipboard( onlyNumbersData.join(EOL) );
            Alert.success(`Copied!`);
        } else {
            Alert.warning(`Something wrong with numbers`);
        }
    };


    const onCopyByPrefix = () => {
        copyToClipboard(csvData && csvData.length && csvData.slice(1)
            .map( line => {
                const item = line.split(";");
                return `${getRangeByNumberDigit(item[0], item[1])}, ${item.slice(2).join(", ")}`
            })
            .join(EOL)
        );

        Alert.success(`Copied!`);
    };


    const handleDownloadCompletedListSimple = () => {
        setLoading(true);
        const params = {target: (service ? {trunk_id: currentTrunkId} : {"sms.trunk_id": currentTrunkId})};
        downloadTrunkNumbersCsv(params, service, () => setLoading(false), "allocated_numbers.csv", true);
    }

    const handleDownloadNewNumbersSimple = () => {
        setLoading(true);
        downloadTrunkNumbersCsv({target: {[getServiceSmsPrefix(service, "trunk_number_transaction_id")]: transactionId}}, service, () => setLoading(false), "numbers.csv", true);
    }


    const handleDownloadCompletedList = () => {
        setLoading(true);
        downloadCompletedList(currentTrunkId, service, () => setLoading(false));
    }

    const handleDownloadCompletedListAsPrefixes = () => {
        setLoading(true);
        downloadCompletedListAsPrefixes(currentTrunkId, service, () => setLoading(false));
    }

    const handleDownloadNewNumbers = () => {
        setLoading(true);
        downloadNewNumbers(transactionId, service, () => setLoading(false));
    }

    const handleDownloadNewNumbersAsPrefixes = () => {
        setLoading(true);
        downloadNewNumbersAsPrefixes(transactionId, service, () => setLoading(false));
    }

    const handleDownloadNewNumbersSimpleList= () => {
        setLoading(true);
        downloadNewNumbersSimpleList(transactionId, service, () => setLoading(false));
    }

    const csvData = csvContent 
        ? csvContent.split("\n").filter(value => value)
        : [];

    const numberCsvListItems = csvData
        .slice(1)
        .map( (line, index) => {
            const items = line.split(";");
            return <RsuiteList.Item key={index}>
                {!isSms
                    ? <>{getRangeByNumberDigit(items[0], items[1])}, {items.slice(2).join(", ")}</>
                    : <>{line}</>
                }
            </RsuiteList.Item>
        }
    );

    const isMobile = windowWidth < 820;

    return (
            <CustomModal
                as={Modal}
                show={show}
                style={{
                    width: windowWidth < 820 ? windowWidth : 820
                }}
                onHide={onClose}
            >

                <Modal.Header>New Allocated numbers</Modal.Header>

                <Modal.Body className="modal-body">
                    {service && <div className="allocation_result_warning-message">
                        <Icon icon="exclamation-triangle"/>&nbsp;
                        Pay your attention that allocated numbers will be activated during 10 minutes after allocation. Warn dialer about it.
                    </div>}

                    {notAllocatedNumbers && !!notAllocatedNumbers.length &&
                        <ResultNotAllocatedList
                            count={notAllocatedNumbers.length}
                            numbers={notAllocatedNumbers}
                        />
                    }

                    <div className="text-center">
                        <strong>{totalNumbersForced || 0}</strong> numbers were allocated{hashCode ? ` (Operation code: ${hashCode})` : ""}
                    </div>

                    {/* <div>
                        Message: {responseMessage}
                    </div> */}

                    {showFullList &&
                        <S.List
                            size="sm"
                            style={{height: csvData.length * 36, maxHeight: 288}}
                        >
                            {numberCsvListItems}
                        </S.List>
                    }
                </Modal.Body>

                <Modal.Footer className="modal-footer">
                { totalNumbersForced

                    ? <>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>
                            {!hideBufferButtons &&
                                <S.Dropdown
                                    placement={isMobile ? "topStart" : "bottomStart"}
                                    title={<>{loading && <S.Loader/>} Copy List</>}
                                    disabled={loading}
                                >
                                    {!isSms &&
                                        <Dropdown.Item onClick={onCopyOnlyNumbers}>Copy just numbers</Dropdown.Item>
                                    }

                                    {/* {allocatedByPrefix && !isSms &&
                                        <Dropdown.Item onClick={onCopyByPrefix}>Copy list allocated by prefix</Dropdown.Item>
                                    } */}

                                    <Dropdown.Item onClick={!isSms ? onCopy : onCopySimple}>Copy List</Dropdown.Item>

                                </S.Dropdown>
                            }
                        </S.ButtonToolbar>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>

                            <S.Dropdown
                                placement={isMobile ? "topStart" : "bottomStart"}
                                title={<>{(loading || downloadLoading) && <S.Loader/>} Download List</>}
                                disabled={loading}
                            >

                                <Dropdown.Item
                                    onClick={!isSms ? handleDownloadCompletedList : handleDownloadCompletedListSimple}
                                >
                                    Download complete list allocated numbers as csv
                                </Dropdown.Item>

                                {allocatedByOTP && !isSms &&
                                    <Dropdown.Item
                                        onClick={handleDownloadCompletedListAsPrefixes}
                                    >
                                        Download complete list of allocated numbers as ranges
                                    </Dropdown.Item>
                                }

                                <Dropdown.Item
                                    onClick={!isSms ? handleDownloadNewNumbers : handleDownloadNewNumbersSimple}
                                >
                                    Download new allocated numbers
                                </Dropdown.Item>

                                {/* {allocatedByPrefix && !isSms &&
                                    <Dropdown.Item
                                        onClick={handleDownloadNewNumbersAsPrefixes}
                                    >
                                        Download new allocated numbers as ranges
                                    </Dropdown.Item>
                                } */}

                                {!isSms &&
                                    <Dropdown.Item
                                        onClick={handleDownloadNewNumbersSimpleList}
                                    >
                                        Download new allocated numbers (only numbers; txt version)
                                    </Dropdown.Item>
                                }
                            </S.Dropdown>

                        </S.ButtonToolbar>

                        <S.ButtonToolbar width={windowWidth < 576 ? "100%" : "30%"}>
                            <S.CancelButton
                                width="100%"
                                onClick={onClose}
                            >
                                Close
                            </S.CancelButton>
                        </S.ButtonToolbar>
                    </>

                    : <></>
                }
                </Modal.Footer>
            </CustomModal>
    );
};

const mapStateToProps = ({auth}) => ({
    authInfo: auth.authInfo,
});

export default connect(mapStateToProps, {})(ModalAllocationResult);