
import {ALLOCATIONS as PREFIX, transformed_service_reverse_data} from "const";
import {api} from "api/loginRoutes";
import {_setValue} from "./defaults";

const setAllocationResultList = _setValue("SET_ITEMS_" + PREFIX);
const setLoading = _setValue("SET_LOADING_" + PREFIX);

export const getAllocationResultList = (service, hash) => (dispatch) => {
    const params = {
        // target: {account_id},
        // hash
        service: transformed_service_reverse_data[service]
    };
    
    api("account:get_allocation_result_list", params)
        .then((response) => {
            if (response !== undefined) {
                dispatch(setAllocationResultList({
                    list: response["allocation_result_list"] || []
                }))
            }
        })
};

export const loadingData = (load) => (dispatch) => {
    dispatch(setLoading(load));
};

