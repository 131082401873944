import React, { useRef } from "react";
import TableServerSort from "components/Table/TableServerSort";
import styled from "styled-components";
import { DEFAULT_PER_PAGE_MAX } from "const";
import m from "definedMessages";


const AllocationsTable = ({
    data,
    count,
    page,
    perPage,
    loading,

    setSort,
    getItems,
    
    formatMessage
}) => {
    const isMobile = false;

    const tableRef = useRef(null);

    const columns = [
        {
            label: "Datetime",
            dataKey: "datetime",
            formatData: "datetime",
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: "Method",
            dataKey: "method", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: "User",
            dataKey: "user",
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: "Status",
            dataKey: "message", 
            sortable: false, 
            flexGrow: 1,
            minWidth: 196,
            minWidthMobile: 300
        },
        {
            label: "Allocated numbers",
            value: ({trunk_number_transaction, params}) => {
                if (trunk_number_transaction) {
                    const {numbers} = trunk_number_transaction;
                    return numbers;
                }
                
                const {numbers: paramsNumbers} = params;

                return paramsNumbers || "";
            },
            sortable: false, 
            align: "right",
            minWidth: 140,
            minWidthMobile: 140
        }
    ];

    const headerHeight = 40;
    const rowHeight = isMobile ? 42 : 30;
    // const extraHeight = (data.length && data.length < 15) ? (data.length * rowHeight) + headerHeight : 490;

    return <StyledTable
        shouldUpdateScroll={false}
        ref={tableRef}
        data={data}
        // height="50%"
        loading={loading}
        columns={columns}
        originalColumns={columns}
        // count={count}
        // per_page={perPage}
        // perPageMaximum={DEFAULT_PER_PAGE_MAX}
        // page={page}

        extraHeight={490}
        rowHeight={rowHeight}

        row_key="al_key"

        // showedCount={count}
        // ispagination

        onSort={(column, type) => setSort({column, type})}
        getItems={getItems}
    />
};

export default AllocationsTable;


const StyledTable = styled(TableServerSort)`
    && {
        margin-bottom: 20px;
    }
`;