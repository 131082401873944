import React, {useRef, useMemo, useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {toStartDayUTCTime} from 'utils';
import {SCREEN_MEDIA} from  '../../../../const';
import {Spacer} from "../../../../components/base/Spacer";
import {
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD,
    SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_NEW_METHOD
} from '../../../../const/apiMethods';
import {api, apiFormData} from "api/loginRoutes";
import {
    Form, Radio, FormControl, RadioGroup, Alert, Schema, SelectPicker, ControlLabel,
    FlexboxGrid, InputNumber, Button, Uploader, PanelGroup, Panel
} from "rsuite";
import CustomField from '../../../../components/Form/CustomField';
import useWindowWidth from "../../../../hooks/useWindowWidth";

import {FlexGrid, FlexGridItem} from "../../../../components/base/FlexGrid";

import {CustomModal} from "../../../../components/base/BaseModal";
import BillingIncrement from "components/Filters/BillingIncrement";
import { CustomCheckbox } from "components/Form/CustomCheckbox";

const {StringType, NumberType} = Schema.Types;


const FILES_DEFAULT = [];
const MIN_CHARACTERS = 7;
const MAX_CHARACTERS = 15;
const MAX_ROWS = 10000;


const numberOptions = [
    {value: 'add', label: 'Add new numbers'},
    {value: 'replace', label: 'Replace group numbers'},
];

const testNumberOptions = [
    {value: 'none', label: 'Do not set test number'},
    {value: 'first', label: 'Set first number from list as test'},
    {value: 'random', label: 'Set random number from list as test'},
];


export default ({
    show,
    trunks,
    service,
    currentTrunkId,
    currentSupplier,
    selectedItems,
    showReasonHandler,

    isUploadToGroup,

    supplierList,
    onSuccess,
    onClose,
    ...props
}) => {

    const resizedWidth = useWindowWidth();

    const defaultFormValue = isUploadToGroup 
        ? {
            name: selectedItems?.name,
            rate: selectedItems?.rate
        }
        : {};

    const formRef = useRef();
    const [files, setFiles] = useState(FILES_DEFAULT);
    const [loading, setLoading] = useState(false);
    const [formValue, setFormValue] = useState(defaultFormValue);

    const [isMultipleUpload, setIsMultipleUpload] = useState(false);

    useEffect(() => {
        if (files && files.length) {
            formRef.current.cleanErrorForFiled("text");
            setFormValue(prev => ({...prev, text: ""}));
            return;
        }

    }, [files]);
    
    const currencyName = useMemo(() => {
        if (currentSupplier) {
            return currentSupplier?.currency_name;
        }

        return "USD";
    }, [currentSupplier]);

    useEffect(() => {
        setFiles([]);
        setFormValue(prev => ({
            ...prev,
            test_number_option: prev.test_number_option,
            supplier_id: prev.supplier_id,
        }))
    }, [isMultipleUpload]);

    const formModel = useMemo(() => {

        if (isMultipleUpload) {
            return Schema.Model({

                test_number_option: StringType()
                    .addRule((value, data) => {
                        if (!value && data.existing_group_action === "replace") {
                            return false;
                        }
                        return value
                    }, 'This field is required', true),
            });
        }

        return Schema.Model({

            name: StringType()
                .isRequired('This field is reqiured'),

            rate: NumberType()
                .min(0.0001, 'Minimum 0.0001')
                .max(10, 'Maximum 10')
                .isRequired('This field is reqiured'),
            
            test_number_option: StringType()
                // .addRule((value, data) => {
                //     if (!value && data?.currentSupplier?.supplier_id) {
                //         return false;
                //     }
                //     return value
                // }, 'This field is required', true),
                .isRequired('This field is reqiured'),
            
            ...(isUploadToGroup ? {
                    existing_group_action:  StringType()
                        .isRequired('This field is reqiured')
                } : {}),
            
            text: StringType()
                .addRule((value) => {
                    if (!value && (!files || !files.length)) {
                        return false;
                    }
                    return true;
                }, 'This field is required', true)

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    for (const row of rows) {
                        if (row.length && row.includes('-')) {
                            const range = row.split('-');
                            if (range.length <= 2) {
                                const rangeHasError = range.find(value => value.trim().length > MAX_CHARACTERS || value.trim().length < MIN_CHARACTERS);
                                if (rangeHasError) {
                                    return false
                                }
                            } else {
                                return false
                            }
                        } else {
                            if ( row.length && (row.trim().length > MAX_CHARACTERS || row.trim().length < MIN_CHARACTERS) ) {
                                return false;
                            }
                        }
                    }
                    return true;
                }, `Each row should be greater than ${MIN_CHARACTERS} and less than ${MAX_CHARACTERS} (range must contain 2 numbers)`)

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    for (const row of rows) {
                        const rowTrimmed = row.trim();
                        if (!rowTrimmed) {
                            continue;
                        }
                        if (rowTrimmed.includes('-')) {
                            const numbers = rowTrimmed.split('-').map(e => e.trim());
                            if (numbers.length > 2) {
                                return false;
                            }
                            for (const number of numbers) {
                                if (!number.match(/^\d{1,}$/)) {
                                    return false;
                                }
                            }
                            continue;
                        }
                        if (!rowTrimmed.match(/^\d{1,}$/)) {
                            return false;
                        }
                    }
                    // const match = row.match(/^\d{1,}$/);
                    return true;
                }, 'Please enter valid numbers')

                .addRule((value) => {
                    const rows = value.trim().split('\n');
                    return !(rows.length > MAX_ROWS);
                }, `The number of entered telephone numbers exceeds ${MAX_ROWS} rows`)
        });
    }, [isMultipleUpload, files]);

    // submit
    const handleSubmit = () => {
        if (!formRef.current.check()) {
            return;
        }

        let params = {
            target: {

                ...(isUploadToGroup 
                    ? {"sms.supplier_gan_group_id": selectedItems?.id} 
                    : {"sms.supplier_trunk_id": currentTrunkId}
                ),
            },
            supplier_id: currentSupplier?.supplier_id,
        };

        const moveFromOtherGroupsObj = !service && formValue?.move_from_other_groups ? {
            move_from_other_groups: formValue.move_from_other_groups
        } : {};

        if (isMultipleUpload) {
            if (!files || !files.length) {
                Alert.error("Please upload the CSV file");
                return;
            }

            const dt = new DataTransfer();
            dt.items.add(files[0].blobFile);

            params = {
                ...params,

                test_number_option: formValue.test_number_option,
                upload_file_list: dt.files,
                files: dt.files,
                ...moveFromOtherGroupsObj
            }

        } else {
            params = {
                ...params,
                ...formValue,
                rate: formValue.rate
                    ? +formValue.rate
                    : formValue.rate,
                ...moveFromOtherGroupsObj
            };

            if (files && files.length) {
                const dt = new DataTransfer();
                dt.items.add(files[0].blobFile);
                // params.upload_file_list = dt.files;
                params.files = dt.files;
                delete params.text;
            }
        }
        const method = isUploadToGroup ? SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_EXISTS_METHOD 
        : isMultipleUpload
            ? SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD
            : SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_NEW_METHOD;

        const methodFunc = params.files || method === SMS_SUPPLIER_GAN_GROUP_UPLOAD_CSV_MULTY_METHOD
            ? apiFormData
            : api;

        setLoading(true);

        methodFunc(method, params)
            .then(r => {
                if (!r) {
                    return;
                }

                if (r.validation_error) {
                    Alert.error(<>
                        <p><b>Error</b>: {r.validation_error}</p>
                        {r.line_number &&
                            <p>
                                {params.files && `${params.files[0].name}: `}
                                line <b>{r.line_number}</b><br/>
                                {r.line_str}
                            </p>
                        }
                    </>, 10000);
                    return;
                }

                if (r.reason_code) {
                    switch (r.reason_code) {
                        case "different_group":
                            showReasonHandler({
                                ...r,
                                status: 1,
                                reasonCode: r.reason_code,
                                reasonHash: r.hash,
                                content: (
                                    <PanelGroup accordion bordered style={{marginRight: 20}}>
                                        {r.incorporated_group_list.map(item => (
                                            <Panel header={item.name} collapsible bordered>
                                                <p>{item.number_list.join(", ")}</p>
                                            </Panel>
                                        ))}
                                    </PanelGroup>
                                )
                            });
                            break;
                        default:
                            showReasonHandler({
                                ...r,
                                status: 1,
                                reasonCode: r.reason_code,
                                reasonHash: r.hash,
                            })
                    }
                }

                if (r?.uploaded_numbers) {
                    Alert.success(`${r.uploaded_numbers} numbers were uploaded`);
                } else if (r?.not_uploaded_numbers) {
                    Alert.error(`${r.not_uploaded_numbers} numbers were not uploaded`);
                }

                setFiles([]);
                onSuccess(r, method === apiFormData);
                onClose();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClose = () => {
        setFiles([]);
        onClose && onClose();
    };

    return (
        <StyledCustomModal
            show={show}
            title={'Gan numbers upload'}
            width={560}
            loading={loading}
            onConfirm={handleSubmit}
            onClose={handleClose}
            showFooter
            {...props}
        >
            <Form
                ref={formRef}
                model={formModel}
                formValue={formValue}
                onChange={setFormValue}
            >
                <FlexboxGrid>
                    {!isUploadToGroup && <FlexboxGrid.Item>
                        <CustomField
                            label="Supplier"
                            accepter={SelectPicker}
                            data={supplierList}
                            name={'supplier_id'}
                            valueKey={'supplier_id'}
                            labelKey={'name'}
                            placeholder={'Supplier'}
                            value={currentSupplier?.supplier_id}
                            disabled={true}
                            style={{width: 224}}
                        />
                    </FlexboxGrid.Item>}
                    {!isUploadToGroup && <FlexboxGrid.Item className={'ml-4'}>
                        <CustomField
                            label="Trunk"
                            accepter={SelectPicker}
                            data={trunks}
                            name={'trunk_id'}
                            valueKey={'id'}
                            labelKey={'name'}
                            placeholder={'Trunk'}
                            value={currentTrunkId}
                            disabled={true}
                            style={{width: 224}}
                        />
                    </FlexboxGrid.Item>}
                </FlexboxGrid>

                {!service ? <CustomCheckboxWithSpaces
                    name="move_from_other_groups"
                    defaultValue={formValue.move_from_other_groups}
                    onChange={(check) => {
                        setFormValue((value) => {
                            return {
                                ...value,
                                move_from_other_groups: check
                            }
                        });
                    }}
                >
                    Move from other groups
                </CustomCheckboxWithSpaces> : <Spacer/>}
                            
                {!isUploadToGroup && <>
                    <div>
                        <CustomField
                            value={isMultipleUpload}
                            accepter={RadioGroup}
                            inline={resizedWidth > 767}
                            onChange={setIsMultipleUpload}
                        >
                            <Radio value={false}>Single group uploading</Radio>
                            <Radio value={true}>Multiple group uploading</Radio>
                        </CustomField>
                    </div>
                    <hr style={{marginBottom: 30}}/>
                </>}
                

               {(!isMultipleUpload)
                    ? <>
                        <FlexGrid align="middle">

                            <FlexGridItem>
                                <FormControl
                                    name="name"
                                    placeholder={'Group name'}
                                    style={{width: 224}}
                                    disabled={isUploadToGroup}
                                />
                            </FlexGridItem>

                            <FlexGridItem>
                                <FlexGrid>
                                    <FlexGridItem>
                                        <FormControl
                                            accepter={InputNumber}
                                            name={'rate'}
                                            min={0.0001}
                                            placeholder={'Payout'}
                                            style={{width: 90}}
                                            disabled={isUploadToGroup}
                                        />
                                    </FlexGridItem>
                                    <FlexGridItem style={{alignSelf: 'center'}}>
                                        {currencyName}
                                    </FlexGridItem>
                                </FlexGrid>
                            </FlexGridItem>
                        </FlexGrid>

                        <Spacer size={30}/>

                        <FlexGrid noWrap>
                            <FlexGridItem colspan={12}>
                                <FormControl
                                    name={'text'}
                                    placeholder={'Number list'}
                                    componentClass="textarea"
                                    onChange={() => setFiles(FILES_DEFAULT)}
                                    style={{height: 200}}
                                />
                            </FlexGridItem>
                            <FlexGridItem colspan={12}>
                                <ControlLabel>or </ControlLabel>
                                <FormControl
                                    disabled={files.length > 0}
                                    accepter={StyledUploader}
                                    fileList={files}
                                    autoUpload={false}
                                    multiple={false}
                                    removable={true}
                                    accept="text/plain"
                                    name="upload_file_list"
                                    onChange={(files) => {
                                        setFiles(files);
                                    }}
                                >
                                    <Button>Upload numbers</Button>
                                </FormControl>
                            </FlexGridItem>
                        </FlexGrid>
                    </>
                    : <>
                        <FormControl
                            disabled={files.length > 0}
                            accepter={StyledUploader}
                            fileList={files}
                            autoUpload={false}
                            multiple={false}
                            removable={true}
                            accept="text/csv"
                            onChange={(files) => {
                                setFiles(files);
                            }}
                        >
                            <Button>Upload via CSV</Button>
                        </FormControl>

                        <Spacer size={15}/>

                        <StyledControlLabel>
                            * The CSV file must consist of 3 columns: number, group name, and payout. <br/>
                        </StyledControlLabel>

                    </>
                }

                <Spacer size={30}/>

              

                <FlexGrid align="middle">
                    {isUploadToGroup &&
                        <FlexGridItem>
                            <FormControl
                                cleanable={false}
                                searchable={false}
                                accepter={SelectPicker}
                                name={'existing_group_action'}
                                data={numberOptions}
                                placeholder={'Existing group action'}
                                style={{width: 224}}
                            />
                        </FlexGridItem>
                    }

                    <FlexGridItem>
                        <FormControl
                            cleanable={false}
                            searchable={false}
                            accepter={SelectPicker}
                            name={'test_number_option'}
                            data={testNumberOptions}
                            placeholder={'Test number option'}
                            // style={{width: 300}}
                        />
                    </FlexGridItem>
                </FlexGrid>

            </Form>
        </StyledCustomModal>
    )
};


const StyledCustomModal = styled(CustomModal)`
    && .rs-modal-dialog .rs-modal-content .rs-modal-body {
        overflow: hidden !important;
    }
`;

const StyledUploader = styled(Uploader)`
    
    .rs-uploader-file-item.rs-uploader-file-item-text {
        display: block !important;
    }

    .rs-uploader-file-item {
        background-color: transparent !important;
    }
    
    .rs-uploader-file-items {
        margin-top: 5px !important;
        flex-grow: 1;
    }
    
    .rs-uploader-file-item-title {
        white-space: normal !important;
    }

    .rs-uploader-file-item-size {
        margin-left: auto;
    }
`;

const StyledControlLabel = styled(ControlLabel)`
    font-style: italic;
    opacity: 0.6;
`;

const StyledForm = styled(Form)`

`;

const FormRow = styled.div`
    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const FormCol = styled.div`

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        padding-left: 10px;
        padding-right: 10px;
        width: 50%;

        &:nth-child(2n - 1) {
            padding-left: 0
        }

        &:nth-child(2n) {
            padding-right: 0
        }
    }
`;

const StyledField = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;

    @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
        display: flex;
    }

    .rs-control-label {
        flex-shrink: 0;
        align-self: center;
        padding-right: 10px;
        width: 100%;

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            width: 150px;
        }
    }

    .rs-input-number {

        @media (min-width: ${SCREEN_MEDIA.sm.min}px) {
            max-width: 90px;
        }
    }

    ${props => props.isCheckbox && css`
        display: flex;
        
        @media (max-width: ${SCREEN_MEDIA.sm.max}px) {

            .rs-form-control-wrapper {
                width: auto;
            }

            .rs-control-label {
                width: auto;
                order: 1;
            }
        }
    `}
`;

const CustomCheckboxWithSpaces = styled(CustomCheckbox)`
    && {
        margin-top: 10px;
        margin-bottom: 5px;
    }
`;