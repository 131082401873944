import React, {useMemo, useContext} from "react";
import {CustomModal} from "../base";
import {ButtonToolbar, FlexboxGrid} from "rsuite";
import {useWindowWidth} from "../../hooks";
import styled from "styled-components";
import {connect} from "react-redux";

import {FormattedMessage, useIntl} from "react-intl";
import {BaseButton} from "../base/BaseButton";
import m from "../../definedMessages";
import {AppTypeContext} from "App";
import {APP_TYPE_ADMIN} from "const";
import {Spacer} from "../base/Spacer";
import {formatLargeList} from "../../utils/format";
import { keyBy } from "lodash";


// Codes
const reasonCodes = {
    not_found_rate: "not_found_rate",
    not_found_free_numbers: "not_found_free_numbers",
    not_enough_free_numbers_sequence: "not_enough_free_numbers_sequence",
    account_trunk_range_mismatch: "account_trunk_range_mismatch",
    self_allocation_limit_exceeded: "self_allocation_limit_exceeded",
    self_allocation_for_sde_limit_exceeded: "self_allocation_for_sde_limit_exceeded",
    not_defined_range: "not_defined_range",

    prefix_in_quarantine: "prefix_in_quarantine",
    non_google_otp_allocation: "non_google_otp_allocation",
    max_numbers_per_range_value_exceeded: "max_numbers_per_range_value_exceeded",
    allocation_to_test_trunk: "allocation_to_test_trunk",
    non_google_otp_trunk: "non_google_otp_trunk",
    non_multiple_100_number: "non_multiple_100_number",
    not_allowed_self_google_otp_allocation: "not_allowed_self_google_otp_allocation",
    non_power_of_10_number: "non_power_of_10_number",
    non_power_of_10_number_start_1000: "non_power_of_10_number_start_1000",
    illegal_template_length: "illegal_template_length",
    illegal_template: "illegal_template",
    too_much_operations: "too_much_operations",
    too_much_operations_sde: "too_much_operations_sde",

    self_allocation_limit_exceeded_once: "self_allocation_limit_exceeded_once",
    self_allocation_limit_exceeded_daily: "self_allocation_limit_exceeded_daily",
    self_allocation_limit_exceeded_daily_sde: "self_allocation_limit_exceeded_daily_sde",
    self_allocation_limit_exceeded_all: "self_allocation_limit_exceeded_all",

    allocated: "allocated",
    amount_lt_limit: "amount_lt_limit",

    // Incorporated
    wrong_sde: "wrong_sde",
    undefined_sde: "undefined_sde",
    different_sde: "different_sde",
    different_group: "different_group",
    invalid_number: "invalid_number",
};


const ModalResponseHandler = (
    {
        show,
        params = {},
        maxWidth = 600,
        subdestinationList,
        onClose,
        onSuccess,
    }
) => {

    const {
        reasonCode,
        reasonHash,
        trunk = {},
        numbersAmount,
        randomNumber,
        template,
        number_list = [],
        template_list,
        group_name_list,
        range = "",
        notAllocatedNumbers = [],
        subdestinationKey = "",
        sde_key_list = [],
        startNumber = "",
        rangeName = "",
        message = null,
        content
    } = params;

    const appType = useContext(AppTypeContext);

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();


    const handleSuccess = () => {
        onClose && onClose(false);
        onSuccess && onSuccess(true);
    };


    const subdestinationName = useMemo(() => {
        if (!subdestinationKey) {
            return null;
        }
        const subdestination = subdestinationList.find(item => item.sde_key === subdestinationKey);
        return subdestination ? subdestination.name : null;
    }, [subdestinationList, subdestinationKey]);

    const subdestinationNameList = useMemo(() => {
        if (!sde_key_list) {
            return null;
        }

        return sde_key_list.map(key => {
            return subdestinationList.find(item => item.sde_key === key).name
        })
    });

    const isAdminApp = appType === APP_TYPE_ADMIN;


    const trunkName = trunk.name || trunk.tr_name || "";


    const getMessage = () => {


        switch (reasonCode) {

            // Incorporated
            case reasonCodes.invalid_number:
                return formatMessage(m.fileContainsSomeInvalidNumbers);

            case reasonCodes.wrong_sde:
                return formatMessage(m.globalAccessNumbersGroupHasDifferentSubdest);

            case reasonCodes.undefined_sde:
                return formatMessage(m.cannotDetermineSubdestByNumber);

            case reasonCodes.different_sde:
                return formatMessage(m.numbersAreFromDifferentSubdest);

            case reasonCodes.different_group:
                return formatMessage(m.numbersBelongToAnotherGroup);


            // Allocation
            case reasonCodes.allocated:
                const numberListString = notAllocatedNumbers.length ? notAllocatedNumbers.join(", ") : "";
                return `${numberListString}${numberListString.length ? ' - ' : ''}Numbers are already allocated`;

            case reasonCodes.self_allocation_limit_exceeded_once:
                return formatMessage(m.tooManyNumbersForOneTimeAllocation);

            case reasonCodes.self_allocation_limit_exceeded_daily:
                return formatMessage(m.dailyAllocationLimitIsExceed);

            case reasonCodes.self_allocation_limit_exceeded_daily_sde:
                return formatMessage(m.dailyAllocationLimitForThisDestinationIsExceed);

            case reasonCodes.self_allocation_limit_exceeded_all:
                return formatMessage(m.amountOfAllocatedNumbersExceedAllowedForThisTrunk);


            case reasonCodes.non_google_otp_trunk:
                return formatMessage(m.trunkIsNotGooogleOtp);

            case reasonCodes.non_google_otp_allocation:
                return formatMessage(m.incorrectAllocationMethod);

            case reasonCodes.non_power_of_10_number:
                return formatMessage(m.incorrectNumberAmount);

            case reasonCodes.non_power_of_10_number_start_1000:
                return formatMessage(m.incorrectNumberAmount);



            case reasonCodes.too_much_operations:
                return formatMessage(m.allocationForIsTemporaryUnavailable, {name: 
                    template ||
                    (formatLargeList(number_list)) ||
                    (template_list && (template_list.join(', '))) ||
                    (group_name_list && (group_name_list.join(', '))) ||
                    subdestinationName ||
                    (formatLargeList(subdestinationNameList)) ||
                    startNumber ||
                    rangeName
                });

            case reasonCodes.prefix_in_quarantine:
                return formatMessage(m.numbersAreTemporarilyUnavailable);

            case reasonCodes.max_numbers_per_range_value_exceeded:
                return formatMessage(m.tooMuchNumbersPerRange);

            case reasonCodes.allocation_to_test_trunk:
                return formatMessage(m.invalidOperation);

            case reasonCodes.non_multiple_100_number:
                return formatMessage(m.incorrectNumberAmount);

            case reasonCodes.illegal_template:
                return formatMessage(m.wrongTemplate);
            
            case reasonCodes.illegal_template_length:
                return formatMessage(m.templateHasIncorrectNumberLength);

            case reasonCodes.not_allowed_self_google_otp_allocation:
                return formatMessage(m.actionIsProhibited);

            case reasonCodes.self_allocation_limit_exceeded:
                return formatMessage(m.youHaveExceededTheLimit);

            case reasonCodes.self_allocation_for_sde_limit_exceeded:
                return formatMessage(m.tooMuchNumbersPerSubdestination);

            case reasonCodes.not_found_rate:
                return !isAdminApp
                    ? formatMessage(m.destinationIsNotAvailable)
                    : formatMessage(m.noRateForDestination);

            case reasonCodes.account_trunk_range_mismatch:
                if (template)
                    return formatMessage(m.accountTrunkRangeMismatchTemplate, {template: template, trunkName: trunkName});
                if (range)
                    return formatMessage(m.accountTrunkRangeMismatchRange, {range: range, trunkName: trunkName});
                return formatMessage(m.numbersCannotBeAllocatedToTrunk, {trunkName: trunkName});

            case reasonCodes.not_found_free_numbers:
                if (template) {
                    return formatMessage(m.numbersFromTemplateCannotBeAllocated, {template: template});
                }
                if (range) {
                    return formatMessage(m.numbersFromRangeCannotBeAllocated, {range: range});
                }
                if (subdestinationName) {
                    return formatMessage(m.numbersForSubdestCannotBeAllocated, {subDest: subdestinationName});
                }
                return formatMessage(m.numbersCannotBeAllocated);

            case reasonCodes.not_enough_free_numbers_sequence:
                if (template)
                    return formatMessage(m.notFoundFreeNumbersTemplate, {
                        numbersAmount: numbersAmount,
                        template: template
                    });
                if (range)
                    return formatMessage(m.notFoundFreeNumbersRange, {
                        numbersAmount: numbersAmount,
                        range: range
                    });
                if (subdestinationName)
                    return formatMessage(m.notFoundFreeNumbersDestination, {
                        numbersAmount: numbersAmount,
                        subDest: subdestinationName
                    });
                return formatMessage(m.sequenceCannotAllocate, {
                    numbersAmount: numbersAmount
                });

            case reasonCodes.too_much_operations_sde:
                if (template || subdestinationName) {
                    return formatMessage(m.tooMuchSimultaneousOperations);
                }

                return formatMessage(m.allocationIsTemporaryUnavailable);

            case reasonCodes.template_is_too_short:
                return formatMessage(m.templateIsTooShort);
                
            case reasonCodes.template_is_too_long:
                return formatMessage(m.templateIsTooLong);

            case reasonCodes.amount_lt_limit:
                return "The amount is less than limit of this payment method";       
            default:
                if (template) {
                    return formatMessage(m.numbersFromTemplateCannotBeAllocated, {template: template});
                }
        }

        return formatMessage(m.numbersCannotBeAllocated)
    };

    return (
        <CustomModal
            show={show}
            onClose={handleSuccess}
            width={windowWidth > maxWidth ? maxWidth : windowWidth}
        >

            <FlexboxGrid justify="end">
                <FlexboxGrid.Item colspan={23}>
                    <span>{getMessage()}{reasonHash ? ` (${formatMessage(m.operationCode)}: ${reasonHash})` : ""}</span>
                </FlexboxGrid.Item>
                {message && <FlexboxGrid.Item colspan={23}>
                    <Spacer />
                    <span>{formatMessage(m.details)}: {message}</span>
                </FlexboxGrid.Item>}
                {content &&
                    <FlexboxGrid.Item colspan={23}>
                        <Spacer />
                        {content}
                    </FlexboxGrid.Item>
                }
            </FlexboxGrid>

            <ModalButtonToolbar>
                <BaseButton
                    buttonStyle="default"
                    onClick={handleSuccess}
                    autoFocus
                >
                    <FormattedMessage id="common.close" defaultMessage="Close" />
                </BaseButton>
            </ModalButtonToolbar>

        </CustomModal>
    )
};


const mapState = ({references})=> ({
    subdestinationList: references.subdestination_list,
});

export default connect(mapState)(ModalResponseHandler);


const ModalButtonToolbar = styled(ButtonToolbar)`
    float: right;
    margin-right: 20px;
    margin-top: 40px;
    right: 0;
`;