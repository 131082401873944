import React, {useMemo, useEffect, useState} from "react";
import {Input, Form, Divider, SelectPicker, Schema} from "rsuite";
import Modal from "components/Modal";
import CustomField from "components/Form/CustomField";
import {servicePick, hasErrorObject} from "utils";
import styled from "styled-components";

const {NumberType, StringType} = Schema.Types;

const ManualModifyAccessModal = ({
    show,
    service,
    modifyAccessData,
    onModifyAccess,
    onCreateAccess,
    createAccessModal,
    onCloseModal,
    loading,

    supplierList,
    subdestinationList,
    numberTypeFamilyList
}) => {

    const hasModifyData = !!Object.keys(modifyAccessData).length;
    const resultFunction = createAccessModal ? onCreateAccess : onModifyAccess;

    const defaultFormValue = useMemo(() => {
        const currentTypeFamilyValue = numberTypeFamilyList.find(value => value.family_name === modifyAccessData.type_family_name);

        return hasModifyData ? {
            ...modifyAccessData,
            a_number_list: modifyAccessData.a_number_list && modifyAccessData.a_number_list.length ? modifyAccessData.a_number_list.join("\n") : null,
            antf_key: currentTypeFamilyValue ? currentTypeFamilyValue.antf_key : null
        } : {};
    }, [modifyAccessData, numberTypeFamilyList]);

    const [pickedInput, setPickedInput] = useState(true);

    const voiceFormModel = useMemo(() => {
        return Schema.Model({
            a_number_list: StringType()
                .addRule((value) => {
                    const valueList = value.trim()
                        .replace( /\r?\n/g, " ")
                        .split(" ")
                        .filter( s => s !== "");
                    for (const row of valueList) {
                        if (row.length > 15 || row.length < 7) {
                            return false;
                        }
                    }
                    return true;
                }, "Each row should be greater than 7 and less than 15")
                .addRule((value) => {
                    const valueList = value.trim()
                        .replace( /\r?\n/g, " ")
                        .split(" ")
                        .filter( s => s !== "");
                    return !((valueList).length > 3)
                }, "There only available 3 rows")
                .pattern(/^(?: *\d+ *(?:\n|$))+$/, "Please enter a valid numbers"),
            ...(pickedInput ? {
                antf_key: NumberType().isRequired("Required")
            } : {
                a_sde_key: NumberType().isRequired("Required")
            }),
            b_sde_key: NumberType().isRequired("Required"),
        })
    }, [pickedInput]);
    
    const smsFormModel = Schema.Model({
        b_sde_key: NumberType().isRequired("Required"),
        ...(pickedInput ? {
            senderid: StringType()
                .maxLength(40, "The maximum is 40 characters")
                .minLength(3, "The minimum is 3 characters")
                .isRequired("Required")
        } : {
            a_sde_key: NumberType().isRequired("Required"),
        })
    });

    const formModel = service ? voiceFormModel : smsFormModel;

    useEffect(() => {
        const isSdeInput = !!modifyAccessData?.a_sde_key;
        const isFamilyTypeInput = !!modifyAccessData?.antf_key;
        const isSenderIdInput = !!modifyAccessData?.senderid;

        if (isSdeInput) {
            setPickedInput(false);
        } else if (isFamilyTypeInput || isSenderIdInput) {
            setPickedInput(true);
        }
    }, [modifyAccessData]);

    const [formValue, setFormValue] = useState(defaultFormValue);

    const formHasError = useMemo(() => {
        const formErrorChecker = formModel.check(formValue);
        return hasErrorObject(formErrorChecker);
    }, [formValue, formModel]);

    const modalTitle = hasModifyData ? "Modify manual access" : "Create manual access";

    return (<Modal
        show={show}
        onSuccess={() => {
            const currentFormValue = {...formValue};

            if (currentFormValue.a_number_list) {
                currentFormValue.a_number_list = currentFormValue.a_number_list
                    .replace(/\r?\n/g, " ").split(" ").filter(s => s !== "");
            }

            if (currentFormValue.senderid === "") {
                currentFormValue.senderid = null;
            }

            resultFunction(currentFormValue);
            onCloseModal();
        }}
        onClose={onCloseModal}
        footer
        title={modalTitle}
        successText="Confirm"
        disabled={loading}
        extraDisabled={formHasError}
    >
        <StyledForm
            model={formModel}
            formValue={formValue}
            formDefaultValue={defaultFormValue}
            onChange={(value) => {
                setFormValue(value);
            }}
        >
            {service ? <CustomField
                label="OTP service"
                name="antf_key"
                accepter={SelectPicker}
                data={numberTypeFamilyList}
                labelKey="family_name"
                valueKey="antf_key"
                placeholder="OTP service name"
                disabled={!pickedInput}
                onClick={() => {
                    setPickedInput(true);
                    setFormValue((value) => {
                        return {
                            ...value,
                            a_sde_key: null
                        }
                    });
                }}
            /> : <CustomField
                label="Sender ID"
                name="senderid"
                accepter={Input}
                placeholder="Sender ID"
                className={!pickedInput ? "disabled" : ""}
                onClick={() => {
                    setPickedInput(true);
                    setFormValue((value) => {
                        return {
                            ...value,
                            a_sde_key: null
                        }
                    });
                }}
            />}
            {service && pickedInput ? <CustomField
                label="CLI list"
                accepter={Input}
                name="a_number_list"
                componentClass="textarea"
                wrap="off"
                rows={2}
                // style={{width: 400}}
                placeholder="CLI list(one per row)"
            /> : <></>}
            <Divider>OR</Divider>
            <CustomField
                label="Operator (subdest)"
                name="a_sde_key"
                accepter={SelectPicker}
                data={subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                disabled={pickedInput}
                onClick={() => {
                    setPickedInput(false);
                    setFormValue((value) => {
                        return {
                            ...value,
                            ...(servicePick(service, {antf_key: null}, {senderid: ""}))
                        }
                    });
                }}
                preventOverflow
            />
            <CustomField
                label="Destination"
                name="b_sde_key"
                accepter={SelectPicker}
                data={subdestinationList}
                labelKey="name"
                valueKey="sde_key"
                preventOverflow
            />
            {!service && <CustomField
                accepter={SelectPicker}
                data={supplierList}
                name="supplier_id"
                label="Supplier"
                placeholder="Suppliers"
                valueKey="supplier_id"
                labelKey="name"
                cleanable={true}
                searchable={true}
                preventOverflow
            />}
            {!service && <CustomField
                accepter={Input}
                name="message"
                label="Message"
                componentClass="textarea"
                wrap="off"
                rows={3}
                placeholder="Message"
            />}
        </StyledForm>
    </Modal>)
};

export default ManualModifyAccessModal;

const StyledForm = styled(Form)`
    .rs-input.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;