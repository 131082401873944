import React, {useContext} from "react";
import {connect} from "react-redux";
import {Button, Icon as RsIcon, Whisper, Popover} from "rsuite";
import styled, {css} from "styled-components";
import {APP_TYPE_CLIENT} from "const";
import {RtlContext} from "App";
import {useWindowWidth} from "hooks";
import Icon from "@mdi/react";
import { mdiMicrosoftTeams } from "@mdi/js";
import m from "definedMessages";
import { withTranslation } from "hoc/WithTranslation";

const SkypeWidget = ({
    supportContacts,
    appType,
    formatMessage
}) => {
    
    const rtl = useContext(RtlContext);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767

    if (!supportContacts || supportContacts && !supportContacts.arabic || supportContacts && !supportContacts.general || appType !== APP_TYPE_CLIENT) {
        return null;
    }

    const {skype: voiceTeams, sms_skype: smsTeams} = supportContacts.arabic;
    const {teams} = supportContacts.general;

    return (<>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>{formatMessage(m.voicePremiumySupport)}</Popover>}
            enterable={true}
        >
            {voiceTeams ? <TeamsButton rtl={rtl} href={voiceTeams} target="_blank" service={true}>
                {/* <Icon icon="skype"/> */}
                <Icon path={mdiMicrosoftTeams} size={2} color={"white"} />
                {!isMobile && <TeamsButtonText service={true}>
                    <span className="vc1">V</span>
                    <span className="vc2">O</span>
                    <span className="vc3">I</span>
                    <span className="vc4">C</span>
                    <span className="vc5">E</span>
                </TeamsButtonText>}
            </TeamsButton> : <></>}
        </Whisper>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>{formatMessage(m.smsPremiumySupport)}</Popover>}
            enterable={true}
        >
         {smsTeams ? <TeamsButton rtl={rtl} href={smsTeams} target="_blank" service={false}>
                {/* <Icon icon="skype"/> */}
                <Icon path={mdiMicrosoftTeams} size={2} color={"white"} />
                {!isMobile && <TeamsButtonText service={false}>
                    <span className="sc1">S</span>
                    <span className="sc2">M</span>
                    <span className="sc3">S</span>
                </TeamsButtonText>}
            </TeamsButton> : <></>}
        </Whisper> 
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>{formatMessage(m.generalPremiumySupport)}</Popover>}
            enterable={true}
        >
         {smsTeams ? <TeamsButton rtl={rtl} href={teams} target="_blank" general>
                {/* <Icon icon="skype"/> */}
                <Icon path={mdiMicrosoftTeams} size={2} color={"white"} />
                {!isMobile && <TeamsButtonText general>
                    <span className="gc1">G</span>
                    <span className="gc2">E</span>
                    <span className="gc3">N</span>
                    <span className="gc4">E</span>
                    <span className="gc5">R</span>
                    <span className="gc6">A</span>
                    <span className="gc7">L</span>
                </TeamsButtonText>}
            </TeamsButton> : <></>}
        </Whisper> 
    </>);
}

const TeamsButtonText = styled.div`
    && {
        /* font-size: 11px;
        position: absolute;
        bottom: 3px;
        left: 16px;
        color: white;
        font-weight: 600; */

        position: absolute;
        width: 60px;
        height: 60px;
        margin: 0px auto;
        top: 0px;
        font-size: ${props => props.service ? "10px" : "12px"};
        color: white;
        font-weight: 600;

        span {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 20px;
            height: 66px;
            margin-left: -10px;
            line-height: 108px; /* height*2 - font-size */
            text-align: center;
            transform-origin: top center;
        }

        ${props => props.general && css`
            font-size: 10px;
        
            span {
                height: 38px;
                line-height: 53px; 
            }
        `}

        span.sc1 {
            transform: rotate(13deg);
        }
        span.sc2 {
            transform: rotate(0deg);
        }
        span.sc3 {
            transform: rotate(-13deg);
        }

        span.vc1 {
            transform: rotate(15deg);
        }
        span.vc2 {
            transform: rotate(7deg);
        }
        span.vc3 {
            transform: rotate(0deg);
        }
        span.vc4 {
            transform: rotate(-7deg);
        }
        span.vc5 {
            transform: rotate(-15deg);
        }

        span.gc1 {
            transform: rotate(46deg);
        }
        span.gc2 {
            transform: rotate(31deg);
        }
        span.gc3 {
            transform: rotate(16deg);
        }
        span.gc4 {
            transform: rotate(0deg);
        }
        span.gc5 {
            transform: rotate(-16deg);
        }
        span.gc6 {
            transform: rotate(-31deg);
        }
        span.gc7 {
            transform: rotate(-46deg);
        }
    }
`;


const TeamsButton = styled(Button)`
    && {
        padding: 0;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
        box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 4px;
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            background: ${props => props.service ? "var(--color-main-hover)" : "#00aff0"};
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        &:hover {
            background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
            
            &:before {
                opacity: 1;
            }
        }

        &:focus, 
        &:active, 
        &:active:hover, 
        &:active:focus, 
        &:active.focus {
            background: ${props => props.service ? "linear-gradient(var(--color-main), var(--color-main-hover))" : "linear-gradient(#88d9f8, #00aff0)"};
        }

        .rs-icon {
            color: #fff;
            font-size: 30px;
            line-height: 50px;
        }

        bottom: ${props => props.service ? "10px" : "75px"};
        position: fixed;
        right: 10px;
        z-index: 10;

        
        ${props => props.general && css`
            bottom: 140px;

            background: linear-gradient(#93ff93, #48c948);

            &:before {
                top: 0;
                background: #48c948;
            }
            &:hover {
                background: linear-gradient(#93ff93, #48c948);
            }
            
            &:focus, 
            &:active, 
            &:active:hover, 
            &:active:focus, 
            &:active.focus {
                background: linear-gradient(#93ff93, #48c948);
            }

            && svg[role="presentation"] {
                /* margin-top: -10px; */
            }
        `}


        @media (max-width: 767px) {
            bottom: ${props => props.service ? "160px" : "100px"};
            right: 5px;
            width: 36px;
            height: 36px;

            .rs-icon {
                font-size: 16px;
                line-height: 36px;
            }

            ${props => props.general && css`
                bottom: 220px;
            `}

            && svg[role="presentation"] {
                width: 1.7rem !important;
                height: 2.3rem !important;
            }
        }

        ${props => props.rtl && css`
            left: 10px;
            right: auto;

            @media (max-width: 767px) {
                left: 5px;
                right: auto;
            }
        `}
    }

    && svg[role="presentation"] {
        width: 2.2rem !important;
        height: 3rem !important;
    }
`;


const mapState = ({references, auth})=> ({
    supportContacts: references.support_contacts,
    appType: auth.appType,
});
// withTranslation(
export default connect(mapState)(withTranslation(SkypeWidget));