import React from 'react';
import {api} from '../../../api/loginRoutes';
import {Alert} from 'rsuite';
import Icon from 'rsuite/es/Icon';
import {ButtonClose} from "../../../components/base/BaseButton";
import {CustomModal} from '../../../components/base/BaseModal';


export default (
    {
        extraParams,
        disabled,
        onSuccess,
        showReasonHandler,
        checkKey='incorporated_numbers',
        method='incorporated_number:remove'
    }
) => {

    const [show, setShow] = React.useState(false);
    const [loading, setLoading] = React.useState(false);


    const onSubmit = async () => {

        setLoading(true);
        const result = await api(method, {...extraParams});

        if (result.reason_code) {
            const trunkList = result?.trunk_list || {};
            const allocatedNumbers = trunkList.reduce((result, value) =>{
                const localAllocatedNumbers = value?.already_allocated_number_list || [];
                return [...localAllocatedNumbers, ...result];
            }, []);
            
            showReasonHandler({
                ...result,
                reasonCode: result.reason_code,
                reasonHash: result.hash,
                number_list: allocatedNumbers
            });
            setShow(false);
            setLoading(false);
            return;
        }

        if (result && result[checkKey]) {
            Alert.success(`${result[checkKey]} numbers were removed`);
            setShow(false);
            onSuccess();
        }
        setLoading(false);

    };
    return (
        <>
            <ButtonClose
                disabled={disabled}
                onClick={() => setShow(true)}
            >
                <Icon icon="trash"/> Remove
            </ButtonClose>
            
            <CustomModal
                show={show}
                title="Remove numbers"
                onClose={() => setShow(false)}
                showFooter={true}
                confirmButtonText="Confirm"
                loading={loading}
                onConfirm={onSubmit}
            >
                Selected numbers will be removed
            </CustomModal>
        </>

    );
}
