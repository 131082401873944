import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Login from "pages/Login";


const LoginPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <Login />
    </GoogleReCaptchaProvider>
  );
};

export default LoginPageWithRecaptcha;