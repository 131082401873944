import React, {useState} from 'react';
import { connect } from 'react-redux';
import {Form, Schema, Alert} from 'rsuite';
import {api} from '../../api/loginRoutes';
import {responseErrorToString, splitList} from '../../utils';
import {showReasonHandler} from '../../actions/reason_handler';
import ModalConfirm from '../Modal/ModalConfirm';
import { rulesAccept } from 'actions/auth';
import RulesRegulationWidget from 'components/RulesRegulationWidget';


const { StringType, NumberType } = Schema.Types;

const validationKeys = {
    name:   StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),

    str:    StringType().isRequired('Required').maxLength(40, 'The maximum is only 40 characters.'),
    
    str30:  StringType().isRequired('Required').maxLength(30, 'The maximum is only 30 characters.'),
    
    rate:   NumberType().isRequired('Required'),
    
    str_required: StringType().isRequired('Required'),
    
    num_required: NumberType().isRequired('Required'),

    number_list: StringType()
        .isRequired('Required')
        .pattern(/^(?: *\d+ *(?:\n|$))+$/, 'Please enter a valid numbers')
        .addRule((value) => {
            const valueList = splitList(value);
            for (const row of valueList) {
                if (row.length > 15 || row.length < 7) {
                    return false
                }
            }
            return true;
        }, 'Each row should be greater than 7 and less than 15')
        .addRule((value) => !(splitList(value).length > 100000),
            'The number of entered telephone numbers exceeds 100 000 rows'),

    template_list: StringType()
        .isRequired('Required')
        .pattern(/^[\d\[\],\-\sXx]*$/, 'Please enter valid templates') // example: 12X3[1,8]4[2-4,6]XX
        .addRule( (value) => {
            let isValid = true;
            const regex = /^[1-9](?:\d*[xX]*(\[((\d)|(\d-\d))((,\d-\d)?(,\d)?)*\])?)*\s*$/;

            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "");

            if (valueList) {
                valueList.forEach((template) => {
                    if(template.match(regex) === null) {
                        isValid = false;
                        return;
                    };
                });
            }

            return isValid;
        }, "Please enter valid templates")
        .addRule( (value) => {
            let isValid = true;
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .replace( /\[[\d\,\-]+\]/g, 'X')
                .split(' ').filter( s => s !== "");

            if (valueList) {
                valueList.forEach((template) => {
                    if(template.length < 7 || template.length > 15) {
                        isValid = false;
                        return;
                    };
                });
            }

            return isValid;

        }, "Each template should be greater than 7 and less than 15" ),


    numbers: NumberType()
        .isRequired('Required')
        .isInteger('Only whole numbers')
        .max(100000, 'The maximum of this field is 100000'),

    numbers_amount: NumberType()
        .isRequired('Required')
        .isInteger('Only whole numbers')
        .addRule((value, data) => {
            if (value > 1000) {
                return false
            }
            return true;
        }, 'The maximum of this field is 1000'),

    numbers_by_prefix: NumberType()
        .isRequired('Required')
        .isInteger('Only whole numbers'),

    ranges: NumberType()
        .isRequired('Required')
        .isInteger('Only whole numbers')
        .max(10000, "The maximum of this field is 10000"),
    
    in_template_list_range: StringType()
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "")
            for (const row of valueList) {
                if (row.length > 15 || row.length < 7) {
                    return false
                }
            }
            return true
        }, 'Each row should be greater than 7 and less than 15')
        .addRule((value) => {
            const valueList = value.trim()
                .replace( /\r?\n/g, ' ')
                .split(' ').filter( s => s !== "")
            return !((valueList).length > 100)
        }, 'The number of entered telephone numbers exceeds 100 rows')
        .pattern(/^(?: *[0-9]{6,14} *(?:[X]{3,6}\r|[X]{3,6}\n|[X]{3,6}$))+$/, 'Please enter a valid numbers') // 123456
        .isRequired('This field is required'),

    in_template_range: StringType()
    // .pattern(/^(?: *[0-9]{6,14} *([X]{1,6}$))$/, 'Please enter a valid numbers')
        .pattern(/^[1-9](?:\d*[xX]*(\[((\d)|(\d-\d))((,\d-\d)?(,\d)?)*\])?)*$/, 'Please enter a valid numbers')
        .addRule((value) => {
            let replacedValue = value.replace( /\[[\d\,\-]+\]/g, 'X');
            if (replacedValue.length >= 200 || replacedValue.length <= 3) {
                return false
            }
        }, 'Template should be greater than 3 and less than 200')
        .isRequired('This field is required'),
    
    start_number: StringType()
        .isRequired('This field is required')
        .pattern(/^[1-9][\d]*$/, 'The number must not begin with 0 and must contain only digits')
        .maxLength(15, 'The maximum  of this field is 15 characters')

};

export const MyForm = (_props) => {

    const {
        disabled, children, update, method, checkResultKey, noCheck, noSend, addData = {}, updateRef, setExtraLoader,
        target, activeTrunk = null, formValue = {}, layout = "inline", formDefaultValue = {}, fieldsToRemove = [],
        spAuth = false, model, unsendFields, deepCloned = false, optionFields = false, showReasonHandler, onClose,
        closeModal, clearFormValueByKeys, setHashCode, setResponseMessage, ...props
    } = _props;

    const childrenModel = {};
    const fieldsValues = {...formDefaultValue, ...formValue};
    let formRef;


    /**
     * Вместо проверки isArray Лучше использовать уже готовый метод React.Children.map
     * Удалил лишние If'ы
     */

    const [_formValue, setFormValue] = useState(fieldsValues);
    const [showConfirmModal, onShowConfirmModal] = useState(false);

    React.useEffect(() => {
        if (optionFields) {
            setFormValue(formValue);
        }
    }, [formValue, optionFields]);

    // Allocate by prefixes effect
    // React.useEffect(() => {
    //
    //     if (_props.allocateByPrefixesStatus === null)
    //         return;
    //
    //     const newFormValue = {..._formValue, allocate_by_prefix: _props.allocateByPrefixesStatus};
    //
    //     // check "numbers" field by allocate_by_prefixes
    //     if (!_props.allocateByPrefixesStatus) {
    //         if (_formValue.numbers > 1000) {
    //             newFormValue.numbers = 1000;
    //         }
    //     } else {
    //         const numberLog10 = Math.log10(_formValue.numbers);
    //         if ( (numberLog10 ^ 0) !== numberLog10 || numberLog10 > 6 ) {
    //             newFormValue.numbers = 10;
    //             formRef.cleanErrorForFiled("numbers");
    //         }
    //     }
    //
    //     setFormValue(newFormValue);
    // }, [_props.allocateByPrefixesStatus]);


    const cloneFormChildren = (formControl) => {

        const { props } = formControl || {};
        const { name, validationKey, children } = props || {};

        if (name) {
            if (validationKeys[validationKey]) {
                childrenModel[name] = validationKeys[validationKey];
            } else if (validationKeys[name]) {
                childrenModel[name] = validationKeys[name];
            }
        }

        // set new props
        let newProps = {
            disabled
        };

        if (name === "random_number" && _formValue.random_number === true) {
            newProps.checked = true;
        }

        // if (name === "allocate_by_prefix" && _props.allocateByPrefixesStatus) {
        //     newProps.checked = true;
        // }

        const elementChildren = children
            ? !name && deepCloned ? React.Children.map(children, cloneFormChildren) : children
            : null;

        return React.isValidElement(formControl)
            ? React.cloneElement(formControl, newProps, elementChildren)
            : formControl;
    };


    const _children = React.Children.map(children, cloneFormChildren);


    const modelData =  Schema.Model({
        ...childrenModel,
        ...model
    });

    const getFormValue = (form) => () => {
        let data = form.getFormValue();
        // После удаления значения в SelectPicker, значение заменяется на null, но после повторной отправки формы
        // значение превращается из null в 0, что не даёт валидации повторно вывести ошибку Required
        const sde_key = data.sde_key !== 0 ? data.sde_key : null;

        data = {...data, sde_key};
        // console.log(data)
        Object.keys(data).map( key => {
            const type = form.props.model.getFieldType(key);

            //console.log(key, type);
            if(type.name === "number"){
                data[key] = +data[key];
            }

            //Remove unused fields from data
            if (unsendFields && unsendFields.includes(key)) {
                delete data[key];
            }
        });

        return data;
    };

    const send = (form) => () => {
        if (!form) 
            return;

        return new Promise( async (resolve, reject) => {

            if (noSend) 
                return resolve(true);

            const data = getFormValue(form)();

            if (clearFormValueByKeys && clearFormValueByKeys.length) {
                clearFormValueByKeys.forEach((key) => {
                    if (data[key]) {
                        delete data[key];
                    }
                });
            }

            // Во многих формах, где есть возможность выбора метода используется одна и та же форма. Поля которые расчитаны
            // на один метод, при выборе другого очищаются, но в данных на отправку запроса остаётся ключ с нуловым значением.
            // Некоторые запросы валятся с валидацией, когда они не ожидают лишних ключей.
            // Object.keys(data).forEach(key => (data[key] == null || data[key] === "") && delete data[key]);
            Object.keys(data).forEach(key => {
                if ( data[key] == null || data[key] === ""
                    || (fieldsToRemove.length && fieldsToRemove.indexOf(key) !== -1) ) {
                    delete data[key]
                }
            });

            if( !form.check() )
                return resolve(false);
                
            const params = {
                target,
                ...data,
                ...addData,
                undefined: undefined
            };

            
            try {
                const result = await api(method, params, false);

                if (result) {
                    const reasonParams = {
                        ...params,
                        subdestinationKey: params.sde_key                     
                    };

                    if (result.hash) {
                        setHashCode(result.hash);
                    }

                    if (result.message) {
                        setResponseMessage(result.message)
                    }

                    // нужно задавать начальное значение "random_number" для всех форм
                    if (result.reason_code === "not_enough_free_numbers_sequence" && _formValue.random_number === false) {
                        setFormValue({..._formValue, random_number: true})
                    }

                    if (!result.trunk_number_transaction) {
                        if (result.reason_code === "not_found_free_numbers" || result.reason_code === "not_enough_free_numbers") {
                            showReasonHandler({
                                ...reasonParams,
                                ...result,
                                reasonCode: result.reason_code,
                                reasonHash: result.hash,
                                trunk: activeTrunk,
                                method: method,
                                number_list: result.not_allocated_numbers && result.not_allocated_numbers.map(item => item.number)
                            });
                            return resolve(false);
                            
                        }
                    }

                    if (method === "allocation:number_list") {

                        if (!result.trunk_number_transaction && !result.not_allocated_numbers) {
                            if (result.reason_code) {
                                showReasonHandler({
                                    ...reasonParams,
                                    ...result,
                                    reasonCode: result.reason_code,
                                    reasonHash: result.hash,
                                    trunk: activeTrunk,
                                    method: method,
                                });
                                return resolve(false);
                            }
                        }

                        if ( result.trunk_number_transaction && !result.trunk_number_transaction.numbers && (!result.not_allocated_numbers || result.trunk_number_transaction && !result.not_allocated_numbers.length) ) {
                            showReasonHandler({
                                ...reasonParams,
                                ...result,
                                reasonCode: 'default_list_number',
                                reasonHash: result.hash,
                                trunk: activeTrunk,
                                method: method,
                            });
                            return resolve(false);
                        }
                        
                    } else {
                        if (result.reason_code === "not_found_free_numbers_in_range") {
                            onShowConfirmModal(true);
                            return resolve(false);
                        } else if (result.reason_code && (result.trunk_number_transaction && !result.trunk_number_transaction || result.trunk_number_transaction && !result.trunk_number_transaction.numbers)) {
                            showReasonHandler({
                                ...reasonParams,
                                ...result,
                                reasonCode: result.reason_code,
                                reasonHash: result.hash,
                                trunk: activeTrunk,
                                method: method,
                                sde_key_list: params?.list ? params.list.map(sde => sde.sde_key) : [],
                                startNumber: params?.start_number
                            });
                            return resolve(false);
                        }
                    }

                    if (result.reason_code && (!result.trunk_number_transaction || (result.trunk_number_transaction && !result.not_allocated_numbers))) {
                        const smartParams = {
                            ...("template" in params ? {template: params.template} : {}),
                            ...("number_list" in params ? {number_list: params.number_list} : {}),
                            ...("template_list" in params ? {template_list: params.template_list} : {}),
                            ...("group_name_list" in params ? {group_name_list: params.group_name_list} : {}),
                            ...("range" in params ? {range: params.range} : {}),
                            ...("sde_key" in params ? {subdestinationKey: params.sde_key} : {}),
                            ...("list" in params ? {sde_key_list: params.list.map(item => item?.sde_key)} : {}),
                            ...("sde_key_list" in params ? {sde_key_list: params.sde_key_list} : {}),
                            ...("start_number" in params ? {startNumber: params.start_number} : {}),
                            ...("rangeName" in params ? {rangeName: params.rangeName} : {})
                        }

                        showReasonHandler({
                            ...reasonParams,
                            ...smartParams,
                            ...result,
                            reasonCode: result.reason_code,
                            reasonHash: result.hash,
                            trunk: activeTrunk,
                            method: method
                        })
                        return resolve(false);
                    }

                    if (noCheck || (Object.keys(result).length && ( (checkResultKey && result[checkResultKey]) || true))) {
                        update && update(result);
                        return resolve(true);
                    }
                }

            } catch (e) {
                console.log('error::', e)
                // Alert.error(responseErrorToString(e.response.data.error, e.response.status), 5000);
            }

            return resolve(false);
        });
    };

    const unFreeNumbersErrorHandler = async () => {

        const data = getFormValue(formRef)();

        const localParams = {
            target,
            ...data,
            ...addData,
            undefined: undefined
        };

        localParams.numbers = (localParams.ranges || 1) * localParams.numbers;
        localParams.ranges = null;
        localParams.random_number = true;

        return new Promise( async (resolve, reject) => {
            if (noSend)
                return resolve(true);

            setExtraLoader(true);

            try {
                const result = await api(method, localParams, false);

                const reasonParams = {
                    ...localParams,
                    subdestinationKey: localParams.sde_key
                };

                if (result.reason_code && (!result.trunk_number_transaction || !result.trunk_number_transaction.numbers)) {
                    if (result.reason_code) {
                        showReasonHandler({
                            ...reasonParams,
                            ...result,
                            reasonCode: result.reason_code,
                            reasonHash: result.hash,
                            trunk: activeTrunk,
                            method: method,
                        });
                        setExtraLoader(false);
                        return resolve(false);
                    }
                } else {
                    if (noCheck || (Object.keys(result).length && ( (checkResultKey && result[checkResultKey]) || true))) {
                        update && update(result);
                        setExtraLoader(false);
                        closeModal();
                        onClose();
                        return resolve(true);
                    }
                }
            } catch (e) {
                Alert.error(responseErrorToString(e.response.data.error, e.response.status), 5000);
            }

            return resolve(false);

        });

    };

    return (
        <>
            
            <Form
                {...props}
                formDefaultValue={formDefaultValue}
                formValue={{...formDefaultValue, ..._formValue}}
                onChange={(values) => {
                    if (props.onChange) props.onChange(values);

                    setFormValue(values);
                }}
                ref = { ref => {
                    updateRef({...ref, getFormValue: getFormValue(ref), send: send(ref)});
                    formRef = ref;
                }}
                layout = {layout}
                model = {modelData}
            >
                {_children}
            </Form>
            {showConfirmModal && <ModalConfirm
                show={showConfirmModal}
                title={"Confirm please"}
                onSuccess={unFreeNumbersErrorHandler}
                onClose={() => onShowConfirmModal(false)}
            >
                <p>Allocate less then {Math.min(1000, _formValue?.numbers)} numbers per range?</p>
            </ModalConfirm>}
        </>
    );
};

export default connect(null, {
    showReasonHandler
})(MyForm);