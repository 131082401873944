import React, { useState, useEffect } from "react";
import Page from "components/base/Page";
import PageHeader from "components/base/PageHeader";
import styled, {createGlobalStyle} from "styled-components";
import AllocationsFilter from "./AllocationsFilter";
import AllocationsTable from "./AllocationsTable";
import { FilterSectionFlexboxGrid, StyledItemFitWidth, StyledItemAutoMargin } from "components/base/FitContainers";
import { useIntl } from "react-intl";
import { withTranslation } from "hoc/WithTranslation";
import m from "definedMessages";


const AllocationsPage = ({
    data,
    loading,
    service,
    // count,
    // page,
    // perPage,

    getAllocationResultList,
    formatMessage
}) => {

    const defaultFilterValue = {str: ""};

    const [filter, setFilter] = useState(defaultFilterValue);

    // const defaultSort = {
    //     column: "number", type: "asc"
    // };

    // const [sort, setSort] = useState(defaultSort);

    useEffect(() => {
        getAllocationResultList(service);
    }, [filter]);

    const getItems = (_page, _perPage) => {
        getAllocationResultList(service);
    };

    return (<Page>
        <GlobalStyle/>
        <PageHeader title={ formatMessage(m.allocationsHeader) } showService={true}/>

        <FilterSectionFlexboxGrid>
            <StyledItemFitWidth>
                <AllocationsFilter
                    filter={filter}
                    setFilter={setFilter}

                    formatMessage={formatMessage}
                />
            </StyledItemFitWidth>
            <StyledItemAutoMargin>
            </StyledItemAutoMargin>
        </FilterSectionFlexboxGrid>


        <AllocationsTable
            data={data}
            // count={count}
            // page={page}
            // perPage={perPage}
            loading={loading}

            // setSort={setSort}
            // getItems={getItems}

            formatMessage={formatMessage}
        />
    </Page>)
};

export default withTranslation(AllocationsPage);


const GlobalStyle = createGlobalStyle`
    .rs-picker-check-menu-items {
        overflow-y: hidden !important;
        max-height: none !important;
    }
`;