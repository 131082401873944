import React, {useState, useContext} from "react";
import {connect} from "react-redux";
import {Button, Icon, Whisper, Popover} from "rsuite";
import styled, {css} from "styled-components";
import {RtlContext} from "App";
import {useWindowWidth} from "hooks";
import m from "definedMessages";
import { withTranslation } from "hoc/WithTranslation";

const ShowWidgetsButton = ({
    show,
    setShow,

    formatMessage
}) => {
    
    const rtl = useContext(RtlContext);

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 767
    const buttonText = show ? formatMessage(m.hideSupport) : formatMessage(m.showSupport);

    const [moved, setMoved] = useState(false);

    const handleClick = () => {
        setMoved(prev => !prev);
    };

    const turnBackIcon = rtl ? "chevron-circle-left" : "chevron-circle-right";

    return (<>
        <Whisper 
            placement={rtl ? "right" : "left"} 
            trigger="hover" 
            speaker={<Popover>{buttonText}</Popover>}
            enterable={true}
        >
            <ShowButton 
                rtl={rtl}
                $moved={moved}
                onClick={() => {
                    setShow(!show)
                    handleClick();
                }}
            > 
                {!show ? <Icon icon="help-o"/> : <Icon icon={turnBackIcon}/>}
            </ShowButton>

            {/* <TeamsButton rtl={rtl} onClick={() => setShow(!show)} general>
                {!show ? <Icon icon="help-o"/> : <Icon icon="chevron-circle-right"/>}
            </TeamsButton> */}
        </Whisper> 
    </>);
}

const ShowButton = styled(Button)`
    && {
        padding: 0;
        width: ${({ $moved }) => ($moved ? "34px": "100px")};
        height: ${({ $moved }) => ($moved ? "34px": "50px")};
        border-radius: 60px;
        background: ${({ $moved }) => ($moved ? "lightslategray": "linear-gradient(var(--color-primary-hover), var(--color-brand1))")};
      
        position: relative;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            background: ${({ $moved }) => ($moved ? "lightslategray": "var(--color-brand1)")};
            z-index: -1;
            opacity: 0;
            transition: opacity 0.2s ease;
            top: 0px;
        }

        &:hover {
            background: ${({ $moved }) => ($moved ? "lightslategray": "linear-gradient(var(--color-primary-hover), var(--color-brand1))")};
            
            &:before {
                opacity: 1;
            }
        }

        &:focus, 
        &:active, 
        &:active:hover, 
        &:active:focus, 
        &:active.focus {
            background: ${({ $moved }) => ($moved ? "lightslategray": "linear-gradient(var(--color-primary-hover), var(--color-brand1))")};
        }

        .rs-icon {
            color: white !important;
            font-size: 25px;
            line-height: 28px;
            right: ${({ $moved }) => ($moved ? "0px": "28px")};
            position: relative;
        }

        bottom: 110px;
        position: fixed;
        right: ${({ $moved }) => ($moved ? "0px": "-60px")};
        z-index: 10;

        transition: transform 0.4s ease;
        transform: ${({ $moved }) => ($moved ? "translate(-22px, -165px)" : "translate(0, 0)")};

        @media (max-width: 767px) {
            bottom: ${props => props.service ? "160px" : "100px"};
            right: 5px;
            width: 36px;
            height: 36px;

            .rs-icon {
                font-size: 16px;
                line-height: 36px;
                right: 0;
            }

            transition: transform 0.4s ease;
            transform: ${({ $moved }) => ($moved ? "translate(0px, -235px)" : "translate(0, 0)")};
        }

        ${props => props.rtl && css`
            left: ${({ $moved }) => ($moved ? "0px": "-60px")};
            right: auto;
            

            .rs-icon {
                color: white !important;
                font-size: 25px;
                line-height: 28px;
                right: ${({ $moved }) => ($moved ? "0px": "-28px")};
                position: relative;
            }

            transition: transform 0.4s ease;
            transform: ${({ $moved }) => ($moved ? "translate(22px, -165px)" : "translate(0, 0)")};

            @media (max-width: 767px) {
                left: 5px;
                right: auto;

                .rs-icon {
                    font-size: 16px;
                    line-height: 36px;
                    right: 0;
                }


                transition: transform 0.4s ease;
                transform: ${({ $moved }) => ($moved ? "translate(0px, -235px)" : "translate(0, 0)")};
            }
        `}
    }
`;

export default withTranslation(ShowWidgetsButton);