import React, {useMemo, useState, useContext} from "react";
import {Icon} from "rsuite";
import MenuDesktop from "./MenuDesktop";
import MenuMobile from "./MenuMobile";
import {checkPermissionsFor} from "store/storeHelpers";
import {useWindowWidth} from "hooks";
import {
    ACCOUNT_GET_LIST,
    ACCESS_EXCLUSION_LIST_API,
    ACCOUNT_MANAGERS_REFERENCES_API,
    ACTION_LOGS_GET_LIST_API,
    AM_ACTIVITIES_LIST_API,
    BLOCKED_DESTINATIONS_GET_LIST_API,
    PAYMENT_REQUEST_LIST_API,
    RATECARD_LIST_ACCOUNT_DIALER,
    MANAGER_ACCOUNT_USER_GET_LIST,
    NEWS_LIST_API,
    INCORPORATED_GROUP_LIST_API,
    SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD,
    RATECARD_LIST_OTP_DEFAULT_DIALER,
    RATECARD_LIST_DEFAULT_DIALER,
    RATECARD_LIST_INCORPORATED_DEFAULT_DIALER,
    RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER, 
    RATECARD_LIST_OTP_ACCOUNT_DIALER, 
    REVOKE_MASS_REVOKE_API, 
    SMS_REVOKE_MASS_REVOKE_API,
    DIALER_GROUPS_LIST_API,
    SMS_MANUAL_ACCESS_LIST_GET_LIST_API,
    MANUAL_ACCESS_LIST_GET_LIST_API,
    SUBACC_GET_SUB_ACCOUNT_LIST_METHOD,
    SMS_ACCESS_EXCLUSION_LIST_API,
    OTP_RULE_API_GET_LIST,
    SMS_SEARCH_GET_LIST,
    SMS_ALLOCATION_SUBDESTINATION_API
} from "const/apiMethods";
import {useIntl} from "react-intl";
import {APP_TYPE_ADMIN, transformed_service_data} from "const";
import {useLocation} from "react-router";
import {Link} from "react-router-dom";
import {servicePick} from "utils";
import {RtlContext, AppTypeContext} from "../../../App";

import m from "definedMessages";


export default ({
    service,
    permissions,
    openAllocationModal,
    setDialerService,
    registrationCount,
    accountJoinList,
    authInfo,
    logoutUser,
    informators,
    servicePlanList
}) => {

    const intl = useIntl();
    const rtl = useContext(RtlContext);
    const location = useLocation();
    const appType = useContext(AppTypeContext);
    const windowWidth = useWindowWidth();

    const menuDataAdmin = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchServiceParam = searchParams.get("service");

        const filterMenuItems = (items, withService=false) => (
            items.reduce((sum, item) => {
                const children = (item.children && item.children.length)
                    ? filterMenuItems(item.children, withService)
                    : null;
                if (item.hide) {
                    return sum;
                }
                if (item.checkedMethods) {
                    for (const method of item.checkedMethods) {
                        if (!checkPermissionsFor(method))
                            return sum;
                    }
                }

                searchParams.set("service", searchServiceParam);

                return [...sum, {
                    ...item, 
                    // ${searchParams.toString()}
                    ...(item.key !== null && withService ? {key: `${item.key}`} : {}), //?service=${searchServiceParam}
                    children: children
                }];
            }, [])
        );

        const menuClientFilter = (menu) => {
            return menu.filter(item => !(isTest && item.hideOnTest && !item.hide))
                .reduce((data, value, idx) => {
                    data[idx] = {
                        ...value,
                        ...(value?.children ? {children: value.children.filter(item => !(isTest && item.hideOnTest) && !item.hide)} : {})
                    };

                    return data;
                }, []);
        };

        const menuAdminFilter = (menu) => {
            return menu.reduce((data, value, idx) => {
                if (!value.hasOwnProperty("showOnService") || value?.showOnService === service) {
                    data[idx] = {
                        ...value, 
                        ...(value?.children ? {children: value.children.filter(item => !item.hasOwnProperty("showOnService") || item?.showOnService === service)} : {})
                    }
                }
                if (data[idx]?.children && data[idx].children.length === 1 && !!data[idx].key) {
                    delete data[idx].children;
                }
              return data;
            }, [menu]);
        };
        
        const createServiceChildren = (accounts = [], pathname= "") => {
             if (typeof pathname === "string" || (Array.isArray(pathname) && pathname.length === 1)) {
                if (!accounts.length) {
                    return {}
                } else if (accounts.length === 1) {
                    const accountService = accounts[0];
                    const currentService = accountService?.service;
            
                    return {
                        onClick: (() => {
                            setDialerService(transformed_service_data[currentService]);
                        })
                    }
                } else {
                    return {
                        children: accounts.map(accountService => {
                            const currentService = accountService?.service;
                            const pathServiceKey = `${pathname}-${currentService}`;
                            
                            let serviceName = currentService;

                            if (currentService === "voice") {
                                serviceName = intl.formatMessage(m.voice);
                            } else if (currentService === "sms") {
                                serviceName = intl.formatMessage(m.sms);
                            }

                            // const titleService = `${currentService[0].toUpperCase()}${currentService.slice(1)}`;

                            return {
                                key: pathServiceKey, title: serviceName, onClick: (() => {
                                    setDialerService(transformed_service_data[currentService]);
                                })
                            }
                        })
                    }
                }
            }

            if (Array.isArray(pathname) && pathname.length > 1) {
                const pathnameChildren = pathname.flatMap((path) => {
                    const {key, title, ...pathParams} = path;

                    if (!accounts.length) {
                        return {}
                    } else if (accounts.length === 1 || pathParams.noService) {
                        const accountService = accounts[0];
                        const currentService = accountService?.service;
          
                        const pathServiceKey = key;
                        const onClickFunc = !pathParams.noService ? {onClick: (() => {
                            setDialerService(transformed_service_data[currentService]);
                        })} : {};
                        return {
                            key: pathServiceKey, title: title, ...pathParams,
                            ...onClickFunc
                        }
                    }


                    return accounts.map(accountService => {
                        const currentService = accountService?.service;
                        const pathServiceKey = `${key}-${currentService}`;
                        
                        let serviceName = currentService;

                        if (currentService === "voice") {
                            serviceName = intl.formatMessage(m.voice);
                        } else if (currentService === "sms") {
                            serviceName = intl.formatMessage(m.sms);
                        }

                        // const titleService = `${currentService[0].toUpperCase()}${currentService.slice(1)}`;

                        return {
                            key: pathServiceKey, title: `${title} ${serviceName}`, ...pathParams,
                            onClick: (() => {
                                setDialerService(transformed_service_data[currentService]);
                            })
                        }
                    });
                });

                return {
                    children: pathnameChildren
                }
            }
        };

        const roleList = authInfo?.session?.account_user?.role_list;
        const isRatemod = roleList && roleList.length ? roleList.includes("Ratemod") || roleList.includes("Ratemod (sms)") : null;

        if (isRatemod) {
            const checkMethods = servicePick(service, [INCORPORATED_GROUP_LIST_API], [SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD])
            return menuAdminFilter([
                {key: "/sms-suppliers", title: "Suppliers", showOnService: false},
                {
                    key: "/global-access-numbers",
                    title: "Global access numbers",
                    checkedMethods: checkMethods,
                    badge: service && informators.incorporated_group_without_payout_count &&
                        <>
                            <Link
                                to="/global-access-numbers?without-payout-only=1"
                                onClick={() => {}}
                            > </Link>
                            {informators.incorporated_group_without_payout_count}
                        </>,
                    children: [
                        {
                            key: "/global-access-numbers",
                            title: "Global access numbers",
                            checkedMethods: checkMethods
                        },
                        {key: "/suppliers", title: "Suppliers", checkedMethods: [], showOnService: true},
                    ]
                },
            ]);
        }

        const isTest = authInfo?.session?.is_test;

        const ratecardPerm = isTest
            ? [RATECARD_LIST_OTP_DEFAULT_DIALER, RATECARD_LIST_DEFAULT_DIALER, RATECARD_LIST_INCORPORATED_DEFAULT_DIALER]
            : [RATECARD_LIST_OTP_ACCOUNT_DIALER, RATECARD_LIST_ACCOUNT_DIALER, RATECARD_LIST_INCORPORATED_ACCOUNT_DIALER]

        const hideOnSmsSign = accountJoinList && accountJoinList.length === 1 && accountJoinList.find(account => account.service === "sms");
        const accountList = authInfo?.session ? authInfo?.session?.account_list : [];
        const hasReseller = accountList.some((account) => account.service === "voice" && account.is_reseller);

        const clientMenu = [
            {key: "/hot-accesses", title: intl.formatMessage(m.hotAccessList),
                ...createServiceChildren(accountJoinList, "/hot-accesses")
            },
            {key: "/live-calls", title: intl.formatMessage(m.liveCalls), icon: <Icon icon="phone-square"/>, hide: !!hideOnSmsSign},
            ...(!isTest ? [{key: null, title: intl.formatMessage(m.myNumbers),
                ...createServiceChildren(accountJoinList, 
                    [
                        {
                            key: "/numbers",
                            title: intl.formatMessage(m.myNumbers),
                            hideOnTest: true
                        }, 
                        {
                            key: "/price",
                            title: intl.formatMessage(m.ratesAndNumbers),
                            hideOnTest: false,
                            checkedMethods: ratecardPerm
                        }
                    ]
                )
            }] :  [{
                key: "/price",
                title: intl.formatMessage(m.ratesAndNumbers),
                ...createServiceChildren(accountJoinList, "/price")
            }]),
            {key: "/traffic", title: intl.formatMessage(m.report), hideOnTest: true,
                ...createServiceChildren(accountJoinList, "/traffic")
            },
            {key: null, title: intl.formatMessage(m.accesses),
                ...createServiceChildren(accountJoinList, 
                    [
                        {
                            key: "/accesses",
                            title: intl.formatMessage(m.accessList),
                        },
                        {
                            key: "/global-accesses-numbers",
                            title: intl.formatMessage(m.gaNumbers),
                            hide: servicePlanList && !servicePlanList.find(item => item.gan)
                        },
                        {
                            key: "/cli",
                            title: intl.formatMessage(m.cliLookup),
                            hideOnTest: true,
                        }
                    ]
                )
            },
            {key: null, title: intl.formatMessage(m.other),
                ...createServiceChildren(accountJoinList, 
                    [
                        {
                            key: "/api",
                            title: "API",
                            hideOnTest: true,
                            noService: true
                        },
                        {
                            key: "/test-calls",
                            title: intl.formatMessage(m.testCalls)
                        },
                        {
                            key: "/auth-numbers",
                            title: intl.formatMessage(m.otpNumbers),
                            hideOnTest: true,
                            noService: true,
                            hide: hideOnSmsSign
                        },
                        // {
                        //     key: "/allocations",
                        //     title: "Allocations",
                        //     hideOnTest: true
                        // }
                    ]
                )
            },
            {key: "/payments", title: intl.formatMessage(m.payments), hideOnTest: true},
            {key: null, title: intl.formatMessage(m.subaccounts), hideOnTest: true, 
             hide: hideOnSmsSign || !hasReseller, checkedMethods: [SUBACC_GET_SUB_ACCOUNT_LIST_METHOD],
                children: [
                    {key: "/sub-accounts", title: intl.formatMessage(m.subaccounts)},
                    {key: "/sub-numbers", title: intl.formatMessage(m.numbers)},
                    {key: "/sub-traffic", title: intl.formatMessage(m.traffic)},
                    {key: "/sub-live-calls", title: intl.formatMessage(m.liveCalls)},
                    {key: "/sub-access-list", title: intl.formatMessage(m.accessList)},
                    {key: "/sub-settings", title: intl.formatMessage(m.settings)}
                ]
            }
        ];

        const adminMenu = [
            {key: "/accounts", title: servicePick(service, "Accounts", "Dialers"), checkedMethods: [ACCOUNT_GET_LIST]},
            {key: "/sms-suppliers", title: "Suppliers", showOnService: false},
            {key: "/prices", title: "Prices"},
            {key: null, title: "Traffic",
                children: [
                    {key: "/cdr", title: servicePick(service, "CDR", "MDR")},
                    {key: "/traffic-reports", title: servicePick(service, "Traffic Reports", "MDR Reports")},
                    {key: "/failed-mdr", title: "Failed MDR", showOnService: false},
                    {key: "/failed-mdr-reports", title: "Failed MDR Reports", showOnService: false},
                    {key: "/live-calls", title: "Live calls", showOnService: true},
                    {key: "/access-list", title: "Access list"},
                    {key: "/otp-rules", title: "OTP Rules", showOnService: false, checkedMethods: [OTP_RULE_API_GET_LIST]},
                    {key: "/otp-search", title: "OTP Search", showOnService: false, checkedMethods: [SMS_SEARCH_GET_LIST]},
                    {key: "/exceptions-accesses", title: "Exceptions for access lists", checkedMethods: [servicePick(service, ACCESS_EXCLUSION_LIST_API, SMS_ACCESS_EXCLUSION_LIST_API)]},
                    {key: "/manual-accesses", title: "Manual accesses", checkedMethods: [servicePick(service, MANUAL_ACCESS_LIST_GET_LIST_API, SMS_MANUAL_ACCESS_LIST_GET_LIST_API)]},
                ]
            },
            {key: null, title: "Number allocation",
                children: [
                    {key: "?modal=allocate_numbers",  title: "Allocate numbers", onClick: openAllocationModal, checkedMethods: [SMS_ALLOCATION_SUBDESTINATION_API]},
                    {key: "/ranges-numbers", title: `Ranges & numbers`},
                    {key: "/blocked-destinations", title: "Blocked Destinations", checkedMethods: [BLOCKED_DESTINATIONS_GET_LIST_API]},
                    // {key: "/sim-traffic-ranges", title: "SIM Traffic Ranges", checkedMethods: [SIM_TRAFFIC_RANGES_GET_LIST], showOnService: true},
                    {key: "/mass-revoke", title: "Mass revoke", checkedMethods: [servicePick(service, REVOKE_MASS_REVOKE_API, SMS_REVOKE_MASS_REVOKE_API)]},
                ]
            },
            {key: "/payments", title: `Payment management`, checkedMethods: [PAYMENT_REQUEST_LIST_API]},
            {key: null, title: "Tools", showOnService: true,
                children: [
                    {key: "/ivrs", title: "IVRs"},
                ]
            },
            {key: null, title: "Settings",
                children: [
                    {key: "/auth-numbers", title: "Whitelisted CLIDs", showOnService: true},
                    {key: "/account-managers", title: "Account Managers", checkedMethods: [ACCOUNT_MANAGERS_REFERENCES_API]},
                    {key: "/admin-panel-users", title: "Admin Panel Users", checkedMethods: [MANAGER_ACCOUNT_USER_GET_LIST]},
                    {key: "/settings", title: "Admin panel settings", checkedMethods: ["global_setting:get_list"]},
                    {key: "/routing", title: "Routing", /*checkedMethods: ["global_setting:get_list"]*/ showOnService: false},
                    {key: "/e164", title: "E164", /*checkedMethods: ["global_setting:get_list"]*/ showOnService: true},
                    {key: "/e212", title: "E164/E212", /*checkedMethods: ["global_setting:get_list"]*/ showOnService: false},
                ]
            },
            {key: null, title: "Logs",
                children: [
                    {key: "/action-logs", title: "Action Logs", checkedMethods: [ACTION_LOGS_GET_LIST_API]},
                    {key: "/am-activities", title: "AM activities", showOnService: true, checkedMethods: [AM_ACTIVITIES_LIST_API]},

                ]
            },
            {key: null, title: "News",
                children: [
                    {key: "/news-management", title: "News Manager",  checkedMethods: [NEWS_LIST_API]},
                    {key: "/news-dialer-groups", title: "News Dialer Groups", checkedMethods: [DIALER_GROUPS_LIST_API]}
                ]
               },            
            {
                key: "/global-access-numbers",
                title: "Global access numbers",
                checkedMethods: [servicePick(service, INCORPORATED_GROUP_LIST_API, SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD)],
                badge: service && informators.incorporated_group_without_payout_count &&
                    <>
                        <Link
                            to="/global-access-numbers?without-payout-only=1"
                            onClick={() => {}}
                        > </Link>
                        {informators.incorporated_group_without_payout_count}
                    </>,
                children: [
                    {
                        key: "/global-access-numbers",
                        title: "Global access numbers",
                        checkedMethods: [servicePick(service, INCORPORATED_GROUP_LIST_API, SMS_SUPPLIER_GAN_GROUP_GET_LIST_METHOD)]
                    },
                    {key: "/suppliers", title: "Suppliers", checkedMethods: [], showOnService: true},
                ]
            },
        ];

        return appType === APP_TYPE_ADMIN
            ? filterMenuItems(menuAdminFilter(adminMenu), true)
            //: filterMenuItems(clientMenu.filter(item => !(isTest && item.hideOnTest)), false);
            : filterMenuItems(menuClientFilter(clientMenu), false);

    }, [permissions, appType, service, intl, authInfo, informators, servicePlanList, accountJoinList]);
    

    return (
        <>
            {windowWidth > 1200
                ? <MenuDesktop registrationCount={registrationCount} data={menuDataAdmin} />
                : <MenuMobile rtl={rtl} logoutUser={logoutUser} registrationCount={registrationCount} data={menuDataAdmin} m={m} />
            }
        </>
    )
};