import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useIntl} from "react-intl";
import {Divider} from 'rsuite';

import {
    ALLOCATION_TEMPLATE_DIALER_API,
    ALLOCATION_SUBDESTINATION_DIALER_API,
    ALLOCATION_NUMBER_LIST_DIALER_API,
    SMS_ALLOCATION_TEMPLATE_DIALER_API,
    SMS_ALLOCATION_SUBDESTINATION_DIALER_API,
    SMS_ALLOCATION_NUMBER_LIST_DIALER_API,
} from "../../../../const/apiMethods";
import m from "../../../../definedMessages";
import * as S from './styled';
import {api} from "../../../../api/loginRoutes";
import {useWindowWidth} from "../../../../hooks";

import {Spacer} from '../../../../components/base/Spacer';
import TrunksList from '../../../../components/TrunksList';

import FormWhisper from './FormWhisper';
import FormAllocationTemplate from "./FormAllocationTemplate";
import FormAllocationNumberList from "./FormAllocationNumberList";
import {connect} from "react-redux";
import {REASON_CODES, SP_KEY__GLOBAL_ACCESS, SP_KEY__INCORPORATED, SP_KEY__CONFERENCE_CALLS} from '../../../../const';
import {servicePick} from "../../../../utils/helpers";


const formNumberListValueDefault = {number_list: ""};
const FORM_TEMPLATE = "FORM_TEMPLATE";
const FORM_NUMBER_LIST = "FORM_NUMBER_LIST";


const COUNTER_VALUE = 30;
const TRY_DELAY_VALUE = 20000;


const ModalAllocationNumbers = (
    {
        maxWidth = 600,
        show,
        trunks,
        spKey,
        service,
        selectedTrunkId,
        loading,
        showReasonHandler,
        subdestinationList,
        globalSettings,

        onSubmit,
        onSuccess,
        onClose,
    }
) => {

    const countDownTimers = useRef({});
    const tryTimers = useRef({});

    const templateFormRef = useRef();
    const numberListFormRef = useRef();

    const {formatMessage} = useIntl();
    const windowWidth = useWindowWidth();
    const isMobile = useMemo(() => windowWidth <= maxWidth, [windowWidth]);

    const isSms = !service;
    const formTemplateValueDefault = {
        template: "",
        sde_key: null,
        numbers: 3,
        ...(!isSms ? {
            random_number: true
        } : {})
    };

    const [activeForm, setActiveForm] = useState(FORM_TEMPLATE);
    const [localLoading, setLocalLoading] = useState(false);
    const [trunkId, setTrunkId] = useState(selectedTrunkId);
    const [formValueTemplate, setFormTemplateValue] = useState({...formTemplateValueDefault});
    const [formValueNumberList, setFormNumberListValue] = useState({...formNumberListValueDefault});
    const [isBySde, setIsBySde] = useState(false);
    const [incorrectSpKey, isIncorrectSpKey] = useState(spKey === SP_KEY__CONFERENCE_CALLS);

    const [inactiveDirections, setInactiveDirections] = useState({});


    const currentTrunkLimits = useMemo(() => (
        !trunks || !trunkId
            ? null
            : trunks.find(item => item.id === trunkId)?.allocation_limit
    ), [trunks, trunkId]);


    useEffect(() => {
        return () => {
            for ( const id of Object.keys(countDownTimers.current) ) {
                clearTimeout(countDownTimers.current[id]);
            }
            for ( const id of Object.keys(tryTimers.current) ) {
                clearTimeout(tryTimers.current[id]);
            }
        }
    }, []);
    
    const sdeMethod = servicePick(service, ALLOCATION_SUBDESTINATION_DIALER_API, SMS_ALLOCATION_SUBDESTINATION_DIALER_API);
    const templateMethod = servicePick(service, ALLOCATION_TEMPLATE_DIALER_API, SMS_ALLOCATION_TEMPLATE_DIALER_API);
    const numberListMethod = servicePick(service, ALLOCATION_NUMBER_LIST_DIALER_API, SMS_ALLOCATION_NUMBER_LIST_DIALER_API);



    const forms = useMemo(() => ({
        [FORM_TEMPLATE]: {
            method: isBySde 
                ? sdeMethod
                : templateMethod,
            formRef: templateFormRef,
        },
        [FORM_NUMBER_LIST]: {
            method: numberListMethod,
            formRef: numberListFormRef,
        }
    }), [templateFormRef, numberListFormRef, isBySde, service]);


    useEffect(() => {
        setDefaults();
        setIsBySde(false);
    }, [activeForm]);


    useEffect(() => {
        setTrunkId(selectedTrunkId);
    }, [selectedTrunkId]);


    useEffect(() => {
        if (templateFormRef?.current) {
            templateFormRef.current.cleanErrorForFiled(isBySde ? 'template' : 'sde_key');
        }

        setFormTemplateValue(prev => isBySde 
            ? {...formTemplateValueDefault, sde_key: prev.sde_key}
            : {...formTemplateValueDefault, template: prev.template}
        );
    }, [isBySde]);


    const filteredTrunks = useMemo(() => {
        if (!trunks) {
            return [];
        }

        return trunks.filter(trunk => {
            if (!trunk || !trunk?.allocation_limit?.allocate) {
                return false;
            }
            return !trunk.closed && trunk.active && ![SP_KEY__GLOBAL_ACCESS, SP_KEY__INCORPORATED].includes(trunk.sp_key)
        })
    }, [trunks]);

    useEffect(() => {
        const newTrunk = filteredTrunks.find(trunk => trunk.id === trunkId);
        if (newTrunk) {
            isIncorrectSpKey(newTrunk.sp_key === SP_KEY__CONFERENCE_CALLS);
            setActiveForm(FORM_TEMPLATE)
        }
    }, [selectedTrunkId, trunkId]);

    const setDefaults = () => {
        for (const formName of Object.keys(forms)) {
            forms[formName].formRef.current && forms[formName].formRef.current.cleanErrors();
        }
        setFormTemplateValue({...formTemplateValueDefault});
        setFormNumberListValue({...formNumberListValueDefault});
    };


    const handleSubmit = async (nextTry = false, fixedMethod = null, fixedParams = {}) => {

        let params = {};
        let currentMethod = null;

        // set current method and params
        if (nextTry) {

            params = {...fixedParams};
            currentMethod = fixedMethod;

        } else {

            const currentFormRef = forms[activeForm].formRef.current;

            if (!currentFormRef.check()) {
                return;
            }

            currentMethod = forms[activeForm].method;

            const formValue = currentFormRef.getFormValue();
            const numberList = formValue.number_list
                ? formValue.number_list.split('\n')
                    .map(number => number.trim())
                    .filter(item => item)
                : undefined;

            params = {
                ...formValue,
                number_list: numberList,
                target: (service ? {trunk_id: trunkId} : {"sms.trunk_id": trunkId}),
                numbers: formValue.numbers && !isNaN(formValue.numbers)
                    ? +formValue.numbers
                    : formValue.numbers,
                template: !isBySde ? formValue.template : undefined,
                sde_key: isBySde ? formValue.sde_key : undefined,
            };
        }

        if (onSubmit) {
            onSubmit(currentMethod, params);
            return;
        }

        setLocalLoading(true);

        await api(currentMethod, params).then(r => {
            if (['too_much_operations', 'too_much_operations_sde'].includes(r?.reason_code)) {

                const delayVal = globalSettings?.allocation_lock_delay_sec 
                    ? globalSettings.allocation_lock_delay_sec * 1000
                    : TRY_DELAY_VALUE;

                if (params.sde_key || params.template) {
                    const key = params.sde_key || params.template;
                    if (!nextTry) {
                        tryTimers.current[key] = setTimeout(() => {
                            handleSubmit(true, currentMethod, params);
                        }, delayVal);
                        return;
                    }

                    disableDirection(key);
                } else {

                    if (!nextTry) {
                        setTimeout(() => {
                            handleSubmit(true, currentMethod, params);
                        }, delayVal);
                        return;
                    }

                }
            }

            setLocalLoading(false);

            const numberListReasonCodeList = [
                REASON_CODES.not_enough_free_numbers, 
                REASON_CODES.not_found_free_numbers
            ];

            if (r === undefined || (r.code && !r.reason_code)) {
                return;
            }

            if (r.reason_code === 'not_enough_free_numbers_sequence') {
                setFormTemplateValue(prev => ({...prev, random_number: true}));
            }

            // form template
            if (!r.trunk_number_transaction || !r.trunk_number_transaction.numbers) {

                if (activeForm === FORM_TEMPLATE && r.reason_code) {

                    showReasonHandler({
                        ...r,
                        trunk: trunkId,
                        reasonCode: r.reason_code,
                        reasonHash: r.hash,
                        method: ALLOCATION_TEMPLATE_DIALER_API,
                        isRandom: params.random_number,
                        template: params.template,
                        numbersAmount: params.numbers,
                        subdestinationKey: params.sde_key
                    });
                    return;
                }
                if (activeForm === FORM_NUMBER_LIST && (
                    !r.not_allocated_numbers 
                    || !r.not_allocated_numbers.length 
                    || numberListReasonCodeList.includes(r.reason_code))
                ) {

                    showReasonHandler({
                        ...r,
                        trunk: trunkId,
                        reasonCode: r.reason_code,
                        reasonHash: r.hash,
                        method: ALLOCATION_NUMBER_LIST_DIALER_API,
                        number_list: params?.number_list
                    });
                    return;
                }
            }

            onSuccess && onSuccess(r, params);
            setDefaults();
        })
    };


    const disableDirection = (id, counter = globalSettings?.allocation_lock_second_delay_sec || COUNTER_VALUE) => {
        setInactiveDirections(prev => ({
            ...prev,
            [id]: {counter: counter}
        }));

        if (counter < 1) {
            return;
        }

        countDownTimers.current[id] = setTimeout(() => {
            disableDirection(id, counter - 1);
        }, 1000);
    };


    const counterValue = inactiveDirections[formValueTemplate?.sde_key || formValueTemplate?.template]?.counter;

    return (
        <>
            <S.FormModal
                show={show}
                title={formatMessage(m.allocateNumbers)}
                width={!isMobile ? maxWidth : windowWidth}
                showFooter={true}
                confirmButtonText={formatMessage(m.submit)}
                disabled={trunkId === null}
                loading={loading || localLoading || !!counterValue}
                loadingText={!!counterValue ? `${formatMessage(m.submit)} (${counterValue})` : null}
                onConfirm={ () => {
                    handleSubmit().then(() =>
                        setTrunkId(selectedTrunkId || null)
                    )
                }}
                onClose={() => {
                    setTrunkId(selectedTrunkId || null);
                    setDefaults();
                    onClose(false);
                }}
            >

                <TrunksList
                    data={filteredTrunks}
                    value={trunkId}
                    cleanable={false}
                    onChange={(value) => {
                        setTrunkId(value);
                    }}
                />

                <Spacer size={30}/>

                {isMobile && !isSms &&
                <S.FormToggle
                    size="lg"
                    checkedChildren={formatMessage(m.template)}
                    unCheckedChildren={formatMessage(m.numberList)}
                    defaultChecked={activeForm}
                    onChange={value => {
                        setActiveForm(value ? FORM_TEMPLATE : FORM_NUMBER_LIST);
                    }}
                />
                }
                

                <S.FormColumns single={isSms}>

                    {(!isMobile || FORM_TEMPLATE === activeForm) &&
                    <S.FormColumn single={isSms}>
                        {activeForm !== FORM_TEMPLATE &&
                            <FormWhisper onClick={() => setActiveForm(FORM_TEMPLATE)}/>
                        }
                        <FormAllocationTemplate
                            ref={templateFormRef}
                            formValue={formValueTemplate}
                            disabled={activeForm !== FORM_TEMPLATE}
                            onChange={setFormTemplateValue}
                            allocationLimit={currentTrunkLimits}
                            {...{
                                subdestinationList,
                                setIsBySde
                            }}
                            hideTitle={isSms}
                            hideAmount={isSms}
                            hideRandom={isSms}
                        />
                    </S.FormColumn>
                    }


                    {!isMobile && !isSms &&
                    <S.FormColumn divider>
                        <S.FormDivider>
                            <span className="divider__text">{formatMessage(m.or)}</span>
                            <Divider className="divider" vertical/>
                        </S.FormDivider>
                    </S.FormColumn>
                    }


                    {(!isMobile || FORM_NUMBER_LIST === activeForm) && !isSms &&
                    <S.FormColumn>
                        {activeForm !== FORM_NUMBER_LIST && !incorrectSpKey &&
                            <FormWhisper onClick={() => setActiveForm(FORM_NUMBER_LIST)} />
                        }

                        <FormAllocationNumberList
                            ref={numberListFormRef}
                            formValue={formValueNumberList}
                            disabled={activeForm !== FORM_NUMBER_LIST || incorrectSpKey}
                            onChange={setFormNumberListValue}
                            allocationLimit={currentTrunkLimits}
                        />
                    </S.FormColumn>
                    }

                </S.FormColumns>
            </S.FormModal>
        </>
    );
}


const mapState = ( {references} )=> ({
    globalSettings: references.global_setting,
    subdestinationList: references.subdestination_list
});

export default connect(mapState, {})(ModalAllocationNumbers);